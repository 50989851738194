import React, { type FC } from 'react';
import t from '../lib/translate';
import { type IntegrationParam } from 'src/types';
import InputCheckbox from './InputCheckbox';
import InputText from './InputText';

const JAVA_TYPE_BOOL = 'java.lang.Boolean';
const JAVA_TYPE_INTEGER = 'java.lang.Integer';

interface Props {
  parameter: IntegrationParam;
  value: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  onChange: (paramId: number, paramValue: number | string) => void;
}

const IntegrationParameter: FC<Props> = ({ parameter, value, onChange }) => {
  if (parameter.javaType === JAVA_TYPE_BOOL) {
    return (
      <InputCheckbox
        key={parameter.integrationParamTypeId}
        label={parameter.i18nkey}
        options={[
          {
            name: parameter.integrationParamTypeId,
            value,
            label: parameter.i18nkey,
          },
        ]}
        handleChange={(e) => {
          onChange(e.target.name, e.target.checked);
        }}
        toggle
      />
    );
  }

  if (parameter.javaType === JAVA_TYPE_INTEGER) {
    const labelTranslation = t(parameter.i18nkey);
    const placeholderTranslation = t(`${parameter.i18nkey}.placeholder`);
    const translatedPlaceholder =
      placeholderTranslation === `${parameter.i18nkey}.placeholder`
        ? labelTranslation
        : placeholderTranslation;

    return (
      <InputText
        name={parameter.integrationParamTypeId.toString()}
        type='number'
        value={value}
        label={labelTranslation}
        placeholder={translatedPlaceholder}
        handleChange={(e) => {
          onChange(parameter.integrationParamTypeId, e.target.value);
        }}
      />
    );
  }

  return <div key={parameter.integrationParamTypeId}>{t('unsupported.type')}</div>;
};

export default IntegrationParameter;
