import React, { useState } from 'react';
import { connect } from 'react-redux';

import TableWithHeader from '../../../components/TableWithHeader';
import Modal from '../../../components/Modal';
import SortingConfigForm from '../SortingConfigForm';
import LoaderPage from '../../LoaderPage';

import t from '../../../lib/translate';
import {
  mapListToObject,
  formatDateForEdit,
  formatFromToDate,
  formatPropertyFilterString,
} from '../../../lib/format';
import {
  deleteSortingConfig,
  editSortingConfig,
} from '../../../redux/actions/stationSortingConfigs';
import FilterWarning from '../../../components/FilterWarning';
import { useStation } from '../../../redux/reducers/stations';

const handleDeleteSortingConfig = (config, deleteSortingConfig, station) => {
  if (window.confirm(t('station.delete.config.confirm', config.name))) {
    deleteSortingConfig(station.stationId, config.id);
  }
};

const SortingConfigs = ({
  mappedStationSortingConfigs,
  mappedStationSortingConfigsForEdit,
  stations,
  deleteSortingConfig,
  isLoading,
}) => {
  const [tableOpen, toggleTable] = useState(true);
  const [modalOpen, toggleModal] = useState(false);
  const [activeModal, setActiveModal] = useState('');
  const [editConfig, setEditConfig] = useState(null);

  const station = useStation(stations);

  const toggleModalOpen = () => toggleModal(!modalOpen);
  const toggleTableOpen = () => toggleTable(!tableOpen);

  const handleCreateClick = () => {
    setActiveModal('create');
    toggleModalOpen();
  };

  const handleEditClick = (configIndex) => {
    setActiveModal('edit');
    toggleModalOpen();
    setEditConfig(mappedStationSortingConfigsForEdit[configIndex]);
  };

  const headerButtons = () => [
    {
      label: 'table.sortingConfigs.new',
      onClick: handleCreateClick,
      className: 'createSortingConfig',
    },
  ];

  return (
    <div className='sorting-degree'>
      {isLoading && <LoaderPage />}

      <Modal open={modalOpen && activeModal === 'create'}>
        <SortingConfigForm
          name='sortingConfigs'
          stationId={station.stationId}
          defaultSortingDegree={station.sortingDegreeId}
          finished={toggleModalOpen}
        />
      </Modal>

      <Modal open={modalOpen && activeModal === 'edit'}>
        <SortingConfigForm
          name='sortingConfigs'
          stationId={station.stationId}
          defaultSortingDegree={station.sortingDegree}
          isEdit={true}
          sortingConfig={editConfig}
          finished={toggleModalOpen}
        />
      </Modal>

      <TableWithHeader
        name='sortingConfigs'
        toggleModalOpen={toggleModalOpen}
        buttons={headerButtons()}
        open={tableOpen}
        toggleOpenTable={toggleTableOpen}
        columnNames={['name', 'date', 'sortingDegree', 'propertyFilter', 'alias', 'priority']}
        rowData={mappedStationSortingConfigs}
        deleteRow={(e) => handleDeleteSortingConfig(e, deleteSortingConfig, station)}
        editable={(e) => handleEditClick(e)}
      />
    </div>
  );
};

const mapDispatchToProps = {
  deleteSortingConfig,
  editSortingConfig,
};

const mapStateToProps = (state) => {
  const { properties } = state.staticData;

  const propertyValues = state.station.propertyValues || {};

  const propertyIdLabelMap = mapListToObject(properties, 'propertyId');

  const mappedStationSortingConfigs = state.station.sortingConfigurations.data.map((config) => ({
    id: config.sortingConfigId,
    name: config.name,
    alias: config.alias,
    priority: config.priority > 0 ? config.priority : '',
    date: formatFromToDate(config.fromDate, config.toDate),
    sortingDegree: t(`sorting.degree.type.${config.sortingDegreeId}`),
    propertyFilter: config.propertyFilters.map((pf, index) => (
      <div key={index.id}>
        {formatPropertyFilterString(pf, propertyIdLabelMap, propertyValues)}{' '}
        <FilterWarning propertyFilter={pf} />
      </div>
    )),
  }));

  const mappedStationSortingConfigsForEdit = state.station.sortingConfigurations.data.map(
    (config) => ({
      id: config.sortingConfigId,
      name: config.name,
      fromDate: formatDateForEdit(config.fromDate),
      toDate: config.toDate === null ? undefined : formatDateForEdit(config.toDate),
      sortingDegree: config.sortingDegreeId,
      alias: config.alias,
      priority: config.priority,
      propertyFilter: config.propertyFilters.map((pf) => ({
        key: pf.propertyId,
        value: pf.externalId,
      })),
      stopOnSuccessor: config.stopOnSuccessor,
    })
  );

  return {
    isLoading: state.isLoading.primary,
    error: state.station.sortingConfigurations.error,
    modalError: state.error.station.modal,
    stations: state.stations,
    mappedStationSortingConfigs,
    mappedStationSortingConfigsForEdit,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SortingConfigs);
