import { patchRequest, deleteRequest } from './util';

const toggle = (stationId) => patchRequest(`/stations/${stationId}/toggleReuseAlias`);

const resetAliases = (stationId) => deleteRequest(`/stations/${stationId}/resetAliases`);

export default {
  toggle,
  resetAliases,
};
