import React from 'react';
import { connect } from 'react-redux';

import Form from '../../components/Form';
import InputRadioButton from '../../components/InputRadioButton';
import InputSelectKeyValue from '../../components/InputSelectKeyValue';
import InputText from '../../components/InputText';
import LabelInfo from '../../components/LabelInfo';

import { createSortingConfig, editSortingConfig } from '../../redux/actions/stationSortingConfigs';
import { resetErrors } from '../../redux/actions/resetErrors';
import { toApiDate } from '../../lib/format';
import t from '../../lib/translate';
import InputCheckbox from '../../components/InputCheckbox';
import Warning from '../../components/Warning';
import InputDatePicker from '../../components/InputDatePicker';

const SORTING_CONFIG_ID = 'sortingConfigId';
const NAME = 'name';
const FROM_DATE = 'fromDate';
const TO_DATE = 'toDate';
const SORTING_DEGREE_ID = 'sortingDegreeId';
const PROPERTY_FILTERS = 'propertyFilters';
const STOP_ON_SUCCESSOR = 'stopOnSuccessor';
const ALIAS = 'alias';
const PRIORITY = 'priority';

class SortingConfigForm extends React.Component {
  constructor(props) {
    super(props);

    const { isEdit, sortingConfig, nextPriority } = this.props;

    let fromDate;
    let toDate;
    let propFilters;
    if (isEdit) {
      fromDate = new Date(Date.parse(sortingConfig.fromDate));
      toDate =
        sortingConfig.toDate === undefined ? undefined : new Date(Date.parse(sortingConfig.toDate));
      propFilters =
        sortingConfig.propertyFilter === undefined
          ? [{ key: undefined, value: undefined }]
          : sortingConfig.propertyFilter;
    } else {
      fromDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
      toDate = undefined;
      propFilters = [{ key: undefined, value: undefined }];
    }

    this.state = {
      [SORTING_CONFIG_ID]: isEdit ? sortingConfig.id : undefined,
      [NAME]: isEdit ? sortingConfig.name : '',
      [FROM_DATE]: fromDate,
      [TO_DATE]: toDate,
      [SORTING_DEGREE_ID]: isEdit ? sortingConfig.sortingDegree : undefined,
      [PROPERTY_FILTERS]: propFilters,
      [STOP_ON_SUCCESSOR]: isEdit ? sortingConfig.stopOnSuccessor : false,
      [ALIAS]: isEdit ? sortingConfig.alias : '',
      [PRIORITY]: isEdit ? sortingConfig.priority : nextPriority,
    };
  }

  updateFieldValue = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    const { target } = e;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.updateFieldValue(name, value);
  };

  handleAliasChange = (e) => {
    const { target } = e;
    const { name } = target;
    const value = target.value;
    this.updateFieldValue(name, value);
  };

  enableSubmit = () => {
    const filters = this.state[PROPERTY_FILTERS];

    const emptyFilters = filters.filter((filter) => !(filter.key && filter.value));

    return emptyFilters.length > 0
      ? false
      : this.state[NAME] !== null &&
          this.state[FROM_DATE] !== null &&
          this.state[SORTING_DEGREE_ID] !== null;
  };

  toApiRequest = () => ({
    [SORTING_CONFIG_ID]: this.state[SORTING_CONFIG_ID],
    [NAME]: this.state[NAME],
    [FROM_DATE]: toApiDate(this.state[FROM_DATE]),
    [TO_DATE]: toApiDate(this.state[TO_DATE]),
    [SORTING_DEGREE_ID]: this.state[SORTING_DEGREE_ID],
    [PROPERTY_FILTERS]: this.state[PROPERTY_FILTERS].map((pf) => ({
      propertyId: pf.key,
      externalId: pf.value,
    })),
    [STOP_ON_SUCCESSOR]: this.state[STOP_ON_SUCCESSOR],
    [ALIAS]: this.isParcelSortingDegree() ? this.state[ALIAS] : null,
    [PRIORITY]: this.isParcelSortingDegree() ? this.state[PRIORITY] : 0,
  });

  isParcelSortingDegree = () => {
    const parcelSortingDegrees = this.props.sortingDegrees
      .filter((sd) => sd.parcelSortingDegree)
      .map((sd) => sd.sortingDegreeId);
    return parcelSortingDegrees.includes(this.state[SORTING_DEGREE_ID]);
  };

  closeModal = () => {
    this.props.finished(this.props.name);
  };

  closeModalAndResetFormErrors = () => {
    this.closeModal();
    this.props.resetErrors();
  };

  handleSubmit = () => {
    if (this.enableSubmit() && this.props.isEdit) {
      const req = this.toApiRequest();
      this.props.editSortingConfig(this.props.stationId, req, this.closeModal);
    } else if (this.enableSubmit() && !this.props.isEdit) {
      const req = this.toApiRequest();
      this.props.createSortingConfig(this.props.stationId, req, this.closeModal);
    }
  };

  handleDateChange = (name, date) => {
    date && this.updateFieldValue(name, date);
  };

  aliasReserved() {
    return this.props.reservedAliases.includes(this.state[ALIAS]);
  }

  render() {
    const {
      isLoading,
      error,
      sortingDegrees,
      mappedOutgoingProperties,
      mappedParcelProperties,
      mappedPropertyValues,
    } = this.props;

    const parcelSortingDegree = this.isParcelSortingDegree();
    const showAliasReservedWarning = this.aliasReserved();

    return (
      <Form
        label='config.label.header'
        isLoading={isLoading}
        error={error}
        enableSubmit={this.enableSubmit()}
        onClose={this.closeModalAndResetFormErrors}
        onSubmit={this.handleSubmit}>
        <InputText
          name={NAME}
          value={this.state[NAME]}
          label='config.label.name'
          handleChange={this.handleChange}
          maxLength='64'
        />

        <InputRadioButton
          name={SORTING_DEGREE_ID}
          label='config.label.sorting.degree'
          info='config.label.sorting.degree.info'
          value={this.state[SORTING_DEGREE_ID]}
          options={sortingDegrees.map((sd) => ({
            value: sd.sortingDegreeId,
            label: t(`sorting.degree.type.${sd.sortingDegreeId}`),
          }))}
          handleChange={this.updateFieldValue}
        />

        {!parcelSortingDegree && (
          <InputCheckbox
            name={STOP_ON_SUCCESSOR}
            id={STOP_ON_SUCCESSOR}
            options={[
              {
                name: 'stopOnSuccessor',
                value: this.state[STOP_ON_SUCCESSOR],
                label: 'config.label.sorting.successor',
              },
            ]}
            handleChange={this.handleChange}
            toggle
          />
        )}

        {showAliasReservedWarning && (
          <Warning className='input-group' message={t('station.alias.reserved')} />
        )}

        {parcelSortingDegree && (
          <InputText
            name={ALIAS}
            value={this.state[ALIAS]}
            label='config.label.alias'
            info='config.label.alias.info'
            handleChange={this.handleAliasChange}
          />
        )}

        {parcelSortingDegree && (
          <InputText
            type='number'
            name={PRIORITY}
            value={this.state[PRIORITY]}
            label='config.label.priority'
            info='config.label.priority.info'
            handleChange={this.handleChange}
            maxLength='2'
          />
        )}

        <InputSelectKeyValue
          name={PROPERTY_FILTERS}
          label='config.label.propertyFilter'
          info='config.label.propertyFilter.info'
          placeholderKey='config.label.propertyFilters.property'
          placeholderValue='config.label.propertyFilters.value'
          selectedKeyValues={this.state[PROPERTY_FILTERS]}
          onChange={this.updateFieldValue}
          keyOptions={parcelSortingDegree ? mappedParcelProperties : mappedOutgoingProperties}
          keyValueOptions={mappedPropertyValues}
          required
        />

        <div className='input-group'>
          <LabelInfo label='config.label.date' info='config.label.date.info' group />
          <div className='input-datepicker-group'>
            <InputDatePicker
              name={FROM_DATE}
              label='config.label.date.from'
              value={this.state[FROM_DATE]}
              onChange={this.updateFieldValue}
            />

            <InputDatePicker
              name={TO_DATE}
              label='config.label.date.to'
              value={this.state[TO_DATE]}
              onChange={this.updateFieldValue}
            />
          </div>
        </div>
      </Form>
    );
  }
}

const mapDispatchToProps = {
  createSortingConfig,
  editSortingConfig,
  resetErrors,
};

const mapStateToProps = (state) => {
  const propertyValues = state.station.propertyValues;
  const { properties } = state.staticData;
  const mappedPropertyValues = {};

  const reservedAliases = state.station.reservedAliases.data;

  const mappedOutgoingProperties =
    properties &&
    properties
      .filter((prop) => prop.outgoingCompatible)
      .map((prop) => ({ value: prop.propertyId, label: t(prop.i18nKey) }));

  const mappedParcelProperties =
    properties &&
    properties
      .filter((prop) => prop.parcelCompatible)
      .map((prop) => ({ value: prop.propertyId, label: t(prop.i18nKey) }));

  propertyValues &&
    Object.keys(propertyValues).forEach((propertyKey) => {
      mappedPropertyValues[propertyKey] = propertyValues[propertyKey].map((pv) => ({
        value: pv.externalId,
        label: t(pv.label),
      }));
    });

  const parcelSortingDegrees = state.staticData.sortingDegrees
    .filter((sd) => sd.parcelSortingDegree)
    .map((sd) => sd.sortingDegreeId);
  const parcelSortingConfigurations = state.station.sortingConfigurations.data.filter((sc) =>
    parcelSortingDegrees.includes(sc.sortingDegreeId)
  );

  return {
    isLoading: state.station.sortingConfigurations.updating,
    error: state.station.sortingConfigurations.error,
    sortingDegrees: state.staticData.sortingDegrees,
    mappedOutgoingProperties,
    mappedPropertyValues,
    mappedParcelProperties,
    nextPriority: parcelSortingConfigurations.length + 1,
    reservedAliases,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SortingConfigForm);
