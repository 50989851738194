import { LANGUAGE_LOADED, LANGUAGE_CHANGED } from '../actions/language';
import { setLanguage } from '../../lib/translate';

const languageReducer = (state = [], action) => {
  switch (action.type) {
    case LANGUAGE_LOADED:
      setLanguage(action.data);
      return action.data;

    case LANGUAGE_CHANGED:
      setLanguage(action.data);
      return action.data;

    default:
      return state;
  }
};

export default languageReducer;
