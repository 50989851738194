import { stations } from '../../api';
import { setPrimaryLoader } from './loader';
import { getAliasBranches } from './stationAliasBranches';
import { getSortingConfigs } from './stationSortingConfigs';

export const GET_STATIONS_SUCCESS = 'GET_STATIONS_SUCCESS';
export const GET_STATIONS_FAILURE = 'GET_STATIONS_FAILURE';

export const CREATE_STATION_SUCCESS = 'CREATE_STATION_SUCCESS';
export const CREATE_STATION_FAILURE = 'CREATE_STATION_FAILURE';

export const EDIT_STATION_SUCCESS = 'EDIT_STATION_SUCCESS';
export const EDIT_STATION_FAILURE = 'EDIT_STATION_FAILURE';

export const DELETE_STATION_SUCCESS = 'DELETE_STATION_SUCCESS';
export const DELETE_STATION_FAILURE = 'DELETE_STATION_FAILURE';

export const RESET_ERROR_NEW_STATION = 'RESET_ERROR_NEW_STATION';
export const RESET_ERROR_EDIT_STATION = 'RESET_ERROR_EDIT_STATION';
export const RESET_ERROR_CLONE_STATION = 'RESET_ERROR_CLONE_STATION';

export const CLONE_STATION_SUCCESS = 'CLONE_STATION_SUCCESS';
export const CLONE_STATION_FAILURE = 'CLONE_STATION_FAILURE';

export const getStations = () => (dispatch) =>
  stations
    .get()
    .then((stations) =>
      dispatch({
        type: GET_STATIONS_SUCCESS,
        data: stations,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_STATIONS_FAILURE,
        data: err,
      })
    );

export const createStation = (stationReq, onSuccess) => (dispatch) => {
  dispatch(setPrimaryLoader(true));
  dispatch({
    type: RESET_ERROR_NEW_STATION,
  });

  return stations
    .create(stationReq)
    .then((station) => {
      onSuccess();
      dispatch({
        type: CREATE_STATION_SUCCESS,
        data: station,
      });
    })
    .catch((err) =>
      dispatch({
        type: CREATE_STATION_FAILURE,
        data: err,
      })
    )
    .finally(() => {
      dispatch(setPrimaryLoader(false));
    });
};

export const editStation = (stationId, stationReq, onSuccess) => (dispatch) => {
  dispatch(setPrimaryLoader(true));
  dispatch({
    type: RESET_ERROR_EDIT_STATION,
  });

  return stations
    .edit(stationId, stationReq)
    .then((station) =>
      dispatch({
        type: EDIT_STATION_SUCCESS,
        data: station,
      })
    )
    .then(() => onSuccess())
    .then(() => dispatch(getAliasBranches(stationId)))
    .then(() => dispatch(getSortingConfigs(stationId)))
    .catch((err) => {
      dispatch({
        type: EDIT_STATION_FAILURE,
        data: err,
      });

      return Promise.reject(err);
    })
    .finally(() => dispatch(setPrimaryLoader(false)));
};

export const deleteStation = (stationId, onSuccess) => (dispatch) =>
  stations
    .remove(stationId)
    .then(() => {
      onSuccess();

      return dispatch({
        type: DELETE_STATION_SUCCESS,
        data: stationId,
      });
    })
    .catch((err) =>
      dispatch({
        type: DELETE_STATION_FAILURE,
        data: err,
      })
    );

export const cloneStation = (stationId, onSuccess) => (dispatch) => {
  dispatch(setPrimaryLoader(true));
  dispatch({
    type: RESET_ERROR_CLONE_STATION,
  });

  return stations
    .clone(stationId)
    .then((station) => {
      onSuccess();
      dispatch({
        type: CLONE_STATION_SUCCESS,
        data: station,
      });
    })
    .catch((err) =>
      dispatch({
        type: CLONE_STATION_FAILURE,
        data: err,
      })
    )
    .finally(() => {
      dispatch(setPrimaryLoader(false));
    });
};
