import { GET_INTEGRATIONS_SUCCESS, GET_INTEGRATIONS_FAILURE } from '../actions/integrations';

const integrationsReducer = (state = null, action) => {
  switch (action.type) {
    case GET_INTEGRATIONS_SUCCESS:
      return action.data;

    case GET_INTEGRATIONS_FAILURE:
      return [];

    default:
      return state;
  }
};

export default integrationsReducer;
