import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { changeLanguage } from '../redux/actions/language';
import { getOwnerParties, updateUserInfo } from '../redux/actions/userInfo';
import t, { languages } from '../lib/translate';
import InputSelect from '../components/InputSelect';
import LoadingButton from '../components/LoadingButton';
import SystemMessageList from './settings/SystemMessageList';
import { useNavigate } from 'react-router';

const DI_STAFF_ROLE = 'di';

const Settings = ({
  currentLanguage,
  changeLanguage,
  activeOwnerParty,
  ownerParties,
  getOwnerParties,
  updateUserInfo,
  loading,
  grantedRoles,
}) => {
  const navigate = useNavigate();
  const [selectedOwnerPartyKey, setSelectedOwnerPartyKey] = useState(activeOwnerParty);
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);

  useEffect(() => {
    getOwnerParties();
  }, [getOwnerParties]);

  useEffect(() => {
    if (activeOwnerParty) {
      setSelectedOwnerPartyKey({
        value: activeOwnerParty.key,
        label: activeOwnerParty.name,
      });
    }
  }, [activeOwnerParty]);

  const handleSubmit = async () => {
    if (selectedOwnerPartyKey) {
      await updateUserInfo({
        activeOwnerPartyKey: selectedOwnerPartyKey.value,
      });
    }
    changeLanguage(selectedLanguage);
    navigate('/stations');
  };

  const handleCancel = () => navigate('/stations');

  return (
    <div className='settings-page'>
      <h1>{t('settings.title')}</h1>
      <div className='ownerparties'>
        <h3>{t('settings.ownerparty')}</h3>
        <InputSelect
          value={selectedOwnerPartyKey}
          onChange={(ownerParty) => setSelectedOwnerPartyKey(ownerParty)}
          options={ownerParties.map((op) => ({
            value: op.key,
            label: op.name,
          }))}
        />
      </div>
      <div className='languages'>
        <h3>{t('settings.languages')}</h3>
        {languages.map((language) => (
          <div key={language.value}>
            <input
              name='language'
              type='radio'
              checked={language.value === selectedLanguage}
              value={language.value}
              id={language.value}
              onChange={() => setSelectedLanguage(language.value)}
            />
            <label className='radio' htmlFor={`${language.value}`}>
              {t(language.label)}
            </label>
          </div>
        ))}
      </div>
      <div className='settings-buttons'>
        <LoadingButton label={t('button.submit')} onClick={handleSubmit} isLoading={loading} />
        <button type='button' className='cancel' onClick={handleCancel}>
          {t('button.cancel')}
        </button>
      </div>
      {grantedRoles?.includes(DI_STAFF_ROLE) && <SystemMessageList />}
    </div>
  );
};

const mapDispatchToProps = {
  changeLanguage,
  updateUserInfo,
  getOwnerParties,
};

const mapStateToProps = (state) => ({
  currentLanguage: state.language,
  activeOwnerParty: state.userInfo.user.activeOwnerParty,
  grantedRoles: state.userInfo.user.grantedRoles,
  ownerParties: state.userInfo.ownerParties,
  loading: state.userInfo.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
