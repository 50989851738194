import { RESET_STATION_DATA } from '../actions/station';

import {
  GET_STATION_USERS_SUCCESS,
  GET_STATION_USERS_FAILURE,
  ADD_STATION_USER_SUCCESS,
  REMOVE_STATION_USER_SUCCESS,
  CREATE_STATION_USER_SUCCESS,
  EDIT_STATION_USER_SUCCESS,
} from '../actions/stationUsers';

const stationUsersReducer = (state = null, action) => {
  switch (action.type) {
    case GET_STATION_USERS_SUCCESS:
      return action.data;

    case GET_STATION_USERS_FAILURE:
      return [];

    case ADD_STATION_USER_SUCCESS:
      return [...state, action.data];

    case REMOVE_STATION_USER_SUCCESS:
      return state.filter((user) => user.username !== action.data);

    case CREATE_STATION_USER_SUCCESS:
      return [...state, action.data];

    case EDIT_STATION_USER_SUCCESS:
      return state.map((user) => (user.userId === action.data.userId ? action.data : user));

    case RESET_STATION_DATA:
      return null;

    default:
      return state;
  }
};

export default stationUsersReducer;
