import React from 'react';
import t from '../lib/translate';

const StationsTable = ({ goToStation, stations }) => (
  <>
    <table className='desktop'>
      <thead>
        <tr>
          <td>{t('stations.label.id')}</td>
          <td>{t('stations.label.name')}</td>
          <td>{t('stations.label.sorting.degree')}</td>
          <td>{t('stations.label.alias.method')}</td>
          <td>{t('stations.label.ownerparty')}</td>
          <td />
        </tr>
      </thead>
      <tbody>
        {stations &&
          stations.map((station) => (
            <tr className={station.nodes.length ? '' : 'error'} key={station.stationId}>
              <td>{station.stationId}</td>
              <td>
                {!station.nodes.length && <i className='fas fa-exclamation-triangle' />}
                {station.name}
              </td>
              <td>{station.sortingDegreeName}</td>
              <td>{station.aliasAllocationMethodName}</td>
              <td>{station.ownerPartyName}</td>
              <td>
                <button onClick={() => goToStation(station.stationId)}>
                  {t('stations.label.choose.station')}
                  <i className='fas fa-angle-right' />
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
    <div className='mobile'>
      {stations &&
        stations.map((station) => (
          <div className='station' key={station.stationId}>
            {!station.nodes.length && (
              <div className='error error-text row'>
                <i className='fas fa-exclamation-triangle' />
                {t('err.station.nodes')}
              </div>
            )}
            <div className='row'>
              <div className='label'>{t('stations.label.id')}</div>
              <div>{station.stationId}</div>
            </div>
            <div className='row'>
              <div className='label'>{t('stations.label.name')}</div>
              <div>{station.name}</div>
            </div>
            <div className='row'>
              <div className='label'>{t('stations.label.sorting.degree')}</div>
              <div>{station.sortingDegreeName}</div>
            </div>
            <div className='row'>
              <div className='label'>{t('stations.label.alias.method')}</div>
              <div>{station.aliasAllocationMethodName}</div>
            </div>
            <button onClick={() => goToStation(station.stationId)}>
              {t('stations.label.choose.station')}
              <i className='fas fa-angle-right' />
            </button>
          </div>
        ))}
    </div>
  </>
);

export default StationsTable;
