import React, { useState } from 'react';
import { connect } from 'react-redux';

import TableWithHeader from '../../../components/TableWithHeader';
import Modal from '../../../components/Modal';
import UserForm from '../UserForm';
import CreateUserForm from '../CreateUserForm';
import LoaderPage from '../../LoaderPage';

import t from '../../../lib/translate';
import {
  removeStationUser,
  editStationUser,
  addStationUser,
} from '../../../redux/actions/stationUsers';
import { useStation } from '../../../redux/reducers/stations';
import { addStationApiUser, removeStationApiUser } from '../../../redux/actions/stationApiUsers';
import { useAppDispatch } from '../../../redux/hooks';

const handleRemoveStationUser = (user, removeStationUser, station) => {
  if (window.confirm(t('table.stationUsers.remove', user.username))) {
    removeStationUser(station.stationId, user.username);
  }
};

const handleRemoveStationApiUser = (user, removeStationUser, station) => {
  if (window.confirm(t('table.stationUsers.remove', user.username))) {
    removeStationUser(station.stationId, user.username);
  }
};

const Users = ({
  mappedStationUsers,
  mappedStationApiUsers,
  stations,
  removeStationUser,
  removeStationApiUser,
  isLoading,
}) => {
  const [userTableOpen, toggleUserTable] = useState(true);
  const [apiUserTableOpen, toggleApiUserTable] = useState(true);
  const [modalOpen, toggleModal] = useState(false);
  const [activeModal, setActiveModal] = useState('');
  const [editUser, setEditUser] = useState(null);

  const dispatch = useAppDispatch();

  const station = useStation(stations);

  const toggleModalOpen = () => toggleModal(!modalOpen);
  const toggleUserTableOpen = () => toggleUserTable(!userTableOpen);
  const toggleApiUserTableOpen = () => toggleApiUserTable(!apiUserTableOpen);

  const handleGrantClick = () => {
    setActiveModal('grant');
    toggleModalOpen();
  };

  const handleGrantApiClick = () => {
    setActiveModal('grantApi');
    toggleModalOpen();
  };

  const handleCreateClick = () => {
    setActiveModal('create');
    toggleModalOpen();
  };

  const handleEditClick = (userIndex) => {
    setActiveModal('edit');
    toggleModalOpen();
    setEditUser(mappedStationUsers[userIndex]);
  };

  const headerButtons = () => [
    {
      label: 'table.stationUsers.create',
      onClick: handleCreateClick,
      className: 'create-user',
    },
    {
      label: 'table.stationUsers.grant',
      onClick: handleGrantClick,
      className: 'grant-user',
    },
  ];

  const headerButtonsApiUsers = () => [
    {
      label: 'table.stationApiUsers.grant',
      onClick: handleGrantApiClick,
      className: 'grant-user',
    },
  ];

  return (
    <div className='users'>
      {isLoading && <LoaderPage />}
      <Modal open={modalOpen && activeModal === 'create'}>
        <CreateUserForm
          name='stationUsers'
          stationId={station.stationId}
          finished={toggleModalOpen}
        />
      </Modal>

      <Modal open={modalOpen && activeModal === 'edit'}>
        <CreateUserForm
          name='stationUsers'
          stationId={station.stationId}
          user={editUser}
          finished={toggleModalOpen}
          isEdit
        />
      </Modal>

      <Modal open={modalOpen && activeModal === 'grant'}>
        <UserForm
          name='stationUsers'
          stationId={station.stationId}
          finished={toggleModalOpen}
          addStationUser={addStationUser}
          dispatch={dispatch}
        />
      </Modal>

      <Modal open={modalOpen && activeModal === 'grantApi'}>
        <UserForm
          name='stationApiUsers'
          stationId={station.stationId}
          finished={toggleModalOpen}
          addStationUser={addStationApiUser}
          dispatch={dispatch}
        />
      </Modal>

      <TableWithHeader
        name='stationUsers'
        toggleModalOpen={toggleModalOpen}
        buttons={headerButtons()}
        open={userTableOpen}
        toggleOpenTable={toggleUserTableOpen}
        columnNames={['name', 'username']}
        rowData={mappedStationUsers}
        deleteRow={(e) => handleRemoveStationUser(e, removeStationUser, station)}
        editable={(e) => handleEditClick(e)}
      />

      <TableWithHeader
        name='stationApiUsers'
        toggleModalOpen={toggleModalOpen}
        buttons={headerButtonsApiUsers()}
        open={apiUserTableOpen}
        toggleOpenTable={toggleApiUserTableOpen}
        columnNames={['name', 'username']}
        rowData={mappedStationApiUsers}
        deleteRow={(e) => handleRemoveStationApiUser(e, removeStationApiUser, station)}
      />
    </div>
  );
};

const mapDispatchToProps = {
  removeStationUser,
  removeStationApiUser,
  editStationUser,
};

const mapStateToProps = (state, props) => {
  const stationUsers = state.station.users || [];

  const mappedStationUsers = stationUsers.map((user) => ({
    id: user.userId,
    name: user.name,
    username: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    languageCode: user.languageCode,
    grantedRoles: user.grantedRoles,
  }));

  const stationApiUsers = state.station.apiUsers || [];

  const mappedStationApiUsers = stationApiUsers.map((user) => ({
    id: user.userId,
    name: user.name,
    username: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    languageCode: user.languageCode,
    grantedRoles: user.grantedRoles,
  }));

  return {
    isLoading: !state.station.users,
    error: state.error.station.page,
    modalError: state.error.station.modal,
    stations: state.stations,
    mappedStationUsers,
    mappedStationApiUsers,
    props,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
