import React, { type FC } from 'react';
import t from '../lib/translate';

interface Props {
  message: string;
}

const DeprecationWarning: FC<Props> = ({ message }) => {
  return (
    <span className={'deprecation-warning'}>
      <i className='fas fa-exclamation-triangle i-error' />
      {t(message)}
    </span>
  );
};

export default DeprecationWarning;
