import { type PropertyValue } from 'src/types';
import {
  GET_PROPERTY_VALUES_SUCCESS,
  GET_RETURN_PROPERTY_VALUES_SUCCESS,
} from '../actions/stationPropertyValues';

type PropertyValueMap = Map<string, PropertyValue[]>;

const INITIAL_STATE: PropertyValueMap = new Map();

const mergeResults = (a: PropertyValueMap, b: PropertyValueMap): PropertyValueMap => {
  const results = { ...a };

  Object.keys(b).forEach((key) => {
    if (!results[key]) {
      results[key] = [];
    }
    b[key].forEach((propertyValue) => {
      results[key]?.push(propertyValue);
    });
  });

  return results;
};

// Merges outgoing property values and return property values into one map
// Makes it easier to print labels no matter the source of property values
const allPropertyValuesReducer = (state = INITIAL_STATE, action): PropertyValueMap => {
  switch (action.type) {
    case GET_RETURN_PROPERTY_VALUES_SUCCESS:
      return mergeResults(state, action.data);

    case GET_PROPERTY_VALUES_SUCCESS:
      return mergeResults(state, action.data);

    default:
      return state;
  }
};

export default allPropertyValuesReducer;
