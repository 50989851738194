import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, useParams } from 'react-router-dom';

import Modal from '../../components/Modal';
import StationForm from './StationForm';
import Error from '../../components/Error';
import NotFound from '../NotFound';
import StationHeader from '../../components/StationHeader';
import SidebarNavigation from '../../components/SidebarNavigation';

import { sideNavSections } from '../sections';
import { getSortingConfigs } from '../../redux/actions/stationSortingConfigs';
import { getStationReturnConfigs } from '../../redux/actions/stationReturnConfigs';
import { getStationIntegrations } from '../../redux/actions/stationIntegrations';
import { cloneStation, deleteStation } from '../../redux/actions/stations';
import { resetStationData, updateStationNetworkAliases } from '../../redux/actions/station';
import { getStationAliasLetterLimits } from '../../redux/actions/stationAliasLetterLimits';
import { getReturnAliasGroups } from '../../redux/actions/stationReturnAliasGroups';
import { getReturnAliasGroupOptions } from '../../redux/actions/stationReturnAliasGroupOptions';
import { getStationUsers } from '../../redux/actions/stationUsers';
import { getStationApiUsers } from '../../redux/actions/stationApiUsers';
import { getAliasBranches } from '../../redux/actions/stationAliasBranches';
import { getReservedAliases } from '../../redux/actions/stationReservedAliases';
import {
  getPropertyValues,
  getReturnPropertyValues,
} from '../../redux/actions/stationPropertyValues';
import t from '../../lib/translate';
import { useStation } from '../../redux/reducers/stations';
import { Navigate, Routes, useNavigate } from 'react-router';

const Station = (props) => {
  const navigate = useNavigate();

  const [editStationModalOpen, setEditStationModalOpen] = useState(false);
  const { stationId } = useParams();
  const { stations } = props;

  const station = useStation(stations);

  const { configuration: stationSortingConfigs = [] } = station;

  const usedStationSortingDegreeIds = [
    ...new Set(stationSortingConfigs.map((conf) => conf.sortingDegreeId)),
  ];

  useEffect(() => {
    if (stationId) {
      props.getSortingConfigs(stationId);
      props.getStationIntegrations(stationId);
      props.getStationAliasLetterLimits(stationId);
      props.getStationReturnConfigs(stationId);
      props.getReturnAliasGroups(stationId);
      props.getReturnAliasGroupOptions(stationId);
      props.getStationUsers(stationId);
      props.getStationApiUsers(stationId);
      props.getAliasBranches(stationId);
      props.getReservedAliases(stationId);
      props.getPropertyValues(stationId);
      props.getReturnPropertyValues(stationId);
    }
  }, [station.stationId]);

  useEffect(
    () => () => {
      props.resetStationData();
    },
    []
  );

  const toggleModalOpen = () => {
    setEditStationModalOpen((previous) => !previous);
  };

  const handleDeleteStation = () => {
    const { deleteStation } = props;
    if (window.confirm(t('station.delete.confirm', station.name))) {
      deleteStation(stationId, () => navigate('/stations'));
    }
  };

  const handleCloneStation = () => {
    const { cloneStation } = props;
    if (window.confirm(t('station.clone.confirm', station.name))) {
      cloneStation(stationId, () => navigate('/stations'));
    }
  };

  const headerButtons = () => [
    {
      label: 'station.label.edit',
      onClick: toggleModalOpen.bind(null, 'editStation'),
      className: 'edit-station',
    },
    {
      label: 'button.clone',
      onClick: handleCloneStation,
      className: 'clone',
    },
    {
      label: 'button.delete',
      onClick: handleDeleteStation,
      className: 'delete',
    },
  ];

  const { error } = props;

  if (!station.stationId) {
    return <NotFound message='station.not.found' />;
  }

  return (
    <div className='station-page'>
      <Modal open={editStationModalOpen}>
        <StationForm
          name='editStation'
          station={station}
          finished={toggleModalOpen}
          usedSortingDegreeIds={usedStationSortingDegreeIds}
        />
      </Modal>

      <StationHeader station={station} buttons={headerButtons()} />

      <div className='station-content-sidenav-wrapper'>
        <SidebarNavigation station={station} props={props} />
        <div className='station-content-wrapper'>
          {error && <Error error={error} withMargin />}
          <Routes>
            {sideNavSections(station).map((section) => (
              <Fragment key={section.name}>
                <Route path={section.path} element={section.component} />
                {section.children?.map(({ path, component }) => (
                  <Route key={path} path={path} element={component} />
                ))}
              </Fragment>
            ))}
            <Route path='*' element={<Navigate to='sortingDegrees' replace />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  getSortingConfigs,
  getStationIntegrations,
  getStationReturnConfigs,
  getReturnAliasGroups,
  getReturnAliasGroupOptions,
  getStationUsers,
  getStationApiUsers,
  resetStationData,
  deleteStation,
  getStationAliasLetterLimits,
  getAliasBranches,
  getReservedAliases,
  updateStationNetworkAliases,
  getPropertyValues,
  getReturnPropertyValues,
  cloneStation,
};

const mapStateToProps = (state) => ({
  error: state.error.station.page,
  modalError: state.error.station.modal,
  stations: state.stations,
});

export default connect(mapStateToProps, mapDispatchToProps)(Station);
