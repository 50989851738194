import t from '../../lib/translate';

export const getDaysOfWeekOptions = () => [
  {
    value: 'MONDAY',
    label: t('station.alias.groups.available.days.monday'),
    order: '0',
  },
  {
    value: 'TUESDAY',
    label: t('station.alias.groups.available.days.tuesday'),
    order: '1',
  },
  {
    value: 'WEDNESDAY',
    label: t('station.alias.groups.available.days.wednesday'),
    order: '2',
  },
  {
    value: 'THURSDAY',
    label: t('station.alias.groups.available.days.thursday'),
    order: '3',
  },
  {
    value: 'FRIDAY',
    label: t('station.alias.groups.available.days.friday'),
    order: '4',
  },
  {
    value: 'SATURDAY',
    label: t('station.alias.groups.available.days.saturday'),
    order: '5',
  },
  {
    value: 'SUNDAY',
    label: t('station.alias.groups.available.days.sunday'),
    order: '6',
  },
];
