import { getRequest, postRequest, deleteRequest } from './util';

const get = (stationId) => getRequest(`/stations/${stationId}/users`);

const post = (stationId, user) => postRequest(`/stations/${stationId}/users`, user);

const edit = (stationId, user) => postRequest(`/stations/${stationId}/users/edit`, user);

const create = (stationId, user) => postRequest(`/stations/${stationId}/user`, user);

const remove = (stationId, userId) => deleteRequest(`/stations/${stationId}/users/${userId}`);

export default {
  get,
  post,
  edit,
  create,
  remove,
};
