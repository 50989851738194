import { getRequest, putRequest } from './util';

const get = (stationId) => getRequest(`/stations/${stationId}/aliasBranches`);
const update = (stationId, aliasBranches) =>
  putRequest(`/stations/${stationId}/aliasBranches`, aliasBranches);

export default {
  get,
  update,
};
