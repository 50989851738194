import React, { useState } from 'react';

import LoaderPage from '../../../views/LoaderPage';
import TableWithHeader from '../../../components/TableWithHeader';
import Modal from '../../../components/Modal';
import { deleteStationIntegration } from '../../../redux/actions/stationIntegrations';
import IntegrationForm, { FormType } from '../IntegrationForm';
import t from '../../../lib/translate';
import PropertyValueList from '../../../components/PropertyValueList';
import IntegrationParamList from '../../..//components/IntegrationParamList';
import { mapListToObject } from '../../../lib/format';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { type RootState } from 'src/redux/store';
import { useStation } from '../../../redux/reducers/stations';

const TableStatus = {
  outgoing: true,
  incoming: true,
  logic: true,
};

const Integrations = (): React.ReactElement => {
  const stations = useAppSelector((state) => state.stations);
  const { stationId } = useStation(stations);
  const dispatch = useAppDispatch();

  const integrations = useAppSelector((state: RootState) => state.staticData.integrations);
  const stationIntegrations = useAppSelector((state: RootState) => state.station.integrations);
  const integrationIdLabelMap = mapListToObject(integrations, 'integrationId', 'name');

  const mappedIntegrations = stationIntegrations?.map((stationIntegration) => ({
    id: stationIntegration.stationIntegrationId,
    name:
      (stationIntegration.priority > 0 ? `${stationIntegration.priority} - ` : '') +
      t(`integration.${integrationIdLabelMap[stationIntegration.integrationId]}`),
    propertyFilter: (
      <PropertyValueList
        propertyValues={stationIntegration.propertyFilters}
        showMatchAllIfEmpty={true}
      />
    ),
    propertyException: <PropertyValueList propertyValues={stationIntegration.propertyExceptions} />,
    integration: (
      <IntegrationParamList
        integrationParameterValues={stationIntegration.integrationParameterValues}
      />
    ),
    type: integrations.find((i) => i.integrationId === stationIntegration.integrationId),
  }));

  const outgoingIntegrations = mappedIntegrations?.filter((i) => i.type?.outgoingCompatible) ?? [];
  const incomingIntegrations = mappedIntegrations?.filter((i) => i.type?.returnCompatible) ?? [];
  const logicIntegrations = mappedIntegrations?.filter((i) => i.type?.returnLogicCompatible) ?? [];

  const [modal, toggleModal] = useState(FormType.CLOSED);
  const [tables, toggleTable] = useState<typeof TableStatus>(TableStatus);

  const handleToggleTable = (key: string): void => {
    toggleTable({ ...tables, [key]: !tables[key] });
  };

  const handleDeleteStationIntegration = (stationIntegration): void => {
    if (window.confirm(t('station.delete.integration.confirm', stationIntegration.name))) {
      // @ts-expect-error Supressing dispatch issues as we do not have properly typed store - need bigger refactor
      dispatch(deleteStationIntegration(stationId, stationIntegration.id));
    }
  };

  return (
    <div className='integrations'>
      {!stationIntegrations && <LoaderPage />}

      <Modal open={modal !== FormType.CLOSED}>
        <IntegrationForm
          stationId={stationId}
          type={modal}
          finished={() => {
            toggleModal(FormType.CLOSED);
          }}
          formTitle={
            (modal === FormType.LOGIC && 'station.integration.new.header.logic') ||
            (modal === FormType.INCOMING && 'station.integration.new.header.incoming') ||
            'station.integration.new.header.outgoing'
          }
        />
      </Modal>

      <TableWithHeader
        name='stationIntegrations'
        open={tables.outgoing}
        toggleOpenTable={() => {
          handleToggleTable('outgoing');
        }}
        toggleModalOpen={() => {
          toggleModal(FormType.OUTGOING);
        }}
        columnNames={['name', 'propertyFilter', 'propertyException', 'integration']}
        rowData={outgoingIntegrations}
        deleteRow={handleDeleteStationIntegration}
        helpText={t('table.stationIntegrations.helpText')}
      />
      <TableWithHeader
        name='stationLogicIntegrations'
        open={tables.logic}
        toggleOpenTable={() => {
          handleToggleTable('logic');
        }}
        toggleModalOpen={() => {
          toggleModal(FormType.LOGIC);
        }}
        columnNames={['name', 'propertyFilter', 'propertyException', 'integration']}
        rowData={logicIntegrations}
        deleteRow={handleDeleteStationIntegration}
        helpText={t('table.stationLogicIntegrations.helpText')}
      />
      <TableWithHeader
        name='stationIncomingIntegrations'
        open={tables.incoming}
        toggleOpenTable={() => {
          handleToggleTable('incoming');
        }}
        toggleModalOpen={() => {
          toggleModal(FormType.INCOMING);
        }}
        columnNames={['name', 'propertyFilter', 'propertyException', 'integration']}
        rowData={incomingIntegrations}
        deleteRow={handleDeleteStationIntegration}
        helpText={t('table.stationIncomingIntegrations.helpText')}
      />
    </div>
  );
};

export default Integrations;
