import { deleteRequest, getRequest, postRequest, putRequest } from './util';

const get = async (stationId): Promise<void> =>
  await getRequest(`/stations/${stationId}/returnAliasGroups`);

const create = async (stationId, returnAliasGroup): Promise<void> =>
  await postRequest(`/stations/${stationId}/returnAliasGroups`, returnAliasGroup);

const update = async (stationId, returnAliasGroupId, returnAliasGroup): Promise<void> =>
  await putRequest(
    `/stations/${stationId}/returnAliasGroups/${returnAliasGroupId}`,
    returnAliasGroup
  );

const remove = async (stationId, returnAliasGroupId): Promise<void> =>
  await deleteRequest(`/stations/${stationId}/returnAliasGroups/${returnAliasGroupId}`);

export default {
  get,
  create,
  remove,
  update,
};
