import { getRequest, postRequest, deleteRequest, putRequest } from './util';

const get = (stationId) => getRequest(`/stations/${stationId}/configs`);

const create = (stationId, sortingConfig) =>
  postRequest(`/stations/${stationId}/configs`, sortingConfig);

const edit = (stationId, sortingConfig) =>
  putRequest(`/stations/${stationId}/configs/edit`, sortingConfig);

const remove = (stationId, sortingConfigId) =>
  deleteRequest(`/stations/${stationId}/configs/${sortingConfigId}`);

export default {
  get,
  create,
  edit,
  remove,
};
