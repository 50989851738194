import React, { useState } from 'react';

import Form from '../components/Form';
import InputText from '../components/InputText';
import t from '../lib/translate';

import { login } from '../redux/actions/authentication';
import SystemInformationBar from './SystemInformationBar';
import Logo from '../images/Cargostream.svg';

import { useAppDispatch, useAppSelector } from '../redux/hooks';

const envLink = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return 'dev-';
    case 'stg':
      return 'stg-';
    default:
      return '';
  }
};

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const isLoading = useAppSelector((state) => state.isLoading.primary);
  const error = useAppSelector((state) => state.error.authentication);

  const handleSetPassword = (e) => setPassword(e.target.value);
  const handleSetUsername = (e) => setUsername(e.target.value);

  const handleSubmit = () => {
    if (username && password) {
      dispatch(login({ username, password }));
    }
  };

  return (
    <div className='login-page'>
      <header>
        <div className='header-wrapper'>
          <div className='brand'>
            <Logo />
          </div>
        </div>
      </header>
      <SystemInformationBar />
      <Form
        buttonLabel='button.login'
        isLoading={isLoading}
        onSubmit={handleSubmit}
        error={error}
        hideCancel
        enableSubmit={username && password}>
        <InputText
          name='username'
          value={username}
          label='login.username'
          handleChange={handleSetUsername}
          autoFocus
          autoComplete='username'
        />
        <InputText
          name='password'
          type='password'
          autoComplete='password'
          value={password}
          label='login.password'
          handleChange={handleSetPassword}
        />
      </Form>
      <a
        className='reset-password-link link'
        href={`https://${envLink()}app.di.no/auth/static/password/reset`}>
        {t('login.password.expired.link')}
      </a>
    </div>
  );
};
export default LoginPage;
