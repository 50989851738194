import { GET_PROPERTIES_SUCCESS, GET_PROPERTIES_FAILURE } from '../actions/properties';

const propertiesReducer = (state = null, action) => {
  switch (action.type) {
    case GET_PROPERTIES_SUCCESS:
      return action.data;

    case GET_PROPERTIES_FAILURE:
      return [];

    default:
      return state;
  }
};

export default propertiesReducer;
