import React from 'react';

import LabelInfo from './LabelInfo';
import t from '../lib/translate';

const InputCheckbox = ({ options, handleChange, toggle, label, info, children }) => (
  <div className='input-group'>
    <LabelInfo label={label} info={info} />

    <div className='input-checkbox'>
      {options.map((option) => (
        <div key={option.name} className='checkbox'>
          <input
            type='checkbox'
            name={option.name}
            id={option.name}
            checked={option.value}
            onChange={handleChange}
            className={toggle ? 'toggle' : ''}
          />
          <label className='toggle-button' htmlFor={option.name} />
          <label htmlFor={option.name}>{t(option.label)}</label>
        </div>
      ))}

      {children}
    </div>
  </div>
);

export default InputCheckbox;
