import sprintf from 'sprintf-js';
import no from '../i18n/no.json';
import en from '../i18n/en.json';
import sv from '../i18n/sv.json';

type TranslationKeys = Record<string, string>;
interface Translations {
  no: TranslationKeys;
  en: TranslationKeys;
  sv: TranslationKeys;
}

const translations: Translations = {
  no,
  en,
  sv,
};

export const languages = [
  {
    value: 'no',
    label: 'Norsk',
  },
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'sv',
    label: 'Svenska',
  },
];

const fallback: keyof Translations = 'en';

// TODO Obtain this from user

let language: keyof Translations;

export const setLanguage = (selectedLang: keyof Translations): void => {
  language = selectedLang;
};

const translation = (key): string =>
  translations[language]?.[key] || translations[fallback]?.[key] || key;

const translate = (key, ...args): string =>
  args.length > 0 ? sprintf.sprintf(translation(key), ...args) : translation(key);

export default translate;
