import React, { type FC } from 'react';
import { type DayMatrix } from '../types';

interface Props {
  dayMatrix: DayMatrix;
  nodeTypeCode: string;
  showSeparator?: boolean;
}

const DayMatrixLabel: FC<Props> = ({ dayMatrix, nodeTypeCode }) => {
  // Show day matrix label only for routes and route segments
  if (nodeTypeCode === 'R' || nodeTypeCode === 'S') {
    return <span className={'day-matrix-label'}> [M{dayMatrix.days}]</span>;
  }
  return null;
};

export default DayMatrixLabel;
