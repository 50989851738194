import React from 'react';

import Loader from '../components/Loader';
import t from '../lib/translate';

const LoaderPage = ({ message = undefined }) => (
  <div className='loader-page'>
    <Loader />
    {message !== null && <div>{t(message)}</div>}
  </div>
);

export default LoaderPage;
