import React, { type FC } from 'react';
import { useSelector } from 'react-redux';

import { type RootState } from 'src/redux/store';
import { type PropertyValue } from 'src/types';
import { formatPropertyFilterString, mapListToObject } from '../lib/format';
import t from '../lib/translate';

interface Props {
  propertyValues: PropertyValue[];
  showMatchAllIfEmpty?: boolean;
}

const PropertyValueList: FC<Props> = ({ propertyValues, showMatchAllIfEmpty = false }) => {
  const propertyStationValues = useSelector((state: RootState) => state.station.allPropertyValues);

  const properties = useSelector((state: RootState) => state.staticData.properties);
  const propertyIdLabelMap = mapListToObject(properties, 'propertyId');

  return (
    <>
      {showMatchAllIfEmpty && propertyValues.length === 0 && (
        <div>{t('property.value.matchAll')}</div>
      )}
      {propertyValues.map((pf) => (
        <div key={pf.propertyId}>
          {formatPropertyFilterString(pf, propertyIdLabelMap, propertyStationValues)}
        </div>
      ))}
    </>
  );
};

export default PropertyValueList;
