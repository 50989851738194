import { stationNetworkLayout } from '../../api';

export const GET_STATION_NETWORK_SUCCESS = 'GET_STATION_NETWORK_SUCCESS';
export const GET_STATION_NETWORK_FAILURE = 'GET_STATION_NETWORK_FAILURE';

export const getStationNetworkLayout = (stationId) => (dispatch) =>
  stationNetworkLayout
    .get(stationId)
    .then((stationNetwork) =>
      dispatch({
        type: GET_STATION_NETWORK_SUCCESS,
        data: stationNetwork,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_STATION_NETWORK_FAILURE,
        data: err,
      })
    );
