import React, { useState } from 'react';
import { connect } from 'react-redux';

import AliasGroupForm from '../AliasGroupForm';
import EditAliasForm from '../EditAliasForm';
import Form from '../../../components/Form';
import LoaderPage from '../../LoaderPage';
import Modal from '../../../components/Modal';
import StationAliasTableForm from '../../../components/StationAliasTableForm';
import TableWithHeader from '../../../components/TableWithHeader';
import DayMatrixLabel from '../../../components/DayMatrixLabel';

import { updateAliasBranches } from '../../../redux/actions/stationAliasBranches';
import { deleteAliasGroup } from '../../../redux/actions/stationAliasGroups';
import { resetErrors } from '../../../redux/actions/resetErrors';
import t from '../../../lib/translate';
import { getDaysOfWeekOptions } from '../../../redux/actions/daysOfWeekOptions';
import { useStation } from '../../../redux/reducers/stations';

const Alias = ({
  aliasBranches,
  deleteAliasGroup,
  isLoading,
  isUpdating,
  mappedStationAliasBranches,
  error,
  stations,
  updateAliasBranches,
  resetErrors,
}) => {
  const [aliasModalOpen, setAliasModalOpen] = useState(false);
  const [aliasGroupModalOpen, setAliasGroupModalOpen] = useState(false);

  const [aliasGroupTableOpen, setAliasGroupTableOpen] = useState(true);
  const [aliasGroupToDelete, setAliasGroupToDelete] = useState(undefined);
  const [groupId, setGroupId] = useState(null);

  const station = useStation(stations);

  const toggleAliasModalOpen = () => setAliasModalOpen(!aliasModalOpen);

  const toggleAliasGroupModalOpen = (rowIndex) => {
    if (typeof rowIndex === 'number') {
      setGroupId(
        mappedStationAliasBranches.filter((aliasGroup) => aliasGroup.aliasGroupId)[rowIndex]
          .aliasGroupId
      );
    } else {
      setGroupId(null);
    }
    setAliasGroupModalOpen(!aliasGroupModalOpen);
  };

  const handleUpdateAlias = (formData) => {
    const updatedAliasBranches = aliasBranches.map((aliasBranch, index) => ({
      ...aliasBranch,
      alias: formData[index].alias,
      aliasFreeText: formData[index].aliasFreeText,
    }));
    return updateAliasBranches(station.stationId, updatedAliasBranches);
  };

  const handleDeleteAliasGroup = () => {
    deleteAliasGroup(station.stationId, aliasGroupToDelete.aliasGroupId).then(() =>
      setAliasGroupToDelete(undefined)
    );
  };

  return (
    <div className='alias-page'>
      {isLoading && <LoaderPage />}

      <Modal open={aliasModalOpen}>
        <EditAliasForm name='stationAliasGroup' station={station} finished={toggleAliasModalOpen} />
      </Modal>

      <Modal open={aliasGroupModalOpen}>
        <AliasGroupForm
          name='stationAliasGroup'
          stationId={station.stationId}
          finished={toggleAliasGroupModalOpen}
          groupId={groupId}
        />
      </Modal>

      <Modal open={aliasGroupToDelete}>
        <Form
          isLoading={isUpdating}
          error={false}
          label='station.alias.group.delete'
          enableSubmit={true}
          buttonLabel='button.confirm'
          onClose={() => setAliasGroupToDelete(undefined)}
          onSubmit={handleDeleteAliasGroup}>
          <p>{t('station.alias.group.delete.info')}</p>
        </Form>
      </Modal>

      <div className='buttons'>
        <button onClick={toggleAliasModalOpen}>{t('station.alias.edit')}</button>
      </div>

      <TableWithHeader
        name='stationAliasGroup'
        toggleModalOpen={toggleAliasGroupModalOpen}
        open={aliasGroupTableOpen}
        toggleOpenTable={() => setAliasGroupTableOpen(!aliasGroupTableOpen)}
        columnNames={['alias', 'aliasFreeText', 'branches']}
        rowData={mappedStationAliasBranches.filter((aliasGroup) => aliasGroup.aliasGroupId)}
        deleteRow={setAliasGroupToDelete}
        editable={(e) => toggleAliasGroupModalOpen(e)}
      />

      {station.reuseAlias && (
        <StationAliasTableForm
          name='stationAlias'
          formError={error}
          isLoading={isUpdating}
          submitForm={handleUpdateAlias}
          columnNames={['alias', 'branches', 'aliasFreeText']}
          editableColumns={['alias', 'aliasFreeText']}
          rowData={mappedStationAliasBranches}
          resetError={resetErrors}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = {
  deleteAliasGroup,
  updateAliasBranches,
  resetErrors,
};

const getAvailableDaysForCustomBranch = (branch) =>
  branch.availableDaysOfWeek.map(
    (day) => ` ${getDaysOfWeekOptions().find((dow) => dow.value === day).label}`
  );

const getAvailableDaysForMainBranch = (aliasGroup) => {
  const customMainBranchAvailableDaysOfWeek = [];
  aliasGroup.branches.forEach((branch) =>
    branch.availableDaysOfWeek.forEach((day) => customMainBranchAvailableDaysOfWeek.push(day))
  );
  const mainBranchAvailableDaysOfWeek = getDaysOfWeekOptions().filter(
    (dow) => !customMainBranchAvailableDaysOfWeek.some((day) => day === dow.value)
  );
  return mainBranchAvailableDaysOfWeek.map((dow) => ` ${dow.label}`);
};

const mapStateToProps = (state) => {
  const mappedStationAliasBranches = state.station.aliasBranches.data.map((aliasGroup) => ({
    id: aliasGroup.alias,
    aliasGroupId: aliasGroup.aliasGroupId,
    alias: aliasGroup.alias,
    aliasFreeText: aliasGroup.aliasFreeText,
    warning:
      aliasGroup.aliasGroupId && !aliasGroup.branches.find((branch) => branch.mainBranch)
        ? t('station.alias.group.main.missing')
        : false,
    branches: aliasGroup.branches.map((branch) => (
      <div key={`${branch.endNode.nodeId}-${branch.startNode.nodeId}`}>
        <div
          className={branch.customMainBranch || branch.mainBranch ? 'heavy' : ''}
          key={`${branch.endNode.nodeId}-${branch.startNode.nodeId}`}>
          {`(${branch.startNode.nodeTypeCode}) ${branch.startNode.label}`}
          <i className='fas fa-arrow-right' />
          {`(${branch.endNode.nodeTypeCode}) ${branch.endNode.label}`}
          <DayMatrixLabel
            dayMatrix={branch.endNode.dayMatrix}
            nodeTypeCode={branch.endNode.nodeTypeCode}
          />
        </div>
        {branch.availableDaysOfWeek.length > 0 && (
          <span className='margin-left-2'>
            <i className='fa fa-calendar' aria-hidden='true' />
            {getAvailableDaysForCustomBranch(branch)}
          </span>
        )}
        {((branch.mainBranch && !branch.customMainBranch) || branch.originalMainBranch) &&
          getAvailableDaysForMainBranch(aliasGroup).length > 0 && (
            <span className='margin-left-2'>
              <i className='fa fa-calendar' aria-hidden='true' />
              {getAvailableDaysForMainBranch(aliasGroup)}
            </span>
          )}
      </div>
    )),
  }));

  return {
    isLoading: !state.station.aliasBranches.loaded && state.station.aliasLetterLimits,
    isUpdating: state.station.aliasBranches.updating,
    aliasBranches: state.station.aliasBranches.data,
    aliasLetterLimits: state.station.aliasLetterLimits,
    error: state.station.aliasBranches.error,
    mappedStationAliasBranches,
    stations: state.stations,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Alias);
