import stationReservedAliases from '../../api/stationReservedAliases';

export const GET_STATION_RESERVED_ALIASES_REQUEST = 'GET_STATION_RESERVED_ALIASES_REQUEST';
export const GET_STATION_RESERVED_ALIASES_SUCCESS = 'GET_STATION_RESERVED_ALIASES_SUCCESS';
export const GET_STATION_RESERVED_ALIASES_FAILURE = 'GET_STATION_RESERVED_ALIASES_FAILURE';

export const getReservedAliases = (stationId) => async (dispatch) => {
  await dispatch({ type: GET_STATION_RESERVED_ALIASES_REQUEST });

  return stationReservedAliases
    .get(stationId)
    .then((reservedAliases) =>
      dispatch({
        type: GET_STATION_RESERVED_ALIASES_SUCCESS,
        data: reservedAliases,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_STATION_RESERVED_ALIASES_FAILURE,
        data: err,
      })
    );
};
