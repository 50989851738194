import { aliasLetterLimits } from '../../api';
import { setPrimaryLoader } from './loader';
import { getAliasBranches } from './stationAliasBranches';

export const GET_ALIAS_LETTER_LIMTITS_SUCCESS = 'GET_ALIAS_LETTER_LIMTITS_SUCCESS';
export const GET_ALIAS_LETTER_LIMTITS_FAILURE = 'GET_ALIAS_LETTER_LIMTITS_FAILURE';

export const UPDATE_ALIAS_LETTER_LIMITS_SUCCESS = 'UPDATE_ALIAS_LETTER_LIMITS_SUCCESS';
export const UPDATE_ALIAS_LETTER_LIMITS_FAILURE = 'UPDATE_ALIAS_LETTER_LIMITS_FAILURE';

export const getStationAliasLetterLimits = (stationId) => (dispatch) => {
  dispatch(setPrimaryLoader(true));
  aliasLetterLimits
    .get(stationId)
    .then((stationAliasLetterLimits) =>
      dispatch({
        type: GET_ALIAS_LETTER_LIMTITS_SUCCESS,
        data: stationAliasLetterLimits,
      })
    )
    .catch((err) => {
      dispatch({
        type: GET_ALIAS_LETTER_LIMTITS_FAILURE,
        data: err,
      });
    })
    .finally(() => {
      dispatch(setPrimaryLoader(false));
    });
};

export const updateStationAliasLetterLimits = (stationId, letterLimits) => (dispatch) =>
  aliasLetterLimits
    .update(stationId, letterLimits)
    .then(() =>
      dispatch({
        type: UPDATE_ALIAS_LETTER_LIMITS_SUCCESS,
        data: letterLimits,
      })
    )
    .then(() => dispatch(getAliasBranches(stationId)))
    .catch((err) => {
      dispatch({
        type: UPDATE_ALIAS_LETTER_LIMITS_FAILURE,
        data: err,
      });

      return Promise.reject(err);
    });
