import {
  GET_MODEL_VERSIONS_FAILURE,
  GET_MODEL_VERSIONS_FILTERS_FAILURE,
  GET_MODEL_VERSIONS_FILTERS_SUCCESS,
  GET_MODEL_VERSIONS_SUCCESS,
  GET_NUMBER_OF_EXPORTS_LEFT_FAILURE,
  GET_NUMBER_OF_EXPORTS_LEFT_SUCCESS,
  ORDER_COMPLETE_MODEL_FAILURE,
  ORDER_COMPLETE_MODEL_REQUEST,
  ORDER_COMPLETE_MODEL_SUCCESS,
  ORDER_TRANSPORT_UPDATE_FAILURE,
  ORDER_TRANSPORT_UPDATE_REQUEST,
  ORDER_TRANSPORT_UPDATE_SUCCESS,
} from '../actions/transport';
import { type Error, type ModelVersionUpdate, type NodeIdentifier } from '../../types';

export interface TransportState {
  modelVersions: ModelVersionUpdate[];
  filterOptions: NodeIdentifier[];
  remainingUpdates: number;
  error: Error | null;
  loading: boolean;
}

const INITIAL_STATE: TransportState = {
  modelVersions: [],
  filterOptions: [],
  remainingUpdates: 0,
  error: null,
  loading: false,
};

const TransportReducer = (state = INITIAL_STATE, action): TransportState => {
  switch (action.type) {
    case GET_MODEL_VERSIONS_SUCCESS:
      return { ...state, modelVersions: action.data };

    case GET_MODEL_VERSIONS_FAILURE:
      return { ...state, error: JSON.parse(action.data.message) };

    case GET_MODEL_VERSIONS_FILTERS_SUCCESS:
      return { ...state, filterOptions: action.data };

    case GET_MODEL_VERSIONS_FILTERS_FAILURE:
      return { ...state, error: JSON.parse(action.data.message) };

    case ORDER_TRANSPORT_UPDATE_REQUEST:
      return { ...state, loading: true };

    case ORDER_TRANSPORT_UPDATE_SUCCESS:
      return { ...state, loading: false, error: null };

    case ORDER_TRANSPORT_UPDATE_FAILURE:
      return { ...state, loading: false, error: JSON.parse(action.data.message) };

    case ORDER_COMPLETE_MODEL_REQUEST:
      return { ...state, loading: true };

    case ORDER_COMPLETE_MODEL_SUCCESS:
      return { ...state, loading: false, error: null };

    case ORDER_COMPLETE_MODEL_FAILURE:
      return { ...state, loading: false, error: JSON.parse(action.data.message) };

    case GET_NUMBER_OF_EXPORTS_LEFT_SUCCESS:
      return { ...state, remainingUpdates: action.data };

    case GET_NUMBER_OF_EXPORTS_LEFT_FAILURE:
      return { ...state, remainingUpdates: 0, error: JSON.parse(action.data.message) };

    default:
      return INITIAL_STATE;
  }
};

export default TransportReducer;
