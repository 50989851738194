import { systemInfo } from '../../api';

export const GET_SYSTEM_INFO_REQUEST = 'GET_SYSTEM_INFO_REQUEST';
export const GET_SYSTEM_INFO_SUCCESS = 'GET_SYSTEM_INFO_SUCCESS';
export const GET_SYSTEM_INFO_FAILURE = 'GET_SYSTEM_INFO_FAILURE';

export const UPDATE_SYSTEM_INFO_SUCCESS = 'UPDATE_SYSTEM_INFO_SUCCESS';
export const UPDATE_SYSTEM_INFO_FAILURE = 'UPDATE_SYSTEM_INFO_FAILURE';

export const getSystemInfo = () => (dispatch) => {
  dispatch({ type: GET_SYSTEM_INFO_REQUEST });

  systemInfo
    .get()
    .then((systemInfo) => {
      dispatch({
        type: GET_SYSTEM_INFO_SUCCESS,
        data: systemInfo,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_SYSTEM_INFO_FAILURE,
        data: err,
      })
    );
};

export const createOrUpdateSystemInfo = (systemMessage) => (dispatch) =>
  systemInfo
    .createOrUpdate(systemMessage)
    .then((result) =>
      dispatch({
        type: UPDATE_SYSTEM_INFO_SUCCESS,
        data: result,
      })
    )
    .then(() => dispatch(getSystemInfo()))
    .catch((err) =>
      dispatch({
        type: UPDATE_SYSTEM_INFO_FAILURE,
        data: err,
      })
    );

export const deleteSystemInfo = (systemInfoId) => (dispatch) =>
  systemInfo.deleteMessage(systemInfoId).then(() => dispatch(getSystemInfo()));
