import { integrations } from '../../api';

export const GET_INTEGRATIONS_SUCCESS = 'GET_INTEGRATIONS_SUCCESS';
export const GET_INTEGRATIONS_FAILURE = 'GET_INTEGRATIONS_FAILURE';

export const getIntegrations = () => (dispatch) =>
  integrations
    .get()
    .then((integrationsResponse) =>
      dispatch({
        type: GET_INTEGRATIONS_SUCCESS,
        data: integrationsResponse,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_INTEGRATIONS_FAILURE,
        data: err,
      })
    );
