import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { getSystemInfo } from '../redux/actions/systemInfo';
import { getUserInfo } from '../redux/actions/userInfo';

import SystemMessage from '../components/SystemMessage';
import PasswordExpiryWarning from '../components/PasswordExpiryWarning';
import { changeTimeZone } from '../lib/dateUtil';

import { useAppDispatch, useAppSelector } from '../redux/hooks';

const shouldShow = (fromDate, toDate) => {
  const from = changeTimeZone(new Date(fromDate));
  const to = changeTimeZone(new Date(toDate));
  const now = new Date();

  return from <= now && to >= now;
};

const SystemInformationBar = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const userInfo = useAppSelector((state) => state.userInfo.user);
  const systemInfoMessages = useAppSelector((state) => state.systemInfo.data);
  const isAuthenticated = useAppSelector((state) => state.isAuthenticated);
  const messages = [];

  // Reload messages whenever the URL path changes
  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserInfo());
    }
    dispatch(getSystemInfo());
  }, [location.pathname, dispatch, isAuthenticated]);

  systemInfoMessages
    .filter((message) => shouldShow(message.fromDate, message.toDate))
    .forEach((message) => {
      const messageLevel = message.importantFromDate ? 'error' : 'warning';
      messages.push(<SystemMessage systemInfo={message} className={messageLevel} />);
    });

  if (userInfo !== null && userInfo.mustChangePassword) {
    messages.push(<PasswordExpiryWarning />);
  }

  return (
    <>
      {messages.map((message, i) => (
        <span key={`system-message-${i.id}`}>{message}</span>
      ))}
    </>
  );
};

export default SystemInformationBar;
