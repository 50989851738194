import { filters } from '../../api';

export const GET_PROPERTY_VALUES_SUCCESS = 'GET_PROPERTY_VALUES_SUCCESS';
export const GET_PROPERTY_VALUES_FAILURE = 'GET_PROPERTY_VALUES_FAILURE';
export const GET_RETURN_PROPERTY_VALUES_SUCCESS = 'GET_RETURN_PROPERTY_VALUES_SUCCESS';
export const GET_RETURN_PROPERTY_VALUES_FAILURE = 'GET_RETURN_PROPERTY_VALUES_FAILURE';

export const getPropertyValues = (stationId) => (dispatch) =>
  filters
    .getPropertyValues(stationId)
    .then((propertyValues) =>
      dispatch({
        type: GET_PROPERTY_VALUES_SUCCESS,
        data: propertyValues,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_PROPERTY_VALUES_FAILURE,
        data: err,
      })
    );

export const getReturnPropertyValues = (stationId) => (dispatch) =>
  filters
    .getReturnPropertyValues(stationId)
    .then((propertyValues) =>
      dispatch({
        type: GET_RETURN_PROPERTY_VALUES_SUCCESS,
        data: propertyValues,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_RETURN_PROPERTY_VALUES_FAILURE,
        data: err,
      })
    );
