import React, { type ChangeEvent, type FC } from 'react';
import LabelInfo from './LabelInfo';

interface Props {
  name: string;
  label: string;
  // eslint-disable-next-line
  value: any;
  info?: string;
  placeholder?: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

const InputTextArea: FC<Props> = ({ name, label, value, info, placeholder, onChange }) => (
  <div className='input-group'>
    <LabelInfo label={label} info={info} />
    <textarea name={name} value={value} placeholder={placeholder} onChange={onChange} />
  </div>
);

export default InputTextArea;
