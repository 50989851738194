import React, { type FC } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'src/redux/store';
import t from '../../lib/translate';
import { simpleHash } from '../../lib/string';

import './index.scss';

const LaunchNotesButton: FC = () => {
  const userName = useSelector((state: RootState) => state.userInfo.user?.username);

  if (!userName) {
    return <></>;
  }

  const token = 'public_kBUe8ogXUcYHcoCzvaFBIqqG';
  const project = 'pro_Fd3hCYI4mvyyk';
  const selector = 'launch-notes-button';

  const embed = `
    <launchnotes-embed
      token="${token}"
      project="${project}"
      categories='["cargostream"]'
      toggle-selector="#${selector}"
      heading="${t('launch.notes.heading')}"
      heading-color="#FFFFFF"
      subheading="${t('launch.notes.subheading')}"
      subheading-color="#FFFFFF"
      primary-color="#237AC2"
      widget-placement="auto"
      widget-offset-skidding="-150"
      widget-offset-distance="15"
      show-unread
      anonymous-user-id="${simpleHash(userName)}"
      unread-placement="right"
      unread-offset-skidding="0"
      unread-offset-distance="10"
      unread-background-color="#237AC2"
      unread-text-color="#ffffff"
    ></launchnotes-embed>
  `;

  return (
    <>
      <div id={selector}>{t('launch.notes.button.title')}</div>
      <div dangerouslySetInnerHTML={{ __html: embed }} />
    </>
  );
};

export default LaunchNotesButton;
