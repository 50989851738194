import {
  GET_STATIONS_SUCCESS,
  GET_STATIONS_FAILURE,
  CREATE_STATION_SUCCESS,
  EDIT_STATION_SUCCESS,
  DELETE_STATION_SUCCESS,
  CLONE_STATION_SUCCESS,
} from '../actions/stations';

import { UPDATE_STATION_REUSE_ALIAS_SUCCESS } from '../actions/reuseAlias';
import { useParams } from 'react-router-dom';

const stationsReducer = (state = null, action) => {
  switch (action.type) {
    case GET_STATIONS_SUCCESS:
      return action.data;

    case GET_STATIONS_FAILURE:
      return [];

    case CREATE_STATION_SUCCESS:
      return [...state, action.data];

    case DELETE_STATION_SUCCESS:
      return state.filter((station) => station.stationId !== action.data);

    case CLONE_STATION_SUCCESS:
      return [...state, action.data];

    case UPDATE_STATION_REUSE_ALIAS_SUCCESS:
    case EDIT_STATION_SUCCESS:
      return state.map((station) =>
        station.stationId === action.data.stationId ? action.data : station
      );

    default:
      return state;
  }
};

export default stationsReducer;

export const useStation = (stations) => {
  const { stationId } = useParams();
  const filteredStations = stations.filter((station) => station.stationId === +stationId);
  const station = filteredStations.length > 0 ? filteredStations[0] : {};
  return station;
};
