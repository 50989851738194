import React from 'react';
import { connect } from 'react-redux';

import Form from '../../components/Form';
import InputText from '../../components/InputText';

import { resetErrors } from '../../redux/actions/resetErrors';

const USERNAME = 'username';

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      [USERNAME]: '',
    };
  }

  componentWillUnmount() {
    this.props.resetErrors();
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  enableSubmit = () => this.state[USERNAME];

  closeModal = () => {
    this.props.finished(this.props.name);
  };

  handleSubmit = () => {
    if (this.enableSubmit()) {
      this.props.addStationUser(
        this.props.stationId,
        { username: this.state[USERNAME] },
        this.closeModal
      )(this.props.dispatch);
    }
  };

  render() {
    const { isLoading, error, name } = this.props;
    const enableSubmit = this.enableSubmit();

    const labelPrefix = `form.${name}`;

    return (
      <Form
        label={`${labelPrefix}.header`}
        isLoading={isLoading}
        error={error}
        enableSubmit={enableSubmit}
        onClose={this.closeModal}
        onSubmit={this.handleSubmit}>
        <InputText
          name={USERNAME}
          value={this.state[USERNAME]}
          label={`${labelPrefix}.${USERNAME}`}
          info={`${labelPrefix}.${USERNAME}.info`}
          handleChange={this.handleChange}
        />
      </Form>
    );
  }
}

const mapDispatchToProps = {
  resetErrors,
};

const mapStateToProps = (state) => ({
  isLoading: state.isLoading.primary,
  error: state.error.station.modal,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
