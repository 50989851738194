import { getRequest } from './util';

const getProperties = () => getRequest('/properties');

const getPropertyValues = (stationId) => getRequest(`/properties/${stationId}/values`);

const getReturnPropertyValues = (stationId) =>
  getRequest(`/properties/${stationId}/return/propertyValueLabels`);

export default {
  getProperties,
  getPropertyValues,
  getReturnPropertyValues,
};
