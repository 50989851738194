import { RESET_STATION_DATA } from '../actions/station';

import {
  GET_STATION_INTEGRATIONS_SUCCESS,
  GET_STATION_INTEGRATIONS_FAILURE,
  ADD_STATION_INTEGRATION_SUCCESS,
  DELETE_STATION_INTEGRATION_SUCCESS,
} from '../actions/stationIntegrations';

const stationIntegrationsReducer = (state = null, action) => {
  switch (action.type) {
    case GET_STATION_INTEGRATIONS_SUCCESS:
      return action.data;

    case GET_STATION_INTEGRATIONS_FAILURE:
      return [];

    case ADD_STATION_INTEGRATION_SUCCESS:
      return [...state, action.data];

    case DELETE_STATION_INTEGRATION_SUCCESS:
      return state.filter(
        (stationIntegration) => stationIntegration.stationIntegrationId !== action.data
      );

    case RESET_STATION_DATA:
      return null;

    default:
      return state;
  }
};

export default stationIntegrationsReducer;
