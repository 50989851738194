import { type RouteSegment } from 'src/types';
import { getRequest, putRequest } from './util';

const setNodeId = (routeSegments: RouteSegment[]): RouteSegment[] =>
  routeSegments.map((route) => ({
    ...route,
    nodeId: [route.nodeTypeCode, route.externalId].join('-'),
  }));

const sortByLabel = (routeSegments: RouteSegment[]): RouteSegment[] =>
  routeSegments.sort((a, b) => a.label?.localeCompare(b.label, undefined, { numeric: true }));

const get = async (stationId: number): Promise<RouteSegment[]> =>
  await getRequest(`/stations/${stationId}/segments`).then(setNodeId).then(sortByLabel);

const update = async (stationId: number, routeSegments: RouteSegment[]): Promise<void> =>
  await putRequest(`/stations/${stationId}/segments`, routeSegments);

export default {
  get,
  update,
};
