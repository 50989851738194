import { RESET_STATION_DATA } from '../actions/station';
import { RESET_ERRORS } from '../actions/resetErrors';

import {
  CREATE_ALIAS_GROUP_REQUEST,
  CREATE_ALIAS_GROUP_SUCCESS,
  CREATE_ALIAS_GROUP_FAILURE,
  UPDATE_ALIAS_GROUP_REQUEST,
  UPDATE_ALIAS_GROUP_SUCCESS,
  UPDATE_ALIAS_GROUP_FAILURE,
  DELETE_ALIAS_GROUP_REQUEST,
  DELETE_ALIAS_GROUP_SUCCESS,
  DELETE_ALIAS_GROUP_FAILURE,
} from '../actions/stationAliasGroups';
import { parseJSON } from '../../lib/format';

const INITIAL_STATE = {
  updating: false,
  error: null,
};

const stationAliasGroupsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATE_ALIAS_GROUP_REQUEST:
    case DELETE_ALIAS_GROUP_REQUEST:
    case UPDATE_ALIAS_GROUP_REQUEST:
      return {
        ...state,
        updating: true,
        error: null,
      };

    case CREATE_ALIAS_GROUP_SUCCESS:
    case DELETE_ALIAS_GROUP_SUCCESS:
    case UPDATE_ALIAS_GROUP_SUCCESS:
      return {
        ...state,
        updating: false,
      };

    case CREATE_ALIAS_GROUP_FAILURE:
    case DELETE_ALIAS_GROUP_FAILURE:
    case UPDATE_ALIAS_GROUP_FAILURE:
      return {
        ...state,
        updating: false,
        error: parseJSON(action.data),
      };

    case RESET_ERRORS:
      return {
        ...state,
        error: null,
      };

    case RESET_STATION_DATA:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default stationAliasGroupsReducer;
