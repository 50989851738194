import React from 'react';

import t from '../lib/translate';

class LabelInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  toggleExpanded = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  render() {
    const { label, info } = this.props;
    const { expanded } = this.state;

    const id = `${label}-${info}`;

    return (
      <div className='label-info'>
        <div className={`label${this.props.group ? ' group' : ''}`}>
          {t(label)}
          {info && <i onClick={this.toggleExpanded} className='far fa-question-circle' />}
        </div>

        {info && (
          <div
            id={id}
            className={`info${expanded ? ' expanded' : ''}`}
            style={{
              maxHeight: expanded ? document.getElementById(id).scrollHeight : 0,
            }}>
            {t(info)}
          </div>
        )}
      </div>
    );
  }
}

export default LabelInfo;
