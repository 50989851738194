import { RESET_STATION_DATA } from '../actions/station';

import {
  GET_STATION_API_USERS_SUCCESS,
  GET_STATION_API_USERS_FAILURE,
  ADD_STATION_API_USER_SUCCESS,
  REMOVE_STATION_API_USER_SUCCESS,
} from '../actions/stationApiUsers';

const stationApiUsersReducer = (state = null, action) => {
  switch (action.type) {
    case GET_STATION_API_USERS_SUCCESS:
      return action.data;

    case GET_STATION_API_USERS_FAILURE:
      return [];

    case ADD_STATION_API_USER_SUCCESS:
      return [...state, action.data];

    case REMOVE_STATION_API_USER_SUCCESS:
      return state.filter((user) => user.username !== action.data);

    case RESET_STATION_DATA:
      return null;

    default:
      return state;
  }
};

export default stationApiUsersReducer;
