import React from 'react';
import { Link } from 'react-router-dom';

import ribbonDev from '../../images/ribbon_dev.png';
import ribbonStg from '../../images/ribbon_stg.png';
import './index.scss';

const EnvironmentRibbon = () => {
  const environmentRibbon = () => {
    switch (process.env.REACT_APP_ENV) {
      case 'dev':
      case 'local':
        return <img alt='dev environment' className='env-ribbon' src={ribbonDev} />;
      case 'stg':
        return <img alt='test environment' className='env-ribbon' src={ribbonStg} />;
      default:
    }
  };
  return (
    <div className='environment-ribbon'>
      <Link to='/'>{environmentRibbon()}</Link>
    </div>
  );
};

export default EnvironmentRibbon;
