import {
  GET_SORTING_DEGREES_SUCCESS,
  GET_SORTING_DEGREES_FAILURE,
} from '../actions/sortingDegrees';

const sortingDegreesReducer = (state = null, action) => {
  switch (action.type) {
    case GET_SORTING_DEGREES_SUCCESS:
      return action.data;

    case GET_SORTING_DEGREES_FAILURE:
      return [];

    default:
      return state;
  }
};

export default sortingDegreesReducer;
