import React from 'react';
import { connect } from 'react-redux';

import Form from '../../components/Form';
import InputSelectKeyValue from '../../components/InputSelectKeyValue';
import InputText from '../../components/InputText';

import {
  createStationReturnConfig,
  updateStationReturnConfig,
} from '../../redux/actions/stationReturnConfigs';
import { resetErrors } from '../../redux/actions/resetErrors';
import t from '../../lib/translate';

const NAME = 'name';
const ALIAS = 'alias';
const PROPERTY_FILTERS = 'propertyFilters';
const PRIORITY = 'priority';

class ReturnConfigForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = props.returnConfig || {
      [NAME]: '',
      [ALIAS]: '',
      [PROPERTY_FILTERS]: [{ key: undefined, value: undefined }],
      [PRIORITY]: props.nextPriority,
    };
  }

  updateFieldValue = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.updateFieldValue(name, value);
  };

  enableSubmit = () => {
    const filters = this.state[PROPERTY_FILTERS];

    const emptyFilters = filters.filter((filter) => !(filter.key && filter.value));

    return emptyFilters.length > 0 ? false : this.state[NAME] && this.state[ALIAS];
  };

  toApiRequest = () => ({
    [NAME]: this.state[NAME],
    [ALIAS]: this.state[ALIAS],
    apiPropertyFilters: this.state[PROPERTY_FILTERS].map((pf) => ({
      propertyId: pf.key,
      externalId: pf.value,
    })),
    [PRIORITY]: this.state[PRIORITY],
  });

  closeModal = () => {
    this.props.finished(this.props.name);
  };

  closeModalAndResetFormErrors = () => {
    this.closeModal();
    this.props.resetErrors();
  };

  handleSubmit = () => {
    if (this.enableSubmit()) {
      const req = this.toApiRequest();

      this.props.returnConfig
        ? this.props.updateStationReturnConfig(
            this.props.stationId,
            req,
            this.state.id,
            this.closeModal
          )
        : this.props.createStationReturnConfig(this.props.stationId, req, this.closeModal);
    }
  };

  render() {
    const { isLoading, error, mappedReturnProperties, mappedPropertyValues } = this.props;
    const enableSubmit = this.enableSubmit();

    return (
      <Form
        label='form.return.config'
        isLoading={isLoading}
        error={error}
        enableSubmit={enableSubmit}
        onClose={this.closeModalAndResetFormErrors}
        onSubmit={this.handleSubmit}>
        <InputText
          name={NAME}
          value={this.state[NAME]}
          label='form.return.config.name'
          handleChange={this.handleChange}
          maxLength='64'
        />

        <InputText
          name={ALIAS}
          value={this.state[ALIAS]}
          label='form.return.config.alias'
          info='form.return.config.alias.info'
          handleChange={this.handleChange}
          maxLength='3'
        />

        <InputSelectKeyValue
          name={PROPERTY_FILTERS}
          label='form.return.config.propertyFilter'
          info='form.return.config.propertyFilter.info'
          placeholderKey='form.return.config.propertyFilters.property'
          placeholderValue='form.return.config.propertyFilters.value'
          selectedKeyValues={this.state[PROPERTY_FILTERS]}
          onChange={this.updateFieldValue}
          keyOptions={mappedReturnProperties}
          keyValueOptions={mappedPropertyValues}
          required
        />

        <InputText
          name={PRIORITY}
          value={this.state[PRIORITY]}
          label='form.return.config.priority'
          handleChange={this.handleChange}
          type='number'
          maxLength='3'
          required
        />
      </Form>
    );
  }
}

const mapDispatchToProps = {
  createStationReturnConfig,
  resetErrors,
  updateStationReturnConfig,
};

const mapStateToProps = (state) => {
  const propertyValues = state.station.returnPropertyValues;
  const { properties } = state.staticData;
  const mappedPropertyValues = {};

  const mappedReturnProperties =
    properties &&
    properties
      .filter((prop) => prop.returnCompatible)
      .map((prop) => ({ value: prop.propertyId, label: t(prop.i18nKey) }));

  propertyValues &&
    Object.keys(propertyValues).forEach((propertyKey) => {
      mappedPropertyValues[propertyKey] = propertyValues[propertyKey].map((pv) => ({
        value: pv.externalId,
        label: t(pv.label),
      }));
    });

  return {
    isLoading: state.isLoading.primary,
    error: state.error.station.modal,
    mappedReturnProperties,
    mappedPropertyValues,
    nextPriority: state.station.returnConfigurations.length + 1,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReturnConfigForm);
