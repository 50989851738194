import { type FC, useState } from 'react';

interface Props {
  message?: string;
}

const TableHeaderHelpText: FC<Props> = ({ message }) => {
  const [open, toggleOpen] = useState(false);

  const handleClick = (): void => {
    toggleOpen(!open);
  };

  if (!message) {
    return <></>;
  }

  return (
    <>
      <span className='button' onClick={handleClick}>
        <i className='far fa-question-circle' />
      </span>
      <div className='table-header-info'>{open && message}</div>
    </>
  );
};

export default TableHeaderHelpText;
