import { stationAliasBranches } from '../../api';

export const GET_STATION_ALIAS_BRANCHES_REQUEST = 'GET_STATION_ALIAS_BRANCHES_REQUEST';
export const GET_STATION_ALIAS_BRANCHES_SUCCESS = 'GET_STATION_ALIAS_BRANCHES_SUCCESS';
export const GET_STATION_ALIAS_BRANCHES_FAILURE = 'GET_STATION_ALIAS_BRANCHES_FAILURE';

export const UPDATE_STATION_ALIAS_BRANCHES_REQUEST = 'UPDATE_STATION_ALIAS_BRANCHES_REQUEST';
export const UPDATE_STATION_ALIAS_BRANCHES_SUCCESS = 'UPDATE_STATION_ALIAS_BRANCHES_SUCCESS';
export const UPDATE_STATION_ALIAS_BRANCHES_FAILURE = 'UPDATE_STATION_ALIAS_BRANCHES_FAILURE';

export const getAliasBranches = (stationId) => async (dispatch) => {
  await dispatch({ type: GET_STATION_ALIAS_BRANCHES_REQUEST });

  return stationAliasBranches
    .get(stationId)
    .then((aliasBranches) =>
      dispatch({
        type: GET_STATION_ALIAS_BRANCHES_SUCCESS,
        data: aliasBranches,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_STATION_ALIAS_BRANCHES_FAILURE,
        data: err,
      })
    );
};

export const updateAliasBranches = (stationId, aliasBranches) => (dispatch) => {
  dispatch({
    type: UPDATE_STATION_ALIAS_BRANCHES_REQUEST,
  });

  return stationAliasBranches
    .update(stationId, aliasBranches)
    .then((updatedBranches) => {
      dispatch({
        type: UPDATE_STATION_ALIAS_BRANCHES_SUCCESS,
        data: updatedBranches,
      });

      return Promise.resolve();
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_STATION_ALIAS_BRANCHES_FAILURE,
        data: err,
      });
      return Promise.reject(err);
    });
};
