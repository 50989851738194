import { getRequest, postRequest } from './util';
import { type ModelVersionUpdate, type NodeIdentifier, type OrderTransportRequest } from '../types';

const get = async (): Promise<ModelVersionUpdate[]> => await getRequest(`/modelversions`);

const getFilterOptions = async (): Promise<NodeIdentifier[]> =>
  await getRequest(`/modelversions/filterOptions`);

const getRemainingUpdates = async (): Promise<number> =>
  await getRequest(`/modelversions/remainingUpdates`);

const requestUpdate = async (updateRequest: OrderTransportRequest): Promise<void> =>
  await postRequest(`/modelversions/requestUpdate`, updateRequest);

const requestCompleteModel = async (): Promise<void> =>
  await postRequest(`/modelversions/requestCompleteModel`);

export default {
  get,
  getFilterOptions,
  getRemainingUpdates,
  requestUpdate,
  requestCompleteModel,
};
