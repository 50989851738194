import Stations from './stations/Stations';
import Station from './stations/Station';
import Settings from './Settings';
import SortingConfigs from './stations/stationPages/SortingConfigs';
import Alias from './stations/stationPages/Alias';
import Integrations from './stations/stationPages/Integrations';
import Return from './stations/stationPages/Return';
import RouteSegments from './stations/stationPages/routeSegments/RouteSegments';
import SelectRoutesStep from './stations/stationPages/routeSegments/SelectRoutesStep';
import SelectSegmentsStep from './stations/stationPages/routeSegments/SelectSegmentsStep';
import Users from './stations/stationPages/Users';
import Map from './stations/stationPages/Map';
import TransportPage from './transport/TransportPage';
import { ROLE_DI, ROLE_TRANSPORT_ADMIN } from '../api/util';

export const sections = [
  {
    name: 'header.stations',
    path: '/stations',
    component: <Stations />,
    includeInHeader: () => true,
  },
  {
    name: 'header.station',
    path: '/stations/:stationId/*',
    component: <Station />,
    includeInHeader: () => false,
  },
  {
    name: 'header.settings',
    path: '/settings',
    component: <Settings />,
    includeInHeader: () => false,
  },
  {
    name: 'header.transport',
    path: '/transport',
    component: <TransportPage />,
    includeInHeader: (roles) => roles.some((r) => [ROLE_TRANSPORT_ADMIN, ROLE_DI].includes(r)),
  },
];

export const sideNavSections = (station) => [
  {
    name: 'sidebar.sortingDegree',
    path: 'sortingDegrees',
    sectionClassName: 'sorting-degree',
    iconClassName: 'fas fa-sitemap',
    component: <SortingConfigs />,
  },
  {
    name: 'sidebar.alias',
    path: 'alias',
    sectionClassName: 'alias',
    iconClassName: 'fas fa-link',
    component: <Alias />,
  },
  {
    name: 'sidebar.network',
    path: 'map',
    sectionClassName: 'network',
    iconClassName: 'fas fa-map-marker-alt',
    component: <Map />,
  },
  {
    name: 'sidebar.integrations',
    path: 'integrations',
    sectionClassName: 'integrations',
    iconClassName: 'fas fa-cogs',
    component: <Integrations />,
  },
  {
    name: 'sidebar.return',
    path: 'return',
    sectionClassName: 'return',
    iconClassName: 'fas fa-recycle',
    component: <Return />,
  },
  {
    name: 'sidebar.users',
    path: 'users',
    sectionClassName: 'users',
    iconClassName: 'fas fa-user',
    component: <Users />,
  },
  ...(station.sortingDegreeId === 7 // Route Segment
    ? [
        {
          name: 'sidebar.routeSegments',
          path: 'routeSegments',
          sectionClassName: 'route-segments',
          iconClassName: 'fas fa-share-alt',
          component: <RouteSegments />,
          children: [
            {
              path: 'routeSegments/selectRoutes',
              component: <SelectRoutesStep />,
            },
            {
              path: 'routeSegments/selectSegments',
              component: <SelectSegmentsStep />,
            },
          ],
        },
      ]
    : []),
];
