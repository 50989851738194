import { stationUsers } from '../../api';
import { setPrimaryLoader } from './loader';
import { resetErrors } from './resetErrors';

export const GET_STATION_USERS_SUCCESS = 'GET_STATION_USERS_SUCCESS';
export const GET_STATION_USERS_FAILURE = 'GET_STATION_USERS_FAILURE';

export const ADD_STATION_USER_SUCCESS = 'ADD_STATION_USER_SUCCESS';
export const ADD_STATION_USER_FAILURE = 'ADD_STATION_USER_FAILURE';

export const EDIT_STATION_USER_SUCCESS = 'EDIT_STATION_USER_SUCCESS';
export const EDIT_STATION_USER_FAILURE = 'EDIT_STATION_USER_FAILURE';

export const CREATE_STATION_USER_SUCCESS = 'CREATE_STATION_USER_SUCCESS';
export const CREATE_STATION_USER_FAILURE = 'CREATE_STATION_USER_FAILURE';

export const REMOVE_STATION_USER_SUCCESS = 'REMOVE_STATION_USER_SUCCESS';
export const REMOVE_STATION_USER_FAILURE = 'REMOVE_STATION_USER_FAILURE';

export const getStationUsers = (stationId) => (dispatch) => {
  dispatch(setPrimaryLoader(true));
  stationUsers
    .get(stationId)
    .then((users) =>
      dispatch({
        type: GET_STATION_USERS_SUCCESS,
        data: users,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_STATION_USERS_FAILURE,
        data: err,
      })
    )
    .finally(() => {
      dispatch(setPrimaryLoader(false));
    });
};

export const addStationUser = (stationId, user, onSuccess) => (dispatch) => {
  dispatch(setPrimaryLoader(true));
  dispatch(resetErrors());

  stationUsers
    .post(stationId, user)
    .then((addedUser) =>
      dispatch({
        type: ADD_STATION_USER_SUCCESS,
        data: addedUser,
      })
    )
    .then(() => onSuccess())
    .catch((err) =>
      dispatch({
        type: ADD_STATION_USER_FAILURE,
        data: err,
      })
    )
    .finally(() => {
      dispatch(setPrimaryLoader(false));
    });
};

export const createStationUser = (stationId, user, onSuccess) => (dispatch) => {
  dispatch(setPrimaryLoader(true));
  dispatch(resetErrors());

  stationUsers
    .create(stationId, user)
    .then((createdUser) =>
      dispatch({
        type: CREATE_STATION_USER_SUCCESS,
        data: createdUser,
      })
    )
    .then(() => onSuccess())
    .catch((err) =>
      dispatch({
        type: CREATE_STATION_USER_FAILURE,
        data: err,
      })
    )
    .finally(() => {
      dispatch(setPrimaryLoader(false));
    });
};

export const editStationUser = (stationId, user, onSuccess) => (dispatch) => {
  dispatch(setPrimaryLoader(true));
  dispatch(resetErrors());
  stationUsers
    .edit(stationId, user)
    .then((updatedUser) =>
      dispatch({
        type: EDIT_STATION_USER_SUCCESS,
        data: updatedUser,
      })
    )
    .then(() => onSuccess())
    .catch((err) =>
      dispatch({
        type: EDIT_STATION_USER_FAILURE,
        data: err,
      })
    )
    .finally(() => {
      dispatch(setPrimaryLoader(false));
    });
};

export const removeStationUser = (stationId, username) => (dispatch) =>
  stationUsers
    .remove(stationId, username)
    .then(() =>
      dispatch({
        type: REMOVE_STATION_USER_SUCCESS,
        data: username,
      })
    )
    .catch((err) =>
      dispatch({
        type: REMOVE_STATION_USER_FAILURE,
        data: err,
      })
    );
