import React, { type FC } from 'react';

import LabelInfo from './LabelInfo';
import t from '../lib/translate';

interface Props {
  name: string;
  label: string;
  info?: string;
  value: boolean;
  handleChange: (name: string, value: boolean) => void;
}

const options = [
  { value: true, label: 'radio.yes' },
  { value: false, label: 'radio.no' },
];

const InputRadioYesNo: FC<Props> = ({ name, label, info, value, handleChange }) => (
  <div className='input-group'>
    <LabelInfo label={label} info={info} />
    <div className='radio-group-yes-no'>
      {options.map((option) => (
        <div key={`${name}${option.label}`} className={'radio-button'}>
          <input
            type='radio'
            name={name}
            checked={option.value === value}
            value={+option.value}
            onChange={() => {
              handleChange(name, option.value);
            }}
            id={`${name}${option.label}`}
          />
          <label className='radio' htmlFor={`${name}${option.label}`}>
            {t(option.label)}
          </label>
        </div>
      ))}
    </div>
  </div>
);

export default InputRadioYesNo;
