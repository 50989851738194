import React from 'react';

import Table from './Table';
import TableHeaderHelpText from './TableHeaderHelpText';
import t from '../lib/translate';

export const PREFIX = 'table';

const TableWithHeader = ({
  name,
  toggleModalOpen,
  buttons,
  open,
  toggleOpenTable,
  columnNames,
  rowData,
  deleteRow,
  editable,
  helpText,
}) => (
  <div className={`station-table ${name}`}>
    <div className='header'>
      <div>
        {toggleOpenTable && (
          <span name={name} className='button' onClick={toggleOpenTable}>
            <i className={`fas fa-angle-${open ? 'down' : 'right'}`} />
          </span>
        )}
        <h3>{t(`${PREFIX}.${name}`)}</h3>
        <TableHeaderHelpText message={helpText} />
      </div>
      <div className='button-container'>
        {!buttons && (
          <button name={name} onClick={toggleModalOpen.bind(null, name)}>
            {t(`${PREFIX}.${name}.new`)}
          </button>
        )}
        {buttons &&
          buttons
            .filter((button) => !button.hidden)
            .map((button, index) => (
              <button key={index} className={button.className} onClick={button.onClick}>
                {t(button.label)}
              </button>
            ))}
      </div>
    </div>

    {open && (
      <Table
        tableName={name}
        columnNames={columnNames}
        rowData={rowData}
        deleteRow={deleteRow}
        toggleModalOpen={toggleModalOpen}
        editable={editable}
      />
    )}
  </div>
);

export default TableWithHeader;
