import { stationReturnAliasGroups } from '../../api';
import { setPrimaryLoader } from './loader';
import { resetErrors } from './resetErrors';
import { getReturnAliasGroupOptions } from './stationReturnAliasGroupOptions';

export const GET_RETURN_ALIAS_GROUPS_SUCCESS = 'GET_RETURN_ALIAS_GROUPS_SUCCESS';
export const GET_RETURN_ALIAS_GROUPS_FAILURE = 'GET_RETURN_ALIAS_GROUPS_FAILURE';

export const CREATE_RETURN_ALIAS_GROUPS_SUCCESS = 'CREATE_RETURN_ALIAS_GROUPS_SUCCESS';
export const CREATE_RETURN_ALIAS_GROUPS_FAILURE = 'CREATE_RETURN_ALIAS_GROUPS_FAILURE';

export const UPDATE_RETURN_ALIAS_GROUPS_SUCCESS = 'UPDATE_RETURN_ALIAS_GROUPS_SUCCESS';
export const UPDATE_RETURN_ALIAS_GROUPS_FAILURE = 'UPDATE_RETURN_ALIAS_GROUPS_FAILURE';

export const DELETE_RETURN_ALIAS_GROUPS_SUCCESS = 'DELETE_RETURN_ALIAS_GROUPS_SUCCESS';
export const DELETE_RETURN_ALIAS_GROUPS_FAILURE = 'DELETE_RETURN_ALIAS_GROUPS_FAILURE';

export const getReturnAliasGroups = (stationId) => (dispatch) =>
  stationReturnAliasGroups
    .get(stationId)
    .then((returnAliasGroups) =>
      dispatch({
        type: GET_RETURN_ALIAS_GROUPS_SUCCESS,
        data: returnAliasGroups,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_RETURN_ALIAS_GROUPS_FAILURE,
        data: err,
      })
    );

export const createReturnAliasGroup = (stationId, aliasGroup, onSuccess) => (dispatch) => {
  dispatch(setPrimaryLoader(true));
  dispatch(resetErrors());

  return stationReturnAliasGroups
    .create(stationId, aliasGroup)
    .then((createdAliasGroupId) =>
      dispatch({
        type: CREATE_RETURN_ALIAS_GROUPS_SUCCESS,
        data: createdAliasGroupId,
      })
    )
    .then(() => onSuccess())
    .then(() => {
      dispatch(getReturnAliasGroups(stationId));
      dispatch(getReturnAliasGroupOptions(stationId));
    })
    .catch((err) =>
      dispatch({
        type: CREATE_RETURN_ALIAS_GROUPS_FAILURE,
        data: err,
      })
    )
    .finally(() => {
      dispatch(setPrimaryLoader(false));
    });
};

export const updateReturnAliasGroup =
  (stationId, aliasGroupId, aliasGroup, onSuccess) => (dispatch) => {
    dispatch(setPrimaryLoader(true));
    dispatch(resetErrors());

    return stationReturnAliasGroups
      .update(stationId, aliasGroupId, aliasGroup)
      .then((aliasGroup) =>
        dispatch({
          type: UPDATE_RETURN_ALIAS_GROUPS_SUCCESS,
          data: aliasGroup,
        })
      )
      .then(() => onSuccess())
      .then(() => {
        dispatch(getReturnAliasGroups(stationId));
        dispatch(getReturnAliasGroupOptions(stationId));
      })
      .catch((err) =>
        dispatch({
          type: UPDATE_RETURN_ALIAS_GROUPS_FAILURE,
          data: err,
        })
      )
      .finally(() => {
        dispatch(setPrimaryLoader(false));
      });
  };

export const deleteReturnAliasGroup = (stationId, aliasGroupId) => (dispatch) =>
  stationReturnAliasGroups
    .remove(stationId, aliasGroupId)
    .then((deletedId) =>
      dispatch({
        type: DELETE_RETURN_ALIAS_GROUPS_SUCCESS,
        data: deletedId,
      })
    )
    .then(() => {
      dispatch(getReturnAliasGroups(stationId));
      dispatch(getReturnAliasGroupOptions(stationId));
    })
    .catch((err) =>
      dispatch({
        type: DELETE_RETURN_ALIAS_GROUPS_FAILURE,
        data: err,
      })
    );
