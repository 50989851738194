import { parseJSON } from '../../lib/format';

import { GET_ALIAS_TYPES_FAILURE } from '../actions/aliasAllocationMethods';
import { GET_INTEGRATIONS_FAILURE } from '../actions/integrations';
import { GET_PROPERTIES_FAILURE } from '../actions/properties';
import { GET_PROPERTY_VALUES_FAILURE } from '../actions/stationPropertyValues';
import { GET_SORTING_DEGREES_FAILURE } from '../actions/sortingDegrees';
import { RESET_ERRORS } from '../actions/resetErrors';
import { REGENERATE_ALIASES_FAILURE } from '../actions/station';
import { ROUTE_SEGMENTS_FAILURE } from '../actions/routeSegments';
import { AUTHENTICATION_FAILURE } from '../actions/authentication';
import { GET_NODES_FAILURE } from '../actions/nodes';
import { GET_STATION_NETWORK_FAILURE } from '../actions/stationNetworkLayout';

import { UPDATE_STATION_REUSE_ALIAS_FAILURE, RESET_ALIASES_FAILURE } from '../actions/reuseAlias';

import {
  GET_STATIONS_FAILURE,
  CREATE_STATION_FAILURE,
  EDIT_STATION_FAILURE,
  CLONE_STATION_FAILURE,
  DELETE_STATION_FAILURE,
  RESET_ERROR_NEW_STATION,
  RESET_ERROR_EDIT_STATION,
  RESET_ERROR_CLONE_STATION,
} from '../actions/stations';

import {
  GET_STATION_INTEGRATIONS_FAILURE,
  ADD_STATION_INTEGRATION_FAILURE,
  DELETE_STATION_INTEGRATION_FAILURE,
} from '../actions/stationIntegrations';

import {
  GET_STATION_RETURN_CONFIGS_FAILURE,
  CREATE_STATION_RETURN_CONFIG_FAILURE,
  DELETE_STATION_RETURN_CONFIG_FAILURE,
  UPDATE_STATION_RETURN_CONFIG_FAILURE,
} from '../actions/stationReturnConfigs';

import {
  GET_ALIAS_LETTER_LIMTITS_FAILURE,
  UPDATE_ALIAS_LETTER_LIMITS_FAILURE,
} from '../actions/stationAliasLetterLimits';

import {
  ADD_STATION_USER_FAILURE,
  CREATE_STATION_USER_FAILURE,
  EDIT_STATION_USER_FAILURE,
  GET_STATION_USERS_FAILURE,
  REMOVE_STATION_USER_FAILURE,
} from '../actions/stationUsers';

import {
  ADD_STATION_API_USER_FAILURE,
  GET_STATION_API_USERS_FAILURE,
} from '../actions/stationApiUsers';

import {
  CREATE_ALIAS_GROUP_FAILURE,
  DELETE_ALIAS_GROUP_FAILURE,
} from '../actions/stationAliasGroups';

import {
  GET_STATION_ALIAS_BRANCHES_FAILURE,
  UPDATE_STATION_ALIAS_BRANCHES_FAILURE,
} from '../actions/stationAliasBranches';

const INITIAL_STATE = {
  authentication: false,
  staticData: false,
  station: {
    page: false,
    modal: false,
  },
  stations: {
    page: false,
    newStation: false,
  },
  stationMap: false,
};

const errorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_ERRORS:
      return INITIAL_STATE;

    case AUTHENTICATION_FAILURE:
      return {
        ...state,
        authentication: parseJSON(action.data),
      };

    case GET_ALIAS_TYPES_FAILURE:
    case GET_INTEGRATIONS_FAILURE:
    case GET_PROPERTIES_FAILURE:
    case GET_SORTING_DEGREES_FAILURE:
    case GET_STATIONS_FAILURE:
      console.error(action.data);
      return {
        ...state,
        staticData: parseJSON(action.data),
      };

    case CREATE_STATION_FAILURE:
    case GET_NODES_FAILURE:
      console.error(action.data);
      return {
        ...state,
        stations: {
          ...state.stations,
          newStation: parseJSON(action.data),
        },
      };

    case RESET_ERROR_NEW_STATION:
      return {
        ...state,
        stations: {
          ...state.stations,
          newStation: INITIAL_STATE.stations.newStation,
        },
      };

    case RESET_ERROR_EDIT_STATION:
      return {
        ...state,
        station: {
          ...state.station,
          editStation: INITIAL_STATE.station.editStation,
        },
      };

    case RESET_ERROR_CLONE_STATION:
      return {
        ...state,
        station: {
          ...state.station,
          cloneStation: INITIAL_STATE.station.cloneStation,
        },
      };

    case CLONE_STATION_FAILURE:
    case DELETE_ALIAS_GROUP_FAILURE:
    case DELETE_STATION_FAILURE:
    case DELETE_STATION_INTEGRATION_FAILURE:
    case DELETE_STATION_RETURN_CONFIG_FAILURE:
    case GET_ALIAS_LETTER_LIMTITS_FAILURE:
    case GET_STATION_ALIAS_BRANCHES_FAILURE:
    case GET_PROPERTY_VALUES_FAILURE:
    case GET_STATION_INTEGRATIONS_FAILURE:
    case GET_STATION_RETURN_CONFIGS_FAILURE:
    case GET_STATION_USERS_FAILURE:
    case GET_STATION_API_USERS_FAILURE:
    case REMOVE_STATION_USER_FAILURE:
    case ROUTE_SEGMENTS_FAILURE:
      console.error(action.data);
      return {
        ...state,
        station: {
          ...state.station,
          page: parseJSON(action.data),
        },
      };

    case ADD_STATION_INTEGRATION_FAILURE:
    case ADD_STATION_USER_FAILURE:
    case ADD_STATION_API_USER_FAILURE:
    case CREATE_STATION_USER_FAILURE:
    case EDIT_STATION_USER_FAILURE:
    case CREATE_ALIAS_GROUP_FAILURE:
    case CREATE_STATION_RETURN_CONFIG_FAILURE:
    case EDIT_STATION_FAILURE:
    case RESET_ALIASES_FAILURE:
    case REGENERATE_ALIASES_FAILURE:
    case UPDATE_STATION_REUSE_ALIAS_FAILURE:
    case UPDATE_ALIAS_LETTER_LIMITS_FAILURE:
    case UPDATE_STATION_ALIAS_BRANCHES_FAILURE:
    case UPDATE_STATION_RETURN_CONFIG_FAILURE:
      console.error(action.data);
      return {
        ...state,
        station: {
          ...state.station,
          modal: parseJSON(action.data),
        },
      };

    case GET_STATION_NETWORK_FAILURE:
      console.error(action.data);
      return {
        ...state,
        stationMap: parseJSON(action.data),
      };

    default:
      return state;
  }
};

export default errorReducer;
