import { RESET_STATION_DATA } from '../actions/station';
import { mapListToObject } from '../../lib/format';

import {
  GET_ALIAS_LETTER_LIMTITS_SUCCESS,
  GET_ALIAS_LETTER_LIMTITS_FAILURE,
  UPDATE_ALIAS_LETTER_LIMITS_SUCCESS,
} from '../actions/stationAliasLetterLimits';

const aliasLetterLimitsReducer = (state = null, action) => {
  switch (action.type) {
    case GET_ALIAS_LETTER_LIMTITS_SUCCESS:
      return action.data;

    case UPDATE_ALIAS_LETTER_LIMITS_SUCCESS: {
      const letterLimits = mapListToObject(action.data, 'aliasLetter', 'aliasLimit');
      return state.map((all) => ({
        aliasLetter: all.aliasLetter,
        aliasLimit: letterLimits[all.aliasLetter] || null,
      }));
    }

    case GET_ALIAS_LETTER_LIMTITS_FAILURE:
      return [];

    case RESET_STATION_DATA:
      return null;

    default:
      return state;
  }
};

export default aliasLetterLimitsReducer;
