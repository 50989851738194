import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Form from '../../../../components/Form';
import { type RouteSegment } from 'src/types';
import { parseJSON } from '../../../../lib/format';
import t from '../../../../lib/translate';

interface State {
  routeSegments?: RouteSegment[];
}

interface Props {
  buttonLabel?: string;
  onSubmit: () => Promise<void>;
  onNavigateBack?: () => void;
  children: React.ReactNode;
}

const Step = ({ buttonLabel, onSubmit, onNavigateBack, children }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const state = useLocation().state as State;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigateToRouteSegments = (): void => {
    navigate('..', { relative: 'path' });
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      setError(null);
      setIsLoading(true);
      await onSubmit();
    } catch (e) {
      console.error(e);
      setError(parseJSON(e));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!state?.routeSegments) navigateToRouteSegments();
  }, []);

  if (!state?.routeSegments) return <div />;

  return (
    <div className='route-segments'>
      <h3 className='header'>{t('routeSegments.edit')}</h3>
      <div className='form-wrapper'>
        <Form
          buttonLabel={buttonLabel}
          enableSubmit
          isLoading={isLoading}
          error={error}
          onClose={navigateToRouteSegments}
          onNavigateBack={onNavigateBack}
          fixedFooter={true}
          onSubmit={handleSubmit as () => void}>
          {children}
        </Form>
      </div>
    </div>
  );
};

export default Step;
