import { authentication } from '../../api';
import { setPrimaryLoader } from './loader';
import { resetErrors } from './resetErrors';

export const AUTHORIZED = 'AUTHORIZED';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const AUTHENTICATION_FAILURE = 'AUTHENTICATION_FAILURE';

export const authenticated = {
  type: AUTHORIZED,
};

export const unauthorized = {
  type: UNAUTHORIZED,
};

export const logout = () => (dispatch) => {
  dispatch(unauthorized);
  localStorage.removeItem('jwt-token');
};

export const checkAuthentication = () => (dispatch) => {
  const validToken = !!localStorage.getItem('jwt-token');

  if (validToken) {
    dispatch(authenticated);
  } else {
    logout();
  }
};

export const login = (credentials) => (dispatch) => {
  dispatch(setPrimaryLoader(true));
  dispatch(resetErrors());
  authentication
    .login(credentials)
    .then(
      () => dispatch(authenticated),
      (err) =>
        dispatch({
          type: AUTHENTICATION_FAILURE,
          data: err,
        })
    )
    .finally(() => {
      dispatch(setPrimaryLoader(false));
    });
};
