import { aliasAllocationMethods } from '../../api';

export const GET_ALIAS_TYPES_SUCCESS = 'GET_ALIAS_TYPES_SUCCESS';
export const GET_ALIAS_TYPES_FAILURE = 'GET_ALIAS_TYPES_FAILURE';

export const getAliasAllocationMethods = () => (dispatch) =>
  aliasAllocationMethods
    .get()
    .then((aliasAllocationMethods) =>
      dispatch({
        type: GET_ALIAS_TYPES_SUCCESS,
        data: aliasAllocationMethods,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ALIAS_TYPES_FAILURE,
        data: err,
      })
    );
