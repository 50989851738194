import { getRequest, postRequest, deleteRequest } from './util';

const get = (stationId) => getRequest(`/stations/${stationId}/api/users`);

const post = (stationId, user) => postRequest(`/stations/${stationId}/api/users`, user);

const remove = (stationId, userId) => deleteRequest(`/stations/${stationId}/api/users/${userId}`);

export default {
  get,
  post,
  remove,
};
