import { RESET_ERRORS } from '../actions/resetErrors';
import { RESET_STATION_DATA } from '../actions/station';
import { parseJSON } from '../../lib/format';
import {
  GET_STATION_RESERVED_ALIASES_FAILURE,
  GET_STATION_RESERVED_ALIASES_REQUEST,
  GET_STATION_RESERVED_ALIASES_SUCCESS,
} from '../actions/stationReservedAliases';

const INITIAL_STATE = {
  loaded: false,
  updating: false,
  data: [],
  error: null,
};

// eslint-disable-next-line
const stationReservedAliasesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_STATION_RESERVED_ALIASES_REQUEST:
      return {
        ...state,
        updating: true,
        error: null,
      };

    case GET_STATION_RESERVED_ALIASES_SUCCESS:
      return {
        ...state,
        loaded: true,
        updating: false,
        data: action.data,
      };

    case GET_STATION_RESERVED_ALIASES_FAILURE:
      return {
        ...state,
        loaded: true,
        updating: false,
        error: parseJSON(action.data),
      };

    case RESET_ERRORS:
      return {
        ...state,
        error: null,
      };

    case RESET_STATION_DATA:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default stationReservedAliasesReducer;
