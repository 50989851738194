import React from 'react';
import { connect } from 'react-redux';
import Form from '../../components/Form';
import InputSelect from '../../components/InputSelect';
import { resetErrors } from '../../redux/actions/resetErrors';
import t from '../../lib/translate';

import {
  createReturnAliasGroup,
  updateReturnAliasGroup,
} from '../../redux/actions/stationReturnAliasGroups';

class ReturnAliasGroupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mainAlias: { label: '', value: '' },
      aliasGroup: [],
    };
  }

  componentDidMount() {
    const { selectedAliasGroup } = this.props;
    if (selectedAliasGroup) {
      const mainAlias = selectedAliasGroup.returnConfigs.find(
        (config) => config.stationReturnConfigId === selectedAliasGroup.mainAliasId
      );

      this.setState({
        aliasGroup: selectedAliasGroup.returnConfigs.map((config) => ({
          label: `(${config.alias}) ${config.name}`,
          value: config.stationReturnConfigId,
        })),
        mainAlias: {
          label: `(${mainAlias.alias}) ${mainAlias.name}`,
          value: selectedAliasGroup.mainAliasId,
        },
      });
    }
  }

  componentWillUnmount() {
    this.props.resetErrors();
  }

  handleAddAliasToGroup = (aliasGroup) => {
    if (!aliasGroup.find((aliasGroup) => aliasGroup.value === this.state.mainAlias.value)) {
      this.setState({ mainAlias: { label: '', value: '' } });
    }
    this.setState({ aliasGroup });
  };

  handleSetMainAlias = (mainAlias) => {
    this.setState({ mainAlias });
  };

  enableSubmit = () => this.state.aliasGroup.length > 1 && this.state.mainAlias.value !== '';

  closeModal = () => this.props.finished(this.props.name);

  mapAliasGroupOptions = () => {
    const { options, selectedAliasGroup } = this.props;

    // If it's update, add unselected items as options
    if (selectedAliasGroup) {
      for (const config of selectedAliasGroup.returnConfigs) {
        if (options.find((option) => option.id === config.stationReturnConfigId) === undefined) {
          options.push({
            alias: config.alias,
            name: config.name,
            id: config.stationReturnConfigId,
          });
        }
      }
    }

    return options
      .filter(
        (option) => this.state.aliasGroup.find((alias) => option.id === alias.value) === undefined
      )
      .map((option) => ({
        label: `(${option.alias}) ${option.name}`,
        value: option.id,
      }));
  };

  handleSubmit = () => {
    if (this.enableSubmit()) {
      const { stationId, createReturnAliasGroup, selectedAliasGroup, updateReturnAliasGroup } =
        this.props;
      const aliasGroup = {
        mainStationReturnConfigId: this.state.mainAlias.value,
        returnConfigIds: this.state.aliasGroup.map((alias) => alias.value) ?? [],
      };

      selectedAliasGroup
        ? updateReturnAliasGroup(
            stationId,
            selectedAliasGroup.aliasGroupId,
            aliasGroup,
            this.closeModal
          )
        : createReturnAliasGroup(stationId, aliasGroup, this.closeModal);
    }
  };

  render() {
    const { error, selectedAliasGroup } = this.props;
    const enableSubmit = this.enableSubmit();
    return (
      <Form
        error={error}
        label={
          selectedAliasGroup
            ? t('station.return.alias.groups.header.edit')
            : t('station.return.alias.groups.header')
        }
        enableSubmit={enableSubmit}
        onClose={this.closeModal}
        onSubmit={this.handleSubmit}>
        <InputSelect
          value={this.state.aliasGroup}
          onChange={this.handleAddAliasToGroup}
          options={this.mapAliasGroupOptions()}
          info={t('station.return.alias.groups.info')}
          label={t('station.return.alias.group')}
          isMulti
        />
        <InputSelect
          value={this.state.mainAlias}
          onChange={this.handleSetMainAlias}
          options={this.state.aliasGroup}
          info={t('station.return.alias.groups.main.info')}
          label={t('station.return.alias.group.main')}
        />
      </Form>
    );
  }
}

const mapDispatchToProps = {
  resetErrors,
  createReturnAliasGroup,
  updateReturnAliasGroup,
};

const mapStateToProps = (state, props) => {
  const returnAliasGroupOptions = state.station.returnAliasGroupOptions ?? [];
  const mappedOptions = returnAliasGroupOptions.map((option) => ({
    alias: option.alias,
    name: option.name,
    id: option.stationReturnConfigId,
  }));
  return {
    error: state.station.returnAliasGroups.error,
    options: mappedOptions,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReturnAliasGroupForm);
