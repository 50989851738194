import { stationSortingConfigs } from '../../api';
import { setPrimaryLoader } from './loader';
import { getAliasBranches } from './stationAliasBranches';

export const GET_SORTING_CONFIGS_REQUEST = 'GET_SORTING_CONFIGS_REQUEST';
export const GET_SORTING_CONFIGS_SUCCESS = 'GET_SORTING_CONFIGS_SUCCESS';
export const GET_SORTING_CONFIGS_FAILURE = 'GET_SORTING_CONFIGS_FAILURE';

export const CREATE_SORTING_CONFIG_REQUEST = 'CREATE_SORTING_CONFIG_REQUEST';
export const CREATE_SORTING_CONFIG_SUCCESS = 'CREATE_SORTING_CONFIG_SUCCESS';
export const CREATE_SORTING_CONFIG_FAILURE = 'CREATE_SORTING_CONFIG_FAILURE';

export const EDIT_SORTING_CONFIG_REQUEST = 'EDIT_SORTING_CONFIG_REQUEST';
export const EDIT_SORTING_CONFIG_SUCCESS = 'EDIT_SORTING_CONFIG_SUCCESS';
export const EDIT_SORTING_CONFIG_FAILURE = 'EDIT_SORTING_CONFIG_FAILURE';

export const DELETE_SORTING_CONFIG_REQUEST = 'DELETE_SORTING_CONFIG_REQUEST';
export const DELETE_SORTING_CONFIG_SUCCESS = 'DELETE_SORTING_CONFIG_SUCCESS';
export const DELETE_SORTING_CONFIG_FAILURE = 'DELETE_SORTING_CONFIG_FAILURE';

export const getSortingConfigs = (stationId) => async (dispatch) => {
  await dispatch(setPrimaryLoader(true));
  await dispatch({ type: GET_SORTING_CONFIGS_REQUEST });

  return stationSortingConfigs
    .get(stationId)
    .then((sortingConfig) =>
      dispatch({
        type: GET_SORTING_CONFIGS_SUCCESS,
        data: sortingConfig,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_SORTING_CONFIGS_FAILURE,
        data: err,
      })
    )
    .finally(() => dispatch(setPrimaryLoader(false)));
};

export const createSortingConfig = (stationId, config, onSuccess) => (dispatch) => {
  dispatch({ type: CREATE_SORTING_CONFIG_REQUEST });

  return stationSortingConfigs
    .create(stationId, config)
    .then((createdSortingConfig) =>
      dispatch({
        type: CREATE_SORTING_CONFIG_SUCCESS,
        data: createdSortingConfig,
      })
    )
    .then(() => onSuccess())
    .then(() => dispatch(getSortingConfigs(stationId)))
    .then(() => dispatch(getAliasBranches(stationId)))
    .catch((err) =>
      dispatch({
        type: CREATE_SORTING_CONFIG_FAILURE,
        data: err,
      })
    );
};

export const editSortingConfig = (stationId, config, onSuccess) => (dispatch) => {
  dispatch({ type: EDIT_SORTING_CONFIG_REQUEST });

  return stationSortingConfigs
    .edit(stationId, config)
    .then((editedSortingConfig) =>
      dispatch({
        type: EDIT_SORTING_CONFIG_SUCCESS,
        data: editedSortingConfig,
      })
    )
    .then(() => onSuccess())
    .then(() => dispatch(getSortingConfigs(stationId)))
    .then(() => dispatch(getAliasBranches(stationId)))
    .catch((err) =>
      dispatch({
        type: EDIT_SORTING_CONFIG_FAILURE,
        data: err,
      })
    );
};

export const deleteSortingConfig = (stationId, sortingConfigId) => (dispatch) =>
  stationSortingConfigs
    .remove(stationId, sortingConfigId)
    .then(() =>
      dispatch({
        type: DELETE_SORTING_CONFIG_SUCCESS,
        data: sortingConfigId,
      })
    )
    .then(() => dispatch(getSortingConfigs(stationId)))
    .then(() => dispatch(getAliasBranches(stationId)))
    .catch((err) =>
      dispatch({
        type: DELETE_SORTING_CONFIG_FAILURE,
        data: err,
      })
    );
