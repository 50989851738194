import {
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  GET_OWNERPARTIES_SUCCESS,
  GET_OWNERPARTIES_FAILURE,
  UPDATE_LOADING_STATE,
} from '../actions/userInfo';

const initialState = {
  user: {},
  ownerParties: [],
  loading: false,
  timestamp: false,
};

const userInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        user: action.data,
      };

    case GET_USER_INFO_FAILURE:
      return {
        ...state,
        user: {},
      };

    case UPDATE_LOADING_STATE:
      return {
        ...state,
        loading: action.data,
      };

    case GET_OWNERPARTIES_SUCCESS:
      return {
        ...state,
        ownerParties: action.data,
      };

    case GET_OWNERPARTIES_FAILURE:
      return {
        ...state,
        ownerParties: [],
      };

    default:
      return state;
  }
};

export default userInfoReducer;
