import React from 'react';
import { NavLink, Link } from 'react-router-dom';

import { sections } from './sections';
import t from '../lib/translate';
import EnvironmentRibbon from '../components/EnvironmentRibbon';
import LaunchNotesButton from '../components/LaunchNotesButton';
import Logo from '../images/Cargostream.svg';

const Header = ({ isAuthenticated, endSession, roles }) => (
  <header>
    {!isAuthenticated && (
      <div className='header-wrapper'>
        <EnvironmentRibbon />
        <div className='brand'>
          <div className='di-logo' />
          <div className='brand-name'>{t('header.brand')}</div>
        </div>
      </div>
    )}

    {isAuthenticated && (
      <>
        <div className='header-wrapper'>
          <EnvironmentRibbon />
          <Link className='brand' to='/stations'>
            <Logo preserveAspectRatio='xMinYMid meet' viewBox='0 -5 65 65' />
          </Link>

          <div
            className='fas fa-bars'
            onClick={(event) => {
              document.getElementsByTagName('header')[0].classList.toggle('expanded');
              event.target.classList.toggle('fa-times');
            }}
          />
          <div className='menu'>
            <div className='nav'>
              {sections
                .filter((section) => section.includeInHeader(roles))
                .map((section) => (
                  <NavLink key={section.name} to={section.path} exact={section.exact}>
                    {t(section.name)}
                  </NavLink>
                ))}
            </div>
            <div className='actions'>
              <LaunchNotesButton />
              <a
                href={process.env.REACT_APP_SCANNER_URL}
                target='_blank'
                rel='noopener noreferrer'
                className='operator-link'>
                {t('header.operator')}
              </a>
              <Link className='operator-link' to='/settings'>
                <i className='fas fa-cog inside-tooltip' />
                {t('header.settings')}
              </Link>
              <div className='logout-button' onClick={endSession}>
                {t('header.logout')}
              </div>
            </div>
          </div>
        </div>

        <div className='mobile-nav'>
          {sections
            .filter((section) => section.includeInHeader(roles))
            .map((section) => (
              <NavLink
                key={section.name}
                to={section.path}
                exact={section.exact}
                onClick={() => {
                  document.getElementsByTagName('header')[0].classList.toggle('expanded');
                  document.getElementsByClassName('fa-bars')[0].classList.toggle('fa-times');
                }}>
                {t(section.name)}
                <i className='fas fa-chevron-right' />
              </NavLink>
            ))}
          <a
            href={process.env.REACT_APP_SCANNER_URL}
            target='_blank'
            rel='noopener noreferrer'
            className='operator-link'>
            {t('header.operator')}
            <i className='fas fa-external-link-square-alt' />
          </a>
          <div className='logout-button' onClick={endSession}>
            {t('header.logout')}
            <i className='fas fa-sign-out-alt' />
          </div>
        </div>
      </>
    )}
  </header>
);

export default Header;
