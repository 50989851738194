import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Network } from 'vis-network';

import NotFound from '../../NotFound';
import LoaderPage from '../../LoaderPage';
import Error from '../../../components/Error';

import { getStationNetworkLayout } from '../../../redux/actions/stationNetworkLayout';
import t from '../../../lib/translate';
import { useStation } from '../../../redux/reducers/stations';

const OPTIONS = {
  physics: {
    stabilization: {
      enabled: true,
      // iterations: 400,
      updateInterval: 100,
      // onlyDynamicEdges: false,
      // fit: true
    },
  },
  layout: {
    hierarchical: {
      enabled: true,
      levelSeparation: 400,
      nodeSpacing: 400,
      edgeMinimization: true,
      direction: 'LR',
      sortMethod: 'directed',
    },
  },
  nodes: {
    shape: 'box',
    margin: 10,
  },
  edges: {
    arrows: {
      to: true,
    },
  },
};

const Map = (props) => {
  const appRef = useRef();

  const [isBuildingNetwork, setIsBuildingNetwork] = useState(false);

  const { getStationNetworkLayout, isLoading, error, stations, networkData } = props;

  const station = useStation(stations);

  useEffect(() => {
    station.stationId && getStationNetworkLayout(station.stationId);
  }, [station.stationId]);

  const onStabilizedNetwork = () => setIsBuildingNetwork(false);

  useEffect(() => {
    if (networkData.nodes.length > 0) {
      setIsBuildingNetwork(true);
      const network = new Network(appRef.current, networkData, OPTIONS);
      network.on('stabilized', onStabilizedNetwork);
    }
  }, [networkData.nodes.length]);

  if (!station.stationId) {
    return <NotFound message='station.not.found' />;
  }

  return (
    <div className='map-page'>
      {error && <Error error={error} withMargin />}

      {(isLoading || isBuildingNetwork) && (
        <LoaderPage message={isBuildingNetwork ? 'map.building.network' : 'map.loading.data'} />
      )}

      {!(isLoading || isBuildingNetwork) && networkData.nodes.length === 0 && (
        <div className='empty'>{t('map.network.empty')}</div>
      )}

      <div className='map' ref={appRef} />
    </div>
  );
};

const nodeTypeColorMap = {
  R: '#00bcd4',
  B: '#b4d0e9',
  F: '#60ab81',
  T: '#8bc34a',
  H: '#ffc107',
  FALLBACK: '#ea911d',
};

const getNodeColor = (nodeType) => nodeTypeColorMap[nodeType] || nodeTypeColorMap.FALLBACK;

const getNodeLabel = (node) => {
  let label = `${node.name} (${node.nodeTypeCode})`;

  if (node.nodeTypeCode === 'R' || node.nodeTypeCode === 'S') {
    label += ` [M${node.dayMatrix.days}]`;
  }
  return label;
};

const mapNodes = (nodes) =>
  nodes.map((node) => ({
    id: node.nodeId,
    label: getNodeLabel(node),
    color: getNodeColor(node.nodeTypeCode),
  }));

const mapBraches = (branches) =>
  branches.map((branch) => ({
    from: branch.startNode.nodeId,
    to: branch.endNode.nodeId,
    label: branch.label,
  }));

const mapDispatchToProps = {
  getStationNetworkLayout,
};

const mapStateToProps = (state) => {
  const nodes = state.station.networkLayout && state.station.networkLayout.nodes;
  const branches = state.station.networkLayout && state.station.networkLayout.branches;

  const data = {
    nodes: mapNodes(nodes || []),
    edges: mapBraches(branches || []),
  };

  return {
    stations: state.stations,
    isLoading: !state.station.networkLayout,
    error: state.error.stationMap,
    networkData: data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
