import t from './translate';

/* eslint-disable */ // TODO - rewrite properly to TS
export const toApiDate = (date) => {
  if (!date) {
    return null;
  }

  date = date.toISOString().substr(0, 10);

  const [year, month, day] = date.split('-');

  return year + month + day;
};

export const formatDate = (date) =>
  `${date.substring(6, 8)}.${date.substring(4, 6)}.${date.substring(0, 4)}`;

export const formatDateForEdit = (date) =>
  `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}`;

export const formatFromToDate = (fromDate, toDate) => {
  if (!fromDate) return '-';

  const formattedFromDate = formatDate(fromDate);

  if (!toDate) return `${formattedFromDate} -`;

  const formattedToDate = formatDate(toDate);

  return `${formattedFromDate} - ${formattedToDate}`;
};

export const formatPropertyFilterString = (propertyFilter, propertyIdLabelMap, propertyValues) => {
  const values = (propertyValues[propertyFilter.propertyId] || []).filter(
    (propertyValue) => propertyValue.externalId === propertyFilter.externalId.toString()
  );

  const value = values.length ? values[0] : { label: 'ERR' };

  return `${propertyIdLabelMap[propertyFilter.propertyId]} - ${t(value.label)}`;
};
/* eslint-enable */ // TODO - rewrite properly to TS

export const mapListToObject = (list, identificator, value = 'i18nKey'): string =>
  list.reduce((res, listObj) => {
    res[listObj[identificator]] = t(listObj[value]);
    return res;
  }, {});

export const parseJSON = (data): any => JSON.parse(data.message);
