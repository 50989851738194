import { userInfo } from '../../api';

export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';

export const UPDATE_USER_INFO_FAILURE = 'UPDATE_USER_INFO_FAILURE';
export const UPDATE_LOADING_STATE = 'UPDATE_LOADING_STATE';

export const GET_OWNERPARTIES_SUCCESS = 'GET_OWNERPARTIES_SUCCESS';
export const GET_OWNERPARTIES_FAILURE = 'GET_OWNERPARTIES_FAILURE';

export const getUserInfo = () => (dispatch) =>
  userInfo
    .get()
    .then((userInfo) => {
      dispatch({
        type: GET_USER_INFO_SUCCESS,
        data: userInfo,
      });
    })
    .catch((err) =>
      dispatch({
        type: GET_USER_INFO_FAILURE,
        data: err,
      })
    );

export const getOwnerParties = () => (dispatch) =>
  userInfo
    .getOwnerParties()
    .then((ownerparties) =>
      dispatch({
        type: GET_OWNERPARTIES_SUCCESS,
        data: ownerparties,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_OWNERPARTIES_FAILURE,
        data: err,
      })
    );

export const updateUserInfo = (UserInfoUpdateRequest) => async (dispatch) => {
  await dispatch({
    type: UPDATE_LOADING_STATE,
    data: true,
  });

  return userInfo
    .updateUserInfo(UserInfoUpdateRequest)
    .then(() => dispatch(getUserInfo()))
    .catch((err) =>
      dispatch({
        type: UPDATE_USER_INFO_FAILURE,
        data: err,
      })
    )
    .finally(() =>
      dispatch({
        type: UPDATE_LOADING_STATE,
        data: false,
      })
    );
};
