import React from 'react';

import Loader from './Loader';

const LoadingButton = ({ label, type = 'submit', isLoading, onClick, disabled }) => (
  <button type={type} className={isLoading ? 'loading' : ''} onClick={onClick} disabled={disabled}>
    {isLoading ? <Loader button /> : label}
  </button>
);

export default LoadingButton;
