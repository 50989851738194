import { RESET_STATION_DATA } from '../actions/station';

import {
  GET_STATION_NETWORK_SUCCESS,
  GET_STATION_NETWORK_FAILURE,
} from '../actions/stationNetworkLayout';

const stationNetworkLayoutReducer = (state = null, action) => {
  switch (action.type) {
    case GET_STATION_NETWORK_SUCCESS:
      return action.data;

    case GET_STATION_NETWORK_FAILURE:
      return {};

    case RESET_STATION_DATA:
      return null;

    default:
      return state;
  }
};

export default stationNetworkLayoutReducer;
