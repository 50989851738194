import { reuseAlias } from '../../api';
import { getAliasBranches } from './stationAliasBranches';

export const UPDATE_STATION_REUSE_ALIAS_REQUEST = 'UPDATE_STATION_REUSE_ALIAS_REQUEST';
export const UPDATE_STATION_REUSE_ALIAS_SUCCESS = 'UPDATE_STATION_REUSE_ALIAS_SUCCESS';
export const UPDATE_STATION_REUSE_ALIAS_FAILURE = 'UPDATE_STATION_REUSE_ALIAS_FAILURE';

export const RESET_ALIASES_REQUEST = 'RESET_ALIASES_REQUEST';
export const RESET_ALIASES_SUCCESS = 'RESET_ALIASES_SUCCESS';
export const RESET_ALIASES_FAILURE = 'RESET_ALIASES_FAILURE';

export const toggleReuseAlias = (stationId) => (dispatch) => {
  dispatch({ type: UPDATE_STATION_REUSE_ALIAS_REQUEST });

  return reuseAlias
    .toggle(stationId)
    .then((station) =>
      dispatch({
        type: UPDATE_STATION_REUSE_ALIAS_SUCCESS,
        data: station,
      })
    )
    .then(() => dispatch(getAliasBranches(stationId)))
    .catch((err) => {
      dispatch({
        type: UPDATE_STATION_REUSE_ALIAS_FAILURE,
        data: err,
      });

      return Promise.reject(err);
    });
};

export const resetAliases = (stationId) => async (dispatch) => {
  await dispatch({ type: RESET_ALIASES_REQUEST });

  return reuseAlias
    .resetAliases(stationId)
    .then((aliasBranches) =>
      dispatch({
        type: RESET_ALIASES_SUCCESS,
        data: aliasBranches,
      })
    )
    .then(() => getAliasBranches(stationId))
    .catch((err) => {
      dispatch({
        type: RESET_ALIASES_FAILURE,
        data: err,
      });

      return Promise.reject(err);
    });
};
