import React from 'react';
import Select from 'react-select';

import t from '../lib/translate';

const KeyValue = ({
  keyOptions,
  keySelected,
  valueOptions,
  value,
  onChangeKey,
  onChangeValue,
  handleRemove,
  index,
  enableRemove,
  placeholderKey,
  placeholderValue,
}) => (
  <div className='property-filter'>
    <Select
      className='react-select-parent'
      classNamePrefix='react-select'
      value={
        keySelected && keyOptions.filter((prop) => prop.value === keySelected).length
          ? keyOptions.filter((prop) => prop.value === keySelected)[0]
          : null
      }
      onChange={(selected) => onChangeKey(index, selected.value)}
      options={keyOptions}
      onSelectResetsInput={false}
      placeholder={t(placeholderKey)}
    />

    <Select
      className='react-select-parent'
      classNamePrefix='react-select'
      value={
        value && valueOptions.filter((val) => val.value.toString() === value.toString()).length
          ? valueOptions.filter((val) => val.value.toString() === value.toString())[0]
          : null
      }
      onChange={(selected) => onChangeValue(index, selected.value)}
      options={valueOptions}
      onSelectResetsInput={false}
      placeholder={t(keySelected ? placeholderValue : '-')}
      isDisabled={!keySelected}
    />

    {enableRemove && (
      <span className='delete' onClick={() => handleRemove(index)}>
        <i className='far fa-trash-alt' />
      </span>
    )}
  </div>
);

export default KeyValue;
