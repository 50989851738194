export const SET_PRIMARY_LOADER = 'SET_PRIMARY_LOADER';
export const SET_SECONDARY_LOADER = 'SET_SECONDARY_LOADER';

export const setPrimaryLoader = (isLoading) => ({
  type: SET_PRIMARY_LOADER,
  data: isLoading,
});

export const dispatchLoader = (isLoading) => (dispatch) =>
  dispatch({
    type: SET_PRIMARY_LOADER,
    data: isLoading,
  });

export const setSecondaryLoader = (isLoading) => ({
  type: SET_SECONDARY_LOADER,
  data: isLoading,
});
