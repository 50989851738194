import { RESET_STATION_DATA } from '../actions/station';
import {
  CREATE_RETURN_ALIAS_GROUPS_FAILURE,
  CREATE_RETURN_ALIAS_GROUPS_SUCCESS,
  DELETE_RETURN_ALIAS_GROUPS_FAILURE,
  DELETE_RETURN_ALIAS_GROUPS_SUCCESS,
  GET_RETURN_ALIAS_GROUPS_FAILURE,
  GET_RETURN_ALIAS_GROUPS_SUCCESS,
  UPDATE_RETURN_ALIAS_GROUPS_FAILURE,
  UPDATE_RETURN_ALIAS_GROUPS_SUCCESS,
} from '../actions/stationReturnAliasGroups';

const stationReturnAliasGroupsReducer = (state = null, action) => {
  switch (action.type) {
    case GET_RETURN_ALIAS_GROUPS_SUCCESS:
    case CREATE_RETURN_ALIAS_GROUPS_SUCCESS:
    case UPDATE_RETURN_ALIAS_GROUPS_SUCCESS:
    case DELETE_RETURN_ALIAS_GROUPS_SUCCESS:
      return action.data;

    case GET_RETURN_ALIAS_GROUPS_FAILURE:
    case CREATE_RETURN_ALIAS_GROUPS_FAILURE:
    case UPDATE_RETURN_ALIAS_GROUPS_FAILURE:
    case DELETE_RETURN_ALIAS_GROUPS_FAILURE:
      return [];

    case RESET_STATION_DATA:
      return null;

    default:
      return state;
  }
};

export default stationReturnAliasGroupsReducer;
