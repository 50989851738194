import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, useLocation } from 'react-router-dom';

import Error from './components/Error';
import Header from './views/Header';
import LoaderPage from './views/LoaderPage';
import NotFound from './views/NotFound';
import SystemInformationBar from './views/SystemInformationBar';

import { sections } from './views/sections';
import { resetErrors } from './redux/actions/resetErrors';
import { getAliasAllocationMethods } from './redux/actions/aliasAllocationMethods';
import { getIntegrations } from './redux/actions/integrations';
import { getSortingDegrees } from './redux/actions/sortingDegrees';
import { getStations } from './redux/actions/stations';
import { getProperties } from './redux/actions/properties';
import { loadLanguage } from './redux/actions/language';
import { logout } from './redux/actions/authentication';
import { Navigate, Routes } from 'react-router';

const AppRouter = (props) => {
  const {
    getAliasAllocationMethods,
    getIntegrations,
    getProperties,
    getSortingDegrees,
    getStations,
    loadLanguage,
    grantedRoles,
  } = props;

  const { pathname } = useLocation();

  useEffect(() => {
    getAliasAllocationMethods();
    getIntegrations();
    getProperties();
    getSortingDegrees();
    getStations();
    loadLanguage();
  }, []);

  useEffect(() => {
    props.resetErrors();
  }, [pathname]);

  const { initialLoadComplete, errorOnLoad, logout } = props;

  return (
    <div className='app-router-wrapper'>
      <Header isAuthenticated endSession={logout} roles={grantedRoles ?? []} />
      <SystemInformationBar />

      {initialLoadComplete && errorOnLoad && <Error error={errorOnLoad} withMargin />}

      <Routes>
        <Route path='/' element={<Navigate to='/stations' replace />} />
        {sections.map((section) => (
          <Route
            key={section.name}
            path={section.path}
            element={initialLoadComplete ? section.component : <LoaderPage />}
          />
        ))}
        <Route path='*' element={<NotFound />} />
      </Routes>
    </div>
  );
};

const mapDispatchToProps = {
  resetErrors,
  logout,
  getAliasAllocationMethods,
  getIntegrations,
  getProperties,
  getSortingDegrees,
  getStations,
  loadLanguage,
};

const mapStateToProps = (state) => {
  const { aliasAllocationMethods, integrations, sortingDegrees, properties } = state.staticData;
  const { stations } = state;

  return {
    errorOnLoad: state.error.staticData,
    selectedLanguage: state.language,
    initialLoadComplete:
      aliasAllocationMethods && integrations && sortingDegrees && stations && properties,
    grantedRoles: state.userInfo.user.grantedRoles,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
