import React, { type FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

import TableWithHeader from '../../components/TableWithHeader';
import SystemMessageForm from './SystemMessageForm';
import Modal from '../../components/Modal';
import { deleteSystemInfo } from '../../redux/actions/systemInfo';
import { formatDate } from '../../lib/dateUtil';
import Loader from '../../components/Loader';
import { type RootState } from 'src/redux/store';
import { type SystemMessage } from 'src/types';

const SystemMessageList: FC = () => {
  const isLoading = useAppSelector((state: RootState) => state.systemInfo.loading);
  const messages = useAppSelector((state: RootState) => state.systemInfo.data);
  const [modalOpen, setModalOpen] = useState(false);
  const [message, setMessage] = useState<SystemMessage | undefined>();
  const dispatch = useAppDispatch();

  const deleteMessage = (message: SystemMessage): void => {
    // @ts-expect-error Supressing dispatch issues as we do not have properly typed store - need bigger refactor
    dispatch(deleteSystemInfo(message.systemInfoId));
  };

  const editMessage = (rowIndex: number): void => {
    setMessage(messages[rowIndex]);
    setModalOpen(true);
  };

  const createMessage = (): void => {
    setMessage(undefined);
    setModalOpen(true);
  };

  const headerButtons = [
    {
      label: 'table.settings.system.messages.create',
      onClick: createMessage,
      className: 'createSortingConfig',
    },
  ];

  const rowData = messages.map((m) => ({
    systemInfoId: m.systemInfoId,
    message: m.message,
    fromDate: formatDate(m.fromDate),
    toDate: formatDate(m.toDate),
    important: m.importantFromDate ? <i className='fas fa-check' /> : '',
  }));

  return (
    <>
      <Modal open={modalOpen}>
        <SystemMessageForm
          systemMessage={message}
          closeModal={() => {
            setModalOpen(false);
          }}
        />
      </Modal>
      <TableWithHeader
        name='settings.system.messages'
        columnNames={['message', 'fromDate', 'toDate', 'important']}
        rowData={rowData}
        open={!isLoading}
        deleteRow={deleteMessage}
        editable={editMessage}
        buttons={headerButtons}
      />
      {isLoading && <Loader button={null} />}
    </>
  );
};

export default SystemMessageList;
