import { RESET_STATION_DATA } from '../actions/station';
import {
  GET_RETURN_ALIAS_GROUP_OPTIONS_FAILURE,
  GET_RETURN_ALIAS_GROUP_OPTIONS_SUCCESS,
} from '../actions/stationReturnAliasGroupOptions';

const stationReturnAliasGroupOptionsReducer = (state = null, action) => {
  switch (action.type) {
    case GET_RETURN_ALIAS_GROUP_OPTIONS_SUCCESS:
      return action.data;

    case GET_RETURN_ALIAS_GROUP_OPTIONS_FAILURE:
      return [];

    case RESET_STATION_DATA:
      return null;

    default:
      return state;
  }
};

export default stationReturnAliasGroupOptionsReducer;
