// Force JS to use the proper time zone
export const changeTimeZone = (date: Date): Date => {
  const utc = new Date(
    date.toLocaleString('en-US', {
      timeZone: 'UTC',
    })
  );

  const diff = date.getTime() - utc.getTime();
  return new Date(date.getTime() + diff);
};

export const parseTimeFromDateString = (dateString: string): string => {
  const date = changeTimeZone(new Date(Date.parse(dateString)));
  const hour = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hour}:${minutes}`;
};

export const parseTime = (timeString: string): { hour: number; minute: number } => {
  const parts = timeString.split(':');
  return {
    hour: parseInt(parts[0]),
    minute: parseInt(parts[1]),
  };
};

export const mergeDateAndTime = (date: Date, time: string): Date => {
  const clockTime = parseTime(time);
  date.setHours(clockTime.hour);
  date.setMinutes(clockTime.minute);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const formatDate = (date: Date | string): string =>
  changeTimeZone(new Date(date)).toLocaleString('no-NO');
