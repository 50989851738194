import React, { useState, useEffect, useRef } from 'react';
import { isEqual } from 'lodash';

import Error from './Error';
import Table from './Table';
import LoadingButton from './LoadingButton';

import t from '../lib/translate';
import { PREFIX } from './TableWithHeader';

const TableForm = ({
  columnNames,
  formData,
  tableName,
  onChange,
  formError,
  onUnmountForm,
  editableColumns,
}) => {
  useEffect(
    () => () => {
      // ComponentWillUnmount
      onUnmountForm();
    },
    [onUnmountForm]
  );

  const updateForm = (newValue, editedIndex, editableColumn) => {
    const updatedForm = formData.map((data, index) =>
      index === editedIndex ? { ...data, [editableColumn]: newValue } : data
    );
    onChange(updatedForm);
  };

  return (
    <>
      {formError && <Error error={formError} withMargin />}
      <table className='table-form'>
        <thead>
          <tr>
            {columnNames.map((column, index) => (
              <td key={index.id}>{t(`${PREFIX}.${tableName}.${column}`)}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {formData.map((row, rowIndex) => (
            <tr key={row.id}>
              {columnNames.map((column, columnIndex) => (
                <td key={`${row.id}R${columnIndex.id}`}>
                  <div className='label'>{t(`${PREFIX}.${tableName}.${column}`)}</div>
                  {editableColumns.includes(column) ? (
                    <textarea
                      value={row[column]}
                      onChange={(e) => updateForm(e.target.value, rowIndex, column)}
                    />
                  ) : (
                    <div className='data'>{row[column]}</div>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

const GetPreviousForm = (rowData) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = rowData;
  });
  return ref.current;
};

const StationAliasTableForm = ({
  name,
  formError,
  submitForm,
  isLoading,
  columnNames,
  rowData,
  resetError,
  editableColumns,
  withModal,
  toggleModalOpen,
}) => {
  const [tableOpen, setTableOpen] = useState(true);
  const [formActive, setFormActive] = useState(false);
  const [form, updateForm] = useState(rowData);
  // eslint-disable-next-line
  const prevRowData = GetPreviousForm(rowData);

  const toggleTableOpen = () => setTableOpen(!tableOpen);
  const toggleFormActive = () => setFormActive(!formActive);

  useEffect(() => {
    // componentDidUpdate
    if (!isEqual(prevRowData, rowData)) {
      updateForm(rowData);
    }
  }, [updateForm, prevRowData, rowData]);

  const onSubmit = () => {
    submitForm(form).then(() => toggleFormActive());
  };

  const onCancel = () => {
    toggleFormActive();
    updateForm(rowData);
  };

  const enableSubmit = form.filter((data) => !data.alias).length === 0 && !isEqual(rowData, form);

  return (
    <div className={`station-table ${name}`}>
      <div className='header'>
        <div>
          <span className='button' onClick={toggleTableOpen}>
            <i className={`fas fa-angle-${tableOpen ? 'down' : 'right'}`} />
          </span>
          <h3>{t(`${PREFIX}.${name}`)}</h3>
        </div>
        <div className='action-buttons'>
          {formActive ? (
            <>
              <span className='cancel-edit-button' name={name} onClick={onCancel}>
                {t('button.cancel')}
              </span>
              <LoadingButton
                label={t('station.submit.alias')}
                onClick={onSubmit}
                isLoading={isLoading}
                disabled={!enableSubmit}
              />
            </>
          ) : (
            <button className='edit-button' onClick={toggleFormActive}>
              {t('station.edit.alias')}
            </button>
          )}
          {withModal && (
            <button className='modal-button' name={name} onClick={toggleModalOpen.bind(null, name)}>
              {t(`${PREFIX}.${name}.new`)}
            </button>
          )}
        </div>
      </div>
      {tableOpen && formActive && (
        <TableForm
          columnNames={columnNames}
          formData={form}
          formError={formError}
          onChange={updateForm}
          tableName={name}
          onUnmountForm={resetError}
          editableColumns={editableColumns}
        />
      )}
      {tableOpen && !formActive && (
        <Table tableName={name} columnNames={columnNames} rowData={rowData} />
      )}
    </div>
  );
};

export default StationAliasTableForm;
