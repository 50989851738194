import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import LoaderPage from '../../../LoaderPage';
import NotFound from '../../../NotFound';
import { ROUTE_SEGMENTS_FAILURE } from '../../../../redux/actions/routeSegments';
import { type RouteSegment } from 'src/types';
import TableWithHeader from '../../../../components/TableWithHeader';
import useScrollToTop from '../../../../hooks/useScrollToTop';
import { routeSegments as routeSegmentsApi } from '../../../../api';
import t from '../../../../lib/translate';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useStation } from '../../../../redux/reducers/stations';
import DayMatrixLabel from '../../../../components/DayMatrixLabel';

const RouteSegments = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { stationId } = useStation(useAppSelector((state) => state.stations));
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [routeSegments, setRouteSegments] = useState<RouteSegment[] | null>(null);

  const routeSegmentsRows = (routeSegments ?? []).map((route) => ({
    ...route,
    id: route.nodeId,
    distribution: <DayMatrixLabel dayMatrix={route.dayMatrix} nodeTypeCode={'S'} />,
    segmentType: route.selectedSplitType ? (
      t(`routeSegments.${route.selectedSplitType}`) +
      (route.lastModifiedByUserName === null ? ' ' + t('routeSegments.automaticallyCreated') : '')
    ) : (
      <span className='tag'>{t('routeSegments.undefined')}</span>
    ),
    segments:
      (route.selectedSplitType === 'USER_DEFINED'
        ? route.userDefinedSegments
        : route.equallyDividedSegments) ?? '-',
  }));

  const buttons = [
    {
      label: 'routeSegments.edit',
      onClick: () => {
        navigate('selectRoutes', { state: { stationId, routeSegments } });
      },
    },
  ];

  useEffect(() => {
    const getRouteSegments = async (): Promise<void> => {
      try {
        const routes = await routeSegmentsApi.get(stationId);
        setRouteSegments(routes);
      } catch (error) {
        dispatch({ type: ROUTE_SEGMENTS_FAILURE, data: error });
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    stationId && getRouteSegments();
  }, [stationId]);

  useScrollToTop();

  if (!stationId) return <NotFound message='station.not.found' />;
  if (isLoading) return <LoaderPage />;
  if (error) return <div />;

  return (
    <div className='route-segments'>
      <TableWithHeader
        name='routeSegments'
        columnNames={['label', 'distribution', 'segmentType', 'segments']}
        rowData={routeSegmentsRows}
        buttons={buttons}
        open
      />
    </div>
  );
};

export default RouteSegments;
