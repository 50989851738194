import { stationIntegrations } from '../../api';
import { setPrimaryLoader } from './loader';
import { resetErrors } from './resetErrors';

export const GET_STATION_INTEGRATIONS_SUCCESS = 'GET_STATION_INTEGRATIONS_SUCCESS';
export const GET_STATION_INTEGRATIONS_FAILURE = 'GET_STATION_INTEGRATIONS_FAILURE';

export const ADD_STATION_INTEGRATION_SUCCESS = 'ADD_STATION_INTEGRATION_SUCCESS';
export const ADD_STATION_INTEGRATION_FAILURE = 'ADD_STATION_INTEGRATION_FAILURE';

export const DELETE_STATION_INTEGRATION_SUCCESS = 'DELETE_STATION_INTEGRATION_SUCCESS';
export const DELETE_STATION_INTEGRATION_FAILURE = 'DELETE_STATION_INTEGRATION_FAILURE';

export const getStationIntegrations = (stationId) => (dispatch) =>
  stationIntegrations
    .get(stationId)
    .then((stationIntegrations) =>
      dispatch({
        type: GET_STATION_INTEGRATIONS_SUCCESS,
        data: stationIntegrations,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_STATION_INTEGRATIONS_FAILURE,
        data: err,
      })
    );

export const addStationIntegration =
  (stationId, integrationId, integrationInput, onSuccess) => (dispatch) => {
    dispatch(setPrimaryLoader(true));
    dispatch(resetErrors());

    return stationIntegrations
      .add(stationId, integrationId, integrationInput)
      .then((createdStationIntegration) => {
        onSuccess();
        dispatch({
          type: ADD_STATION_INTEGRATION_SUCCESS,
          data: createdStationIntegration,
        });
        dispatch(getStationIntegrations(stationId));
      })
      .catch((err) =>
        dispatch({
          type: ADD_STATION_INTEGRATION_FAILURE,
          data: err,
        })
      )
      .finally(() => {
        dispatch(setPrimaryLoader(false));
      });
  };

export const deleteStationIntegration = (stationId, stationIntegrationId) => (dispatch) =>
  stationIntegrations
    .remove(stationId, stationIntegrationId)
    .then(() =>
      dispatch({
        type: DELETE_STATION_INTEGRATION_SUCCESS,
        data: stationIntegrationId,
      })
    )
    .then(() => dispatch(getStationIntegrations(stationId)))
    .catch((err) =>
      dispatch({
        type: DELETE_STATION_INTEGRATION_FAILURE,
        data: err,
      })
    );
