import React, { type FC } from 'react';
import TransportUpdateForm from './TransportUpdateForm';
import TransportList from './TransportUpdateList';
import TransportAdmin from './TransportAdmin';

import t from '../../lib/translate';

const TransportPage: FC = () => {
  return (
    <div className={'transport-page'}>
      <h2>{t('transport.heading')}</h2>
      <TransportAdmin />
      <TransportUpdateForm />
      <TransportList />
    </div>
  );
};

export default TransportPage;
