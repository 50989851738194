import { getRequest, postRequest, deleteRequest } from './util';

const get = (stationId) => getRequest(`/stations/${stationId}/integrations`);

const add = (stationId, integrationId, apiIntegrationInput) =>
  postRequest(`/stations/${stationId}/integrations/${integrationId}`, apiIntegrationInput);

const remove = (stationId, stationIntegrationId) =>
  deleteRequest(`/stations/${stationId}/integrations/${stationIntegrationId}`);

export default {
  get,
  add,
  remove,
};
