import React, { useState } from 'react';
import t from '../lib/translate';
import { NavLink, useLocation } from 'react-router-dom';
import { sideNavSections } from '../views/sections';

const findActiveMobileIcon = (locationState) => {
  if (locationState && locationState.activeMobileIcon) {
    return locationState.activeMobileIcon;
  } else {
    return 'fas fa-sitemap';
  }
};

const SidebarNavigation = ({ station }) => {
  const [expanded, toggleExpansion] = useState(false);
  const location = useLocation();
  const activeIcon = findActiveMobileIcon(location.state);

  return (
    <>
      <div className={`side-nav ${expanded ? 'expanded' : ''}`}>
        <div className='sections-wrapper'>
          {sideNavSections(station).map((section) => (
            <NavLink
              to={section.path}
              state={{ activeMobileIcon: section.iconClassName }}
              className={({ isActive }) =>
                `section ${section.sectionClassName} ${isActive && 'active-section'}`
              }
              key={section.name}>
              <i className={section.iconClassName} />
              {expanded && <span>{t(`${section.name}`)}</span>}
            </NavLink>
          ))}
        </div>
        <div
          className={`desktop open-sidenav-button ${
            expanded ? 'fas fa-angle-left' : 'fas fa-angle-right'
          }`}
          onClick={() => toggleExpansion(!expanded)}
        />
      </div>
      <div
        className={`mobile open-sidenav-button ${
          expanded ? 'fas fa-angle-left expanded' : activeIcon
        }`}
        onClick={() => toggleExpansion(!expanded)}
      />
    </>
  );
};

export default SidebarNavigation;
