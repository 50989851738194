import React, { useState } from 'react';
import { connect } from 'react-redux';

import TableWithHeader from '../../../components/TableWithHeader';
import Modal from '../../../components/Modal';
import ReturnConfigForm from '../ReturnConfigForm';
import LoaderPage from '../../LoaderPage';

import t from '../../../lib/translate';
import { mapListToObject, formatPropertyFilterString } from '../../../lib/format';
import { deleteStationReturnConfig } from '../../../redux/actions/stationReturnConfigs';
import sortBy from 'lodash/sortBy';
import FilterWarning from '../../../components/FilterWarning';
import { useStation } from '../../../redux/reducers/stations';
import Form from '../../../components/Form';
import ReturnAliasGroupForm from '../ReturnAliasGroupForm';
import { deleteReturnAliasGroup } from '../../../redux/actions/stationReturnAliasGroups';

const handleDeleteStationReturnConfig = (config, deleteStationReturnConfig, station) => {
  if (window.confirm(t('station.delete.return.config.confirm', config.name))) {
    deleteStationReturnConfig(station.stationId, config.id);
  }
};

const Return = ({
  mappedStationReturnConfigs,
  stations,
  isLoading,
  deleteStationReturnConfig,
  deleteReturnAliasGroup,
  stationReturnConfigs,
  mappedReturnAliasGroups,
  stationReturnAliasGroups,
}) => {
  const [tableOpen, setTableOpen] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [returnConfig, setReturnConfig] = useState(null);

  const [aliasGroupModalOpen, setAliasGroupModalOpen] = useState(false);
  const [aliasGroupTableOpen, setAliasGroupTableOpen] = useState(true);
  const [aliasGroupToDelete, setAliasGroupToDelete] = useState(undefined);
  const [selectedAliasGroup, setSelectedAliasGroup] = useState(null);

  const station = useStation(stations);
  const toggleTableOpen = () => setTableOpen(!tableOpen);

  const toggleModalOpen = (rowIndex) => {
    if (typeof rowIndex === 'number') {
      const returnConfigToEdit = mappedStationReturnConfigs[rowIndex];
      returnConfigToEdit.propertyFilters = stationReturnConfigs[rowIndex].apiPropertyFilters.map(
        (filter) => ({
          key: filter.propertyId,
          value: filter.externalId,
        })
      );
      setReturnConfig(returnConfigToEdit);
    } else {
      setReturnConfig(null);
    }
    setModalOpen(!modalOpen);
  };

  const toggleAliasGroupModalOpen = (rowIndex) => {
    setSelectedAliasGroup(
      typeof rowIndex === 'number'
        ? stationReturnAliasGroups.find(
            (group) => group.aliasGroupId === mappedReturnAliasGroups[rowIndex].id
          )
        : null
    );
    setAliasGroupModalOpen(!aliasGroupModalOpen);
  };

  const handleDeleteAliasGroup = () => {
    deleteReturnAliasGroup(station.stationId, aliasGroupToDelete.id);
    setAliasGroupToDelete(undefined);
  };

  const handleEditClick = (e) => {
    toggleModalOpen(e);
  };

  return (
    <div className='return-config'>
      {isLoading && <LoaderPage />}

      <Modal open={modalOpen}>
        <ReturnConfigForm
          name='stationReturnConfigs'
          stationId={station.stationId}
          finished={toggleModalOpen}
          returnConfig={returnConfig}
        />
      </Modal>

      <Modal open={aliasGroupModalOpen}>
        <ReturnAliasGroupForm
          name='stationReturnAliasGroup'
          stationId={station.stationId}
          finished={toggleAliasGroupModalOpen}
          selectedAliasGroup={selectedAliasGroup}
        />
      </Modal>

      <Modal open={aliasGroupToDelete}>
        <Form
          isLoading={false}
          error={false}
          label='station.return.alias.group.delete'
          enableSubmit={true}
          buttonLabel='button.confirm'
          onClose={() => setAliasGroupToDelete(undefined)}
          onSubmit={handleDeleteAliasGroup}>
          <p>{t('station.return.alias.group.delete.info')}</p>
        </Form>
      </Modal>

      <TableWithHeader
        name='stationReturnAliasGroup'
        toggleModalOpen={toggleAliasGroupModalOpen}
        open={aliasGroupTableOpen}
        toggleOpenTable={() => setAliasGroupTableOpen(!aliasGroupTableOpen)}
        columnNames={['mainAlias', 'group']}
        rowData={mappedReturnAliasGroups}
        deleteRow={setAliasGroupToDelete}
        editable={(e) => toggleAliasGroupModalOpen(e)}
      />

      <TableWithHeader
        name='stationReturnConfigs'
        toggleModalOpen={toggleModalOpen}
        toggleOpenTable={toggleTableOpen}
        open={tableOpen}
        columnNames={['priority', 'name', 'alias', 'propertyFilter']}
        rowData={mappedStationReturnConfigs}
        deleteRow={(e) => handleDeleteStationReturnConfig(e, deleteStationReturnConfig, station)}
        editable={(e) => handleEditClick(e)}
      />
    </div>
  );
};

const mapDispatchToProps = {
  deleteStationReturnConfig,
  deleteReturnAliasGroup,
};

const mapStateToProps = (state) => {
  // Return configs
  const { properties } = state.staticData;
  const propertyValues = state.station.returnPropertyValues || {};

  const stationReturnConfigs = sortBy(state.station.returnConfigurations, 'priority');

  const propertyIdLabelMap = mapListToObject(properties, 'propertyId');

  const mappedStationReturnConfigs = stationReturnConfigs.map((config) => ({
    id: config.stationConfigId,
    priority: config.priority,
    name: config.name,
    alias: config.alias,
    propertyFilter: config.apiPropertyFilters.map((pf, index) => (
      <div key={index.id}>
        {formatPropertyFilterString(pf, propertyIdLabelMap, propertyValues)}{' '}
        <FilterWarning propertyFilter={pf} />
      </div>
    )),
  }));

  // Return alias groups
  const stationReturnAliasGroups = state.station.returnAliasGroups ?? [];
  const mappedReturnAliasGroups = stationReturnAliasGroups.map((group) => ({
    id: group.aliasGroupId,
    mainAlias: group.mainAlias,
    group: group.returnConfigs
      .sort(function (a, b) {
        if (a.alias < b.alias) {
          return -1;
        }
        if (a.alias > b.alias) {
          return 1;
        }
        return 0;
      })
      .map((config, i) => (
        <div key={config.stationReturnConfigId}>
          {`(${config.alias}) ${config.name}`}
          {i !== group.returnConfigs.length - 1 && ','}
        </div>
      )),
  }));

  return {
    isLoading: !state.station.returnConfigurations || !state.station.returnPropertyValues,
    error: state.error.station.page,
    stations: state.stations,
    mappedStationReturnConfigs,
    stationReturnConfigs,
    mappedReturnAliasGroups,
    stationReturnAliasGroups,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Return);
