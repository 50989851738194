import { deleteRequest, getRequest, postRequest, putRequest } from './util';

const get = async (stationId): Promise<void> =>
  await getRequest(`/stations/${stationId}/returnConfigs`);

const create = async (stationId, stationReturnConfig): Promise<void> =>
  await postRequest(`/stations/${stationId}/returnConfigs`, stationReturnConfig);

const update = async (stationId, stationReturnConfig, stationReturnConfigId): Promise<void> =>
  await putRequest(
    `/stations/${stationId}/returnConfigs/${stationReturnConfigId}`,
    stationReturnConfig
  );

const remove = async (stationId, stationReturnConfigId): Promise<void> =>
  await deleteRequest(`/stations/${stationId}/returnConfigs/${stationReturnConfigId}`);

export default {
  get,
  create,
  remove,
  update,
};
