import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Error from '../../components/Error';
import StationForm from './StationForm';
import Modal from '../../components/Modal';
import StationsTable from '../../components/StationsTable';

import t from '../../lib/translate';
import { useNavigate } from 'react-router';

const Stations = (props) => {
  const [newStationModalOpen, setNewStationModalOpen] = useState(false);
  const [stationFilter, setStationFilter] = useState('');
  const navigate = useNavigate();

  const toggleOpenNewStationModal = () => {
    setNewStationModalOpen((prevState) => !prevState);
  };

  const handleChangeStationFilter = (e) => setStationFilter(e.target.value.toLowerCase());

  const goToStation = (stationId) => {
    navigate(`/stations/${stationId}/sortingDegrees`);
  };

  const isOwnedBy = (station, activeOwnerParty) => {
    // We want to show stations without any ownerparty to ensure people don't lose access,
    // since not all stations will have ownerparties at release
    if (!station.ownerPartyKey) {
      return true;
    }

    return activeOwnerParty && station.ownerPartyKey === activeOwnerParty.key;
  };

  const { mappedStations, activeOwnerParty } = props;
  const [filteredStations, setFilteredStations] = useState(mappedStations);

  useEffect(() => {
    setFilteredStations(
      mappedStations
        .filter((station) => isOwnedBy(station, activeOwnerParty))
        .filter(
          (station) =>
            !stationFilter ||
            station.stationId.toString().includes(stationFilter) ||
            station.name.toLowerCase().includes(stationFilter) ||
            station.sortingDegreeName.toLowerCase().includes(stationFilter) ||
            station.aliasAllocationMethodName.toLowerCase().includes(stationFilter)
        )
    );
  }, [mappedStations, activeOwnerParty, stationFilter]);

  const { sortingDegreesIdNameMap, aliasAllocationMethodIdLabelMap, error } = props;

  return (
    <div className='stations-page'>
      <Modal open={newStationModalOpen} onClose={toggleOpenNewStationModal}>
        <StationForm finished={toggleOpenNewStationModal} />
      </Modal>

      {error && <Error error={error} />}
      {activeOwnerParty && <h2>{activeOwnerParty.name}</h2>}
      <div className='new-station'>
        <input
          type='text'
          placeholder={t('stations.input.filter')}
          onChange={handleChangeStationFilter}
        />
        <button onClick={toggleOpenNewStationModal}>{t('stations.label.create.station')}</button>
      </div>

      <StationsTable
        goToStation={goToStation}
        stations={filteredStations}
        sortingDegreesIdNameMap={sortingDegreesIdNameMap}
        aliasAllocationMethodIdLabelMap={aliasAllocationMethodIdLabelMap}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const mappedStations = state.stations.map((station) => ({
    stationId: station.stationId,
    nodes: station.nodes,
    name: station.name,
    ownerPartyKey: station.ownerParty ? station.ownerParty.key : '',
    ownerPartyName: station.ownerParty ? station.ownerParty.name : '',
    sortingDegreeName: t(`sorting.degree.type.${station.sortingDegreeId}`),
    aliasAllocationMethodName: t(`alias.type.${station.aliasAllocationMethodId}`),
  }));

  return {
    activeOwnerParty: state.userInfo.user.activeOwnerParty
      ? state.userInfo.user.activeOwnerParty
      : null,
    mappedStations,
  };
};

export default connect(mapStateToProps)(Stations);
