import { getRequest, postRequest, putRequest, deleteRequest } from './util';

const get = () => getRequest('/stations');

const create = (station) => postRequest('/stations', station);

const edit = (stationId, station) => putRequest(`/stations/${stationId}`, station);

const remove = (stationId) => deleteRequest(`/stations/${stationId}`);

const clone = (stationId) => postRequest(`/stations/${stationId}/clone`);

export default {
  get,
  create,
  edit,
  remove,
  clone,
};
