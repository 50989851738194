import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { type RouteSegment } from 'src/types';
import SelectRoutes from './SelectRoutes';
import Step from './Step';
import { routeSegments as routeSegmentsApi } from '../../../../api';
import t from '../../../../lib/translate';

interface State {
  stationId: number;
  routeSegments?: RouteSegment[];
}

const SelectRoutesStep = (): React.ReactElement => {
  const navigate = useNavigate();
  const state = useLocation().state as State;
  const [routeSegments, setRouteSegments] = useState<RouteSegment[]>(state?.routeSegments ?? []);

  const anyEquallyDividedSegments = routeSegments.some(
    (route) => route.selectedSplitType === 'EQUALLY_DIVIDED'
  );

  const updatedRouteSegments = (): RouteSegment[] =>
    (state?.routeSegments ?? []).map((route, i) => {
      const updatedRoute = routeSegments[i];
      const newRoute =
        route.selectedSplitType !== updatedRoute.selectedSplitType
          ? { ...route, selectedSplitType: updatedRoute.selectedSplitType, segments: null }
          : { ...route };

      if (newRoute.selectedSplitType === 'EQUALLY_DIVIDED') {
        newRoute.equallyDividedSegments ??= 1;
      }

      return newRoute;
    });

  const handleSubmit = async (): Promise<void> => {
    const routeSegments = updatedRouteSegments();

    if (anyEquallyDividedSegments) {
      navigate('../selectSegments', {
        state: { ...state, routeSegments },
        relative: 'path',
      });
    } else {
      await routeSegmentsApi.update(state.stationId, routeSegments);
      navigate('..', { relative: 'path' });
    }
  };

  return (
    <Step
      buttonLabel={anyEquallyDividedSegments ? 'button.next' : undefined}
      onSubmit={handleSubmit}>
      <h4>{t('routeSegments.selectRoutes')}</h4>
      <div className='info'>{t(`routeSegments.selectRoutes.info`)}</div>
      <SelectRoutes
        label='routeSegments.EQUALLY_DIVIDED'
        selectedSplitType='EQUALLY_DIVIDED'
        routeSegments={routeSegments}
        setRouteSegments={setRouteSegments}
      />
      <h4>{t('routeSegments.USER_DEFINED')}</h4>
      <SelectRoutes
        label='routeSegments.USER_DEFINED'
        selectedSplitType='USER_DEFINED'
        routeSegments={routeSegments}
        setRouteSegments={setRouteSegments}
      />
    </Step>
  );
};

export default SelectRoutesStep;
