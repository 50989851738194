import { AUTHORIZED, UNAUTHORIZED } from '../actions/authentication';

const authenticationReducer = (state = false, action) => {
  switch (action.type) {
    case AUTHORIZED:
      return true;

    case UNAUTHORIZED:
      return false;

    default:
      return state;
  }
};

export default authenticationReducer;
