import Select from 'react-select';

import LabelInfo from '../../../../components/LabelInfo';
import { type RouteSegment } from 'src/types';
import t from '../../../../lib/translate';

interface Props {
  label: string;
  selectedSplitType: 'EQUALLY_DIVIDED' | 'USER_DEFINED';
  routeSegments: RouteSegment[];
  setRouteSegments: React.Dispatch<React.SetStateAction<RouteSegment[]>>;
}

let selectAllKey = 1;

const SelectRoutes = ({
  label,
  selectedSplitType,
  routeSegments,
  setRouteSegments,
}: Props): React.ReactElement => {
  const selectedRouteSegments = routeSegments.filter(
    (route) => route.selectedSplitType === selectedSplitType
  );

  const selectable = (route: RouteSegment): boolean =>
    !route.selectedSplitType &&
    (selectedSplitType === 'EQUALLY_DIVIDED' ||
      (!!route.userDefinedSegments && route.userDefinedSegments > 0));

  const handleSelectAll = (): void => {
    setRouteSegments((routes) =>
      routes.map((route) => ({
        ...route,
        selectedSplitType: selectable(route) ? selectedSplitType : route.selectedSplitType,
      }))
    );

    // The Select will not update its selected routes, unless we force it to rerender
    selectAllKey++;
  };

  const handleSelectOne = (selected: RouteSegment): void => {
    setRouteSegments((routes) =>
      routes.map((route) =>
        route.nodeId === selected.nodeId ? { ...route, selectedSplitType } : route
      )
    );
  };

  const handleRemoveSome = (removed: RouteSegment[]): void => {
    setRouteSegments((routes) =>
      routes.map((route) =>
        removed.find(({ nodeId }) => nodeId === route.nodeId)
          ? { ...route, selectedSplitType: null }
          : route
      )
    );
  };

  const warningForMultiStationSegments = (route: RouteSegment): string => {
    if (route.otherStationsUsingNodeSegment.length === 0) {
      return '';
    }
    return t('routeSegments.segmented.at.another.station');
  };

  return (
    <div className='input-group'>
      <button type='button' className='select-all' onClick={handleSelectAll}>
        {t('routeSegments.selectAll')}
      </button>
      <LabelInfo label={label} info={`${label}.info`} />

      <Select
        key={selectAllKey}
        isMulti
        defaultValue={selectedRouteSegments}
        options={routeSegments}
        getOptionLabel={(route) => route.label + warningForMultiStationSegments(route)}
        getOptionValue={({ nodeId }) => nodeId}
        filterOption={({ data }) => selectable(data)}
        onChange={(_, { option, removedValue, removedValues }) => {
          if (option) {
            handleSelectOne(option);
          } else if (removedValue) {
            handleRemoveSome([removedValue]);
          } else if (removedValues) {
            handleRemoveSome(removedValues as RouteSegment[]);
          }
        }}
      />
    </div>
  );
};

export default SelectRoutes;
