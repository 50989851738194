import {
  GET_ALIAS_TYPES_SUCCESS,
  GET_ALIAS_TYPES_FAILURE,
} from '../actions/aliasAllocationMethods';

const aliasAllocationMethodsReducer = (state = null, action) => {
  switch (action.type) {
    case GET_ALIAS_TYPES_SUCCESS:
      return action.data;

    case GET_ALIAS_TYPES_FAILURE:
      return [];

    default:
      return state;
  }
};

export default aliasAllocationMethodsReducer;
