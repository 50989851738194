import { getRequest, postRequest } from './util';

const get = (stationId) => getRequest(`/stations/${stationId}/aliasLimits`);

const update = (stationId, aliasLetterLimits) =>
  postRequest(`/stations/${stationId}/aliasLimits`, aliasLetterLimits);

export default {
  get,
  update,
};
