import Select from 'react-select';

interface Props {
  segments: number;
  onChange: (segments: number) => void;
}

const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => ({
  value,
  label: value,
}));

const SelectSegments = ({ segments, onChange }: Props): React.ReactElement => (
  <Select
    value={{ value: segments, label: segments }}
    options={options}
    onChange={(selected) => {
      onChange(selected?.value ?? 1);
    }}
  />
);

export default SelectSegments;
