import { transport } from '../../api';
import { type OrderTransportRequest } from '../../types';

export const GET_MODEL_VERSIONS_SUCCESS = 'GET_MODEL_VERSIONS_SUCCESS';
export const GET_MODEL_VERSIONS_FAILURE = 'GET_MODEL_VERSIONS_FAILURE';

export const GET_MODEL_VERSIONS_FILTERS_SUCCESS = 'GET_MODEL_VERSIONS_FILTERS_SUCCESS';
export const GET_MODEL_VERSIONS_FILTERS_FAILURE = 'GET_MODEL_VERSIONS_FILTERS_FAILURE';

export const GET_NUMBER_OF_EXPORTS_LEFT_SUCCESS = 'GET_NUMBER_OF_EXPORTS_LEFT_SUCCESS';
export const GET_NUMBER_OF_EXPORTS_LEFT_FAILURE = 'GET_NUMBER_OF_EXPORTS_LEFT_FAILURE';

export const ORDER_TRANSPORT_UPDATE_REQUEST = 'ORDER_TRANSPORT_UPDATE_REQUEST';
export const ORDER_TRANSPORT_UPDATE_SUCCESS = 'ORDER_TRANSPORT_UPDATE_SUCCESS';
export const ORDER_TRANSPORT_UPDATE_FAILURE = 'ORDER_TRANSPORT_UPDATE_FAILURE';

export const ORDER_COMPLETE_MODEL_REQUEST = 'ORDER_COMPLETE_MODEL_REQUEST';
export const ORDER_COMPLETE_MODEL_SUCCESS = 'ORDER_COMPLETE_MODEL_SUCCESS';
export const ORDER_COMPLETE_MODEL_FAILURE = 'ORDER_COMPLETE_MODEL_FAILURE';

export const getModelVersions = () => async (dispatch) =>
  await transport
    .get()
    .then((modelVersions) =>
      dispatch({
        type: GET_MODEL_VERSIONS_SUCCESS,
        data: modelVersions,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_MODEL_VERSIONS_FAILURE,
        data: err,
      })
    );

export const getNumberOfUpdatesLeft = () => async (dispatch) =>
  await transport
    .getRemainingUpdates()
    .then((remainingUpdates) =>
      dispatch({
        type: GET_NUMBER_OF_EXPORTS_LEFT_SUCCESS,
        data: remainingUpdates,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_NUMBER_OF_EXPORTS_LEFT_FAILURE,
        data: err,
      })
    );

export const getFilterOptions = () => async (dispatch) =>
  await transport
    .getFilterOptions()
    .then((modelVersions) =>
      dispatch({
        type: GET_MODEL_VERSIONS_FILTERS_SUCCESS,
        data: modelVersions,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_MODEL_VERSIONS_FILTERS_FAILURE,
        data: err,
      })
    );

export const orderTransportUpdate = (request: OrderTransportRequest) => async (dispatch) => {
  dispatch({
    type: ORDER_TRANSPORT_UPDATE_REQUEST,
  });
  await transport
    .requestUpdate(request)
    .then(() => {
      dispatch(getModelVersions());
      dispatch(getNumberOfUpdatesLeft());
    })
    .then(() =>
      dispatch({
        type: ORDER_TRANSPORT_UPDATE_SUCCESS,
      })
    )
    .catch((err) =>
      dispatch({
        type: ORDER_TRANSPORT_UPDATE_FAILURE,
        data: err,
      })
    );
};

export const orderCompleteModel = () => async (dispatch) => {
  dispatch({
    type: ORDER_COMPLETE_MODEL_REQUEST,
  });
  await transport
    .requestCompleteModel()
    .then(() => {
      dispatch(getModelVersions());
    })
    .then(() =>
      dispatch({
        type: ORDER_COMPLETE_MODEL_SUCCESS,
      })
    )
    .catch((err) =>
      dispatch({
        type: ORDER_COMPLETE_MODEL_FAILURE,
        data: err,
      })
    );
};
