import React from 'react';
import Select from 'react-select';

import LabelInfo from './LabelInfo';
import t from '../lib/translate';

const InputSelect = ({
  value,
  selectedKey,
  options,
  isLoading,
  onChange,
  onInputChange,
  label,
  info,
  isMulti,
  placeholder = label,
  isClearable,
}) => {
  // Allow supplying only the key of the selected value, not the whole object
  if (selectedKey) {
    value = options.find((opt) => opt.value === selectedKey);
  }

  return (
    <div className='input-group'>
      <LabelInfo label={label} info={info} />
      <Select
        classNamePrefix='react-select'
        value={value}
        onChange={onChange}
        onInputChange={onInputChange}
        options={options}
        isLoading={isLoading}
        placeholder={t(placeholder)}
        onSelectResetsInput={false}
        isMulti={isMulti}
        isClearable={isClearable}
      />
    </div>
  );
};

export default InputSelect;
