import React from 'react';

const SystemMessage = ({ systemInfo, className }) => (
  <div className={`${className}-system-message`}>
    <i className='fas fa-exclamation-circle' />
    {systemInfo.message}
  </div>
);

export default SystemMessage;
