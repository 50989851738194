import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import LabelInfo from './LabelInfo';

const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

// TODO Format chosen vale DD/MM/YYYY
const InputDatePicker = ({ name, label = name, value, onChange, beforeLimit = tomorrow }) => (
  <div className='input-datepicker'>
    <LabelInfo label={label} />
    <DayPickerInput
      placeholder='YYYY-MM-DD'
      format='DD/MM/YYYY'
      dayPickerProps={{ disabledDays: { before: beforeLimit } }} // Tomorrow
      value={value}
      onDayChange={(date) => onChange(name, date)}
    />
  </div>
);

export default InputDatePicker;
