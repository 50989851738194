import {
  type Integration,
  type OwnerParty,
  type Property,
  type PropertyValue,
  type Station,
  type StationIntegration,
  type SystemMessage,
  type User,
} from '../types';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';
import {
  aliasAllocationMethodsReducer,
  authenticationReducer,
  errorReducer,
  integrationsReducer,
  languageReducer,
  loaderReducer,
  nodesReducer,
  propertiesReducer,
  sortingDegreesReducer,
  stationAliasBranchesReducer,
  stationAliasGroupsReducer,
  stationAliasLetterLimitsReducer,
  stationAllPropertyValuesReducer,
  stationApiUsersReducer,
  stationIntegrationsReducer,
  stationNetworkLayoutReducer,
  stationPropertyValuesReducer,
  stationReservedAliasesReducer,
  stationReturnConfigsReducer,
  stationReturnPropertyValuesReducer,
  stationReturnAliasGroupsReducer,
  stationReturnAliasGroupOptionsReducer,
  stationSortingConfigsReducer,
  stationsReducer,
  stationUsersReducer,
  systemInfoReducer,
  userInfoReducer,
} from './reducers';
import TransportReducer, { type TransportState } from './reducers/TransportReducer';

export interface SystemInfoReducer {
  data: SystemMessage[];
  loading: boolean;
}

export type StationsReducer = Station[];

export interface StationReducer {
  integrations?: StationIntegration[];
  propertyValues: Map<string, PropertyValue[]>;
  returnPropertyValues: Map<string, PropertyValue[]>;
  allPropertyValues: Map<string, PropertyValue[]>;
}

export interface StaticDataReducer {
  properties: Property[];
  integrations: Integration[];
}

export interface ErrorReducer {
  authentication: boolean;
  staticData: boolean;
  station: {
    page: boolean;
    modal: boolean;
  };
  stations: {
    page: boolean;
    newStation: boolean;
  };
  stationMap: boolean;
}

export interface LoadingReducer {
  primary: boolean;
  secondary: boolean;
  station: {
    aliasBranches: boolean;
  };
}

export interface UserReducer {
  user: User;
  ownerParties: OwnerParty[];
  loading: boolean;
  timestamp: boolean;
}

// Please expand this object as you use it in new components
export interface RootState {
  isAuthenticated: boolean;
  isLoading: LoadingReducer;
  language: any; // TODO remove - looks like not used
  error: ErrorReducer;
  systemInfo: SystemInfoReducer;
  userInfo: UserReducer;
  staticData: StaticDataReducer;
  stations: StationsReducer;
  station: StationReducer;
  transport: TransportState;
  nodes: any; // TODO define type
  stationRouteNodes: any; // TODO define type
}

const reducer = combineReducers({
  isAuthenticated: authenticationReducer,
  isLoading: loaderReducer,
  language: languageReducer,
  error: errorReducer,
  systemInfo: systemInfoReducer,
  userInfo: userInfoReducer,
  staticData: combineReducers({
    aliasAllocationMethods: aliasAllocationMethodsReducer,
    integrations: integrationsReducer,
    properties: propertiesReducer,
    sortingDegrees: sortingDegreesReducer,
  }),
  stations: stationsReducer,
  station: combineReducers({
    reservedAliases: stationReservedAliasesReducer,
    aliasBranches: stationAliasBranchesReducer,
    aliasGroups: stationAliasGroupsReducer,
    aliasLetterLimits: stationAliasLetterLimitsReducer,
    integrations: stationIntegrationsReducer,
    networkLayout: stationNetworkLayoutReducer,
    propertyValues: stationPropertyValuesReducer,
    returnPropertyValues: stationReturnPropertyValuesReducer,
    allPropertyValues: stationAllPropertyValuesReducer,
    returnConfigurations: stationReturnConfigsReducer,
    returnAliasGroups: stationReturnAliasGroupsReducer,
    returnAliasGroupOptions: stationReturnAliasGroupOptionsReducer,
    sortingConfigurations: stationSortingConfigsReducer,
    users: stationUsersReducer,
    apiUsers: stationApiUsersReducer,
  }),
  nodes: nodesReducer,
  transport: TransportReducer,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;

export type AppDispatch = typeof store.dispatch;
