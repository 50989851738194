import React from 'react';

import t from '../lib/translate';

const StationHeader = ({ station, buttons }) => (
  <div className='station-header'>
    <div className='station-info'>
      <div>
        <div>{t('station.label.name')}</div>
        {station.name}
      </div>

      <div>
        <div>{t('station.label.sortingDegree')}</div>
        {t(`sorting.degree.type.${station.sortingDegreeId}`)}
      </div>

      <div>
        <div>{t('station.label.aliasAllocationMethod')}</div>
        {t(`alias.type.${station.aliasAllocationMethodId}`)}
      </div>
      {station.ownerParty && (
        <div>
          <div>{t('station.label.ownerparty')}</div>
          {station.ownerParty.name}
        </div>
      )}
    </div>

    <div className='button-container'>
      {buttons &&
        buttons
          .filter((button) => !button.hidden)
          .map((button) => (
            <button key={button.label} className={button.className} onClick={button.onClick}>
              {t(button.label)}
            </button>
          ))}
    </div>
  </div>
);

export default StationHeader;
