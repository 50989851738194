import React from 'react';

import Error from './Error';
import LoadingButton from './LoadingButton';
import t from '../lib/translate';

const handleSubmit = (onSubmit, e) => {
  e.preventDefault();
  onSubmit();
};

const Form = ({
  isLoading,
  error,
  enableSubmit,
  label,
  onClose,
  onSubmit,
  hideButtons,
  buttonLabel,
  hideCancel,
  fixedFooter,
  onNavigateBack,
  className = '',
  children,
}) => (
  <form
    className={`form ${isLoading ? 'disabled' : ''} ${className}`}
    onSubmit={handleSubmit.bind(null, onSubmit)}>
    {label && <div className='header label'>{t(label)}</div>}

    {error && <Error error={error} />}
    {children}

    {!hideButtons && (
      <div className={`button-group ${fixedFooter ? 'fixed-footer' : ''}`}>
        <button type='button' className={`cancel${hideCancel ? ' hidden' : ''}`} onClick={onClose}>
          {t('button.cancel')}
        </button>
        {onNavigateBack &&
          <button type='button' onClick={onNavigateBack}>
            {t('button.back')}
          </button>
        }
        <LoadingButton
          label={t(buttonLabel || 'button.submit')}
          isLoading={isLoading}
          disabled={!enableSubmit}
          onClick={handleSubmit.bind(null, onSubmit)}
        />
      </div>
    )}
  </form>
);

export default Form;
