import { RESET_STATION_DATA } from '../actions/station';

import {
  GET_RETURN_PROPERTY_VALUES_SUCCESS,
  GET_RETURN_PROPERTY_VALUES_FAILURE,
} from '../actions/stationPropertyValues';

const ReturnPropertyValuesReducer = (state = null, action) => {
  switch (action.type) {
    case GET_RETURN_PROPERTY_VALUES_SUCCESS:
      return action.data;

    case GET_RETURN_PROPERTY_VALUES_FAILURE:
      return {};

    case RESET_STATION_DATA:
      return null;

    default:
      return state;
  }
};

export default ReturnPropertyValuesReducer;
