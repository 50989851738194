import React from 'react';

import t from '../lib/translate';

const errorHandler = ({ errorKey, statusCode, errorMap }) => {
  const i18nErrorKey = `err.${errorKey}`;
  const statusCodeString = statusCode ? ` (${statusCode})` : '';

  if (!errorKey.startsWith('validation.')) return `${t(i18nErrorKey)}${statusCodeString}`;

  const translatedParams = errorMap.errorMsgParams.map((param) => t(param));
  return `${t(i18nErrorKey, ...translatedParams)}${statusCodeString}`;
};

const Error = ({ error, withMargin }) => (
  <div className={`error${withMargin ? ' margin' : ''}`}>
    {error.errorKey && <span>{errorHandler(error)}</span>}
  </div>
);

export default Error;
