import { filters } from '../../api';

export const GET_PROPERTIES_SUCCESS = 'GET_PROPERTIES_SUCCESS';
export const GET_PROPERTIES_FAILURE = 'GET_PROPERTIES_FAILURE';

export const getProperties = () => (dispatch) =>
  filters
    .getProperties()
    .then((properties) =>
      dispatch({
        type: GET_PROPERTIES_SUCCESS,
        data: properties,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_PROPERTIES_FAILURE,
        data: err,
      })
    );
