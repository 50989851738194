import {
  GET_STATION_ALIAS_BRANCHES_REQUEST,
  GET_STATION_ALIAS_BRANCHES_SUCCESS,
  GET_STATION_ALIAS_BRANCHES_FAILURE,
  UPDATE_STATION_ALIAS_BRANCHES_REQUEST,
  UPDATE_STATION_ALIAS_BRANCHES_SUCCESS,
  UPDATE_STATION_ALIAS_BRANCHES_FAILURE,
} from '../actions/stationAliasBranches';

import { RESET_ERRORS } from '../actions/resetErrors';
import { RESET_STATION_DATA } from '../actions/station';
import { parseJSON } from '../../lib/format';

const INITIAL_STATE = {
  loaded: false,
  updating: false,
  data: [],
  error: null,
};

const stationAliasBranchesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_STATION_ALIAS_BRANCHES_REQUEST:
    case UPDATE_STATION_ALIAS_BRANCHES_REQUEST:
      return {
        ...state,
        updating: true,
        error: null,
      };

    case GET_STATION_ALIAS_BRANCHES_SUCCESS:
    case UPDATE_STATION_ALIAS_BRANCHES_SUCCESS:
      return {
        ...state,
        loaded: true,
        updating: false,
        data: action.data,
      };

    case GET_STATION_ALIAS_BRANCHES_FAILURE:
    case UPDATE_STATION_ALIAS_BRANCHES_FAILURE:
      return {
        ...state,
        loaded: true,
        updating: false,
        error: parseJSON(action.data),
      };

    case RESET_ERRORS:
      return {
        ...state,
        error: null,
      };

    case RESET_STATION_DATA:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default stationAliasBranchesReducer;
