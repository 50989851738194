import { station } from '../../api';
import { setPrimaryLoader } from './loader';

export const RESET_STATION_DATA = 'RESET_STATION_DATA';

export const REGENERATE_ALIASES_SUCCESS = 'REGENERATE_ALIASES_SUCCESS';
export const REGENERATE_ALIASES_FAILURE = 'REGENERATE_ALIASES_FAILURE';

export const resetStationData = () => (dispatch) =>
  dispatch({
    type: RESET_STATION_DATA,
  });

export const updateStationNetworkAliases = (stationId, onSuccess) => (dispatch) => {
  dispatch(setPrimaryLoader(true));

  station
    .regenerateStationNetworkAliases(stationId)
    .then(() => {
      onSuccess(); // Close modal
    })
    .catch((err) =>
      dispatch({
        type: REGENERATE_ALIASES_FAILURE,
        data: err,
      })
    )
    .finally(() => {
      dispatch(setPrimaryLoader(false));
    });
};
