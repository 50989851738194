import { stationAliasGroups } from '../../api';
import { getAliasBranches } from './stationAliasBranches';

export const CREATE_ALIAS_GROUP_REQUEST = 'CREATE_ALIAS_GROUP_REQUEST';
export const CREATE_ALIAS_GROUP_SUCCESS = 'CREATE_ALIAS_GROUP_SUCCESS';
export const CREATE_ALIAS_GROUP_FAILURE = 'CREATE_ALIAS_GROUP_FAILURE';

export const DELETE_ALIAS_GROUP_REQUEST = 'DELETE_ALIAS_GROUP_REQUEST';
export const DELETE_ALIAS_GROUP_SUCCESS = 'DELETE_ALIAS_GROUP_SUCCESS';
export const DELETE_ALIAS_GROUP_FAILURE = 'DELETE_ALIAS_GROUP_FAILURE';

export const UPDATE_ALIAS_GROUP_REQUEST = 'UPDATE_ALIAS_GROUP_REQUEST';
export const UPDATE_ALIAS_GROUP_SUCCESS = 'UPDATE_ALIAS_GROUP_SUCCESS';
export const UPDATE_ALIAS_GROUP_FAILURE = 'UPDATE_ALIAS_GROUP_FAILURE';

export const createAliasGroup = (stationId, aliasGroup, onSuccess) => (dispatch) => {
  dispatch({ type: CREATE_ALIAS_GROUP_REQUEST });

  return stationAliasGroups
    .create(stationId, aliasGroup)
    .then(() => dispatch({ type: CREATE_ALIAS_GROUP_SUCCESS }))
    .then(() => onSuccess())
    .then(() => dispatch(getAliasBranches(stationId)))
    .catch((err) =>
      dispatch({
        type: CREATE_ALIAS_GROUP_FAILURE,
        data: err,
      })
    );
};

export const updateAliasGroup = (stationId, aliasGroupId, aliasGroup, onSuccess) => (dispatch) => {
  dispatch({ type: UPDATE_ALIAS_GROUP_REQUEST });

  return stationAliasGroups
    .update(stationId, aliasGroupId, aliasGroup)
    .then(() => dispatch({ type: UPDATE_ALIAS_GROUP_SUCCESS }))
    .then(() => onSuccess())
    .then(() => dispatch(getAliasBranches(stationId)))
    .catch((err) =>
      dispatch({
        type: UPDATE_ALIAS_GROUP_FAILURE,
        data: err,
      })
    );
};

export const deleteAliasGroup = (stationId, aliasGroupId) => (dispatch) => {
  dispatch({ type: DELETE_ALIAS_GROUP_REQUEST });

  return stationAliasGroups
    .remove(stationId, aliasGroupId)
    .then(() => dispatch({ type: DELETE_ALIAS_GROUP_SUCCESS }))
    .then(() => dispatch(getAliasBranches(stationId)))
    .catch((err) =>
      dispatch({
        type: DELETE_ALIAS_GROUP_FAILURE,
        data: err,
      })
    );
};
