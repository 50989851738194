import { sortingDegrees } from '../../api';

export const GET_SORTING_DEGREES_SUCCESS = 'GET_SORTING_DEGREES_SUCCESS';
export const GET_SORTING_DEGREES_FAILURE = 'GET_SORTING_DEGREES_FAILURE';

export const getSortingDegrees = () => (dispatch) =>
  sortingDegrees
    .get()
    .then((sortingDegrees) =>
      dispatch({
        type: GET_SORTING_DEGREES_SUCCESS,
        data: sortingDegrees,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_SORTING_DEGREES_FAILURE,
        data: err,
      })
    );
