import React, { type FC, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getModelVersions } from '../../redux/actions/transport';
import t from '../../lib/translate';
import moment from 'moment';

const TransportList: FC = () => {
  const modelVersions = useAppSelector((state) => state.transport.modelVersions);
  const dispatch = useAppDispatch();

  // Reload the list every minute
  useEffect(() => {
    // @ts-expect-error Not typed due to old JS
    dispatch(getModelVersions());

    const interval = setInterval(() => {
      // @ts-expect-error Not typed due to old JS
      dispatch(getModelVersions());
    }, 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <h3 className={'transport-list'}>{t('transport.list.heading')}</h3>
      <table>
        <thead>
          <tr>
            <th>{t('transport.table.header.orderedAt')}</th>
            <th>{t('transport.table.header.nodes')}</th>
            <th>{t('transport.table.header.orderedBy')}</th>
            <th>{t('transport.table.header.status')}</th>
          </tr>
        </thead>
        <tbody>
          {modelVersions.map((mv) => (
            <tr key={`${mv.majorVersion}.${mv.minorVersion}`}>
              <td>{moment(mv.orderedAt).format('DD.MM.yyyy HH:mm')}</td>
              <td className={'transport-affected'}>
                {mv.filters.length === 0 ? (
                  <span className={'badge'}>{t('transport.all.nodes')}</span>
                ) : null}
                {mv.filters.map((f) => (
                  <span className={'badge'} key={f.nodeTypeCode + f.externalId}>
                    ({f.nodeTypeCode}) {f.label}
                  </span>
                ))}
              </td>
              <td>{mv.orderedBy}</td>
              <td>
                <span className={`transport-status-label status-${mv.status}`}>
                  {t(`transport.status.${mv.status}`)}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default TransportList;
