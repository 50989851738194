import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';

import LoginPage from './views/LoginPage';
import AppRouter from './AppRouter';
import { checkAuthentication } from './redux/actions/authentication';

class App extends React.Component {
  componentDidMount() {
    this.props.checkAuthentication();
  }

  render() {
    const { isAuthenticated } = this.props;

    return <Router>{isAuthenticated ? <AppRouter /> : <LoginPage />}</Router>;
  }
}

const mapDispatchToProps = {
  checkAuthentication,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.isAuthenticated,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
