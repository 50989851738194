import React from 'react';

import t from '../lib/translate';
import { PREFIX } from './TableWithHeader';

const Table = ({ tableName, columnNames, rowData, deleteRow, editable }) =>
  rowData.length > 0 ? (
    <table className={deleteRow ? 'delete' : ''}>
      <thead>
        <tr>
          {columnNames.map((column) => (
            <td key={column}>{t(`${PREFIX}.${tableName}.${column}`)}</td>
          ))}
          {deleteRow && <td />}
        </tr>
      </thead>
      <tbody>
        {rowData.map((row, rowIndex) => (
          <tr key={row.id} className={row.warning ? 'tr-error' : ''}>
            {columnNames.map((column, index) => (
              <td
                key={`${row.id}R${column}`}
                className={row.warning && index === 0 ? 'td-error' : ''}>
                {row.warning && index === 0 && (
                  <>
                    <i className='fas fa-exclamation-triangle i-error' />
                    <span className='span-error'>{row.warning}</span>
                  </>
                )}
                <div className='label'>{t(`${PREFIX}.${tableName}.${column}`)}</div>
                <div className='data'>{row[column]}</div>
              </td>
            ))}
            {editable && (
              <td>
                <button onClick={() => editable(rowIndex)}>
                  <i className='fas fa-pen' />
                  <span>{t(`${PREFIX}.${tableName}.edit`)}</span>
                </button>
              </td>
            )}
            {deleteRow && (
              <td>
                <button onClick={() => deleteRow(row)}>
                  <i className='far fa-trash-alt' />
                  <span>{t(`${PREFIX}.${tableName}.delete`)}</span>
                </button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <div className='empty'>{t(`${PREFIX}.${tableName}.empty`)}</div>
  );

export default Table;
