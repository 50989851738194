import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { type RouteSegment } from 'src/types';
import SelectSegments from './SelectSegments';
import Step from './Step';
import { routeSegments as routeSegmentsApi } from '../../../../api';
import t from '../../../../lib/translate';
import DayMatrixLabel from '../../../../components/DayMatrixLabel';

interface State {
  stationId: number;
  routeSegments?: RouteSegment[];
}

const SelectSegmentsStep = (): React.ReactElement => {
  const navigate = useNavigate();
  const state = useLocation().state as State;
  const [segments, setSegments] = useState(1);
  const [routeSegments, setRouteSegments] = useState(state?.routeSegments ?? []);

  const handleChange = (segments: number, nodeId: string): void => {
    setRouteSegments((routeSegments) =>
      routeSegments.map((route) =>
        route.nodeId === nodeId ? { ...route, equallyDividedSegments: segments } : route
      )
    );
  };

  const handleChangeAll = (): void => {
    setRouteSegments((routeSegments) =>
      routeSegments.map((route) =>
        route.selectedSplitType === 'EQUALLY_DIVIDED'
          ? { ...route, equallyDividedSegments: segments }
          : route
      )
    );
  };

  const handleNavigateBack = (): void => {
    navigate('../selectRoutes', {
      state: { ...state, routeSegments },
      relative: 'path',
    });
  };

  const handleSubmit = async (): Promise<void> => {
    await routeSegmentsApi.update(state.stationId, routeSegments.filter(onlySelectedSegments));
    navigate('..', { relative: 'path' });
  };

  const onlySelectedSegments = (segment: RouteSegment): boolean =>
    segment.selectedSplitType != null;

  return (
    <Step onSubmit={handleSubmit} onNavigateBack={handleNavigateBack}>
      <h4>{t('routeSegments.EQUALLY_DIVIDED')}</h4>
      <p>{t('routeSegments.selectSegments')}</p>
      <table className='select-segments'>
        <thead>
          <tr>
            <th>{t('routeSegments.numberOfSegments')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <SelectSegments segments={segments} onChange={setSegments} />
            </td>
            <td>
              <button type='button' onClick={handleChangeAll}>
                {t('routeSegments.applyToAll')}
              </button>
            </td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th>{t('table.routeSegments.label')}</th>
            <th>{t('table.routeSegments.segments')}</th>
            <th></th>
            <th>{t('table.routeSegments.other.stations')}</th>
          </tr>
        </thead>
        <tbody>
          {routeSegments
            .filter((route) => route.selectedSplitType === 'EQUALLY_DIVIDED')
            .map((route) => (
              <tr key={route.nodeId}>
                <td>
                  {route.label}
                  <DayMatrixLabel dayMatrix={route.dayMatrix} nodeTypeCode={'S'} />
                </td>
                <td>
                  <SelectSegments
                    segments={route.equallyDividedSegments ?? 1}
                    onChange={(segments) => {
                      handleChange(segments, route.nodeId);
                    }}
                  />
                </td>
                <td>
                  {route.lastModifiedByUserName === null
                    ? t('routeSegments.automaticallyCreated')
                    : ''}
                </td>
                <td>{route.otherStationsUsingNodeSegment.join(', ')}</td>
              </tr>
            ))}
        </tbody>
      </table>
      {routeSegments.some((route) => route.selectedSplitType === 'USER_DEFINED') && (
        <>
          <h4>{t('routeSegments.USER_DEFINED')}</h4>
          <table className='select-segments user-defined'>
            <thead>
              <tr>
                <th>{t('table.routeSegments.label')}</th>
                <th>{t('table.routeSegments.segments')}</th>
                <th></th>
                <th>{t('table.routeSegments.other.stations')}</th>
              </tr>
            </thead>
            <tbody>
              {routeSegments
                .filter((route) => route.selectedSplitType === 'USER_DEFINED')
                .map((route) => (
                  <tr key={route.nodeId}>
                    <td>
                      {route.label}
                      <DayMatrixLabel dayMatrix={route.dayMatrix} nodeTypeCode={'S'} />
                    </td>
                    <td>{route.userDefinedSegments}</td>
                    <td>
                      {route.lastModifiedByUserName === null
                        ? t('routeSegments.automaticallyCreated')
                        : ''}
                    </td>
                    <td>{route.otherStationsUsingNodeSegment.join(', ')}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      )}
    </Step>
  );
};

export default SelectSegmentsStep;
