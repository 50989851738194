import { setSecondaryLoader } from './loader';
import { resetErrors } from './resetErrors';
import { nodes } from '../../api';

export const GET_NODES_SUCCESS = 'GET_NODES_SUCCESS';
export const GET_NODES_FAILURE = 'GET_NODES_FAILURE';

export const searchNodes = (nodeSearchTerm, selectedOwnerParty) => async (dispatch) => {
  dispatch(setSecondaryLoader(true));
  dispatch(resetErrors());

  try {
    await nodes.search(nodeSearchTerm, selectedOwnerParty).then((nodeSearchResponse) =>
      dispatch({
        type: GET_NODES_SUCCESS,
        data: nodeSearchResponse,
      })
    );
  } catch (err) {
    dispatch({
      type: GET_NODES_FAILURE,
      data: err,
    });
  } finally {
    dispatch(setSecondaryLoader(false));
  }
};
