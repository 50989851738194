import { GET_NODES_SUCCESS, GET_NODES_FAILURE } from '../actions/nodes';

const nodesReducer = (state = [], action) => {
  switch (action.type) {
    case GET_NODES_SUCCESS:
      return action.data;

    case GET_NODES_FAILURE:
      return [];

    default:
      return state;
  }
};

export default nodesReducer;
