import { SET_PRIMARY_LOADER, SET_SECONDARY_LOADER } from '../actions/loader';

import {
  GET_STATION_ALIAS_BRANCHES_REQUEST,
  GET_STATION_ALIAS_BRANCHES_SUCCESS,
  GET_STATION_ALIAS_BRANCHES_FAILURE,
} from '../actions/stationAliasBranches';

const INITIAL_STATE = {
  primary: false,
  secondary: false,
  station: {
    aliasBranches: false,
  },
};

const loader = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PRIMARY_LOADER:
      return {
        ...state,
        primary: action.data,
      };

    case SET_SECONDARY_LOADER:
      return {
        ...state,
        secondary: action.data,
      };

    case GET_STATION_ALIAS_BRANCHES_REQUEST:
      return {
        ...state,
        station: {
          ...state.station,
          aliasBranches: true,
        },
      };

    case GET_STATION_ALIAS_BRANCHES_SUCCESS:
    case GET_STATION_ALIAS_BRANCHES_FAILURE:
      return {
        ...state,
        station: {
          ...state.station,
          aliasBranches: false,
        },
      };

    default:
      return state;
  }
};

export default loader;
