import React from 'react';
import t from '../lib/translate';

const resetPasswordUrl = () => {
  const envLink = () => {
    switch (process.env.REACT_APP_ENV) {
      case 'dev':
        return 'dev-';
      case 'stg':
        return 'stg-';
      default:
        return '';
    }
  };

  return `https://${envLink()}app.di.no/auth/static/password/reset`;
};

const PasswordExpiryWarning = () => (
  <div className='warning-system-message'>
    <i className='fas fa-exclamation-circle' />
    {t('system.information.password.expiry.warning')}
    <a className='link' href={resetPasswordUrl()}>
      {t('system.information.password.change.link')}
    </a>
  </div>
);

export default PasswordExpiryWarning;
