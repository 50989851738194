import { createRoot } from 'react-dom/client';
import store from './redux/store';
import { Provider } from 'react-redux';
import App from './App';

import * as Sentry from '@sentry/react';

function render() {
  if (process.env.REREACT_APP_ENV !== 'local' && process.env.REREACT_APP_ENV !== 'mock') {
    Sentry.init({
      dsn: 'https://da4c63a1f4784f13999c0293f904d830@o37388.ingest.sentry.io/4504554114514944',
      environment: process.env.REACT_APP_ENV,
    });
  }

  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );
}

render();
module.hot && module.hot.accept('App', render);
