import React from 'react';

import t from '../lib/translate';
import LabelInfo from './LabelInfo';

const InputText = ({
  name,
  value,
  label,
  info,
  handleChange,
  maxLength,
  autoFocus,
  autoComplete,
  onKeyPress,
  placeholder = label,
  type = 'text',
  min,
  pattern,
}) => (
  <div className='input-group'>
    <LabelInfo label={label} info={info} />
    <input
      type={type}
      name={name}
      value={value}
      placeholder={t(placeholder)}
      onChange={handleChange}
      maxLength={maxLength}
      onKeyPress={onKeyPress}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      min={min}
      pattern={pattern}
    />
  </div>
);

export default InputText;
