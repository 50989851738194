import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

import LabelInfo from './LabelInfo';
import KeyValue from './KeyValue';
import t from '../lib/translate';

class InputSelectKeyValue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keyValues: props.selectedKeyValues || [{ key: undefined, value: undefined }],
    };
  }

  setStateAndupdateParent = (keyValues) => {
    const { onChange, name } = this.props;
    this.setState({ keyValues });
    onChange(name, keyValues);
  };

  handleChangeKey = (index, key) => {
    const { keyValues } = this.state;
    const clone = cloneDeep(keyValues);

    if (keyValues[index].key !== key) {
      clone[index] = {
        key,
        value: undefined,
      };
    }

    this.setStateAndupdateParent(clone);
  };

  handleChangeValue = (index, value) => {
    const { keyValues } = this.state;

    const clone = cloneDeep(keyValues);

    if (keyValues[index].value !== value) {
      clone[index].value = value;
    }

    this.setStateAndupdateParent(clone);
  };

  handleAddKeyValue = () => {
    const { keyValues } = this.state;
    const clone = cloneDeep(keyValues);

    clone.push({ key: undefined, value: undefined });

    this.setStateAndupdateParent(clone);
  };

  handleRemoveKeyValue = (index) => {
    const { keyValues } = this.state;
    const clone = cloneDeep(keyValues);

    const newKeyValues = clone.filter((pf, pfIndex) => pfIndex !== index);

    this.setStateAndupdateParent(newKeyValues);
  };

  render() {
    const { keyValues } = this.state;
    const {
      name,
      info,
      keyOptions,
      keyValueOptions,
      uniformValues,
      required,
      placeholderKey,
      placeholderValue,
      label = name,
    } = this.props;

    return (
      <div className='input-group pf'>
        <LabelInfo label={label} info={info} />

        <div className='property-filters'>
          {keyValues.map((kv, index) => (
            <KeyValue
              key={index.id}
              index={index}
              keySelected={kv.key}
              keyOptions={keyOptions}
              onChangeKey={this.handleChangeKey}
              value={kv.value}
              valueOptions={kv.key && (uniformValues ? keyValueOptions : keyValueOptions[kv.key])}
              onChangeValue={this.handleChangeValue}
              handleRemove={this.handleRemoveKeyValue}
              enableRemove={required ? keyValues.length > 1 : true}
              placeholderKey={placeholderKey}
              placeholderValue={placeholderValue}
            />
          ))}
        </div>

        <span className='pf-add' onClick={this.handleAddKeyValue}>
          {t('config.label.filter.add')}
        </span>
      </div>
    );
  }
}

export default InputSelectKeyValue;
