import { RESET_STATION_DATA } from '../actions/station';
import { RESET_ERRORS } from '../actions/resetErrors';

import {
  GET_SORTING_CONFIGS_REQUEST,
  GET_SORTING_CONFIGS_SUCCESS,
  GET_SORTING_CONFIGS_FAILURE,
  CREATE_SORTING_CONFIG_REQUEST,
  CREATE_SORTING_CONFIG_SUCCESS,
  CREATE_SORTING_CONFIG_FAILURE,
  EDIT_SORTING_CONFIG_REQUEST,
  EDIT_SORTING_CONFIG_SUCCESS,
  EDIT_SORTING_CONFIG_FAILURE,
  DELETE_SORTING_CONFIG_REQUEST,
  DELETE_SORTING_CONFIG_SUCCESS,
  DELETE_SORTING_CONFIG_FAILURE,
} from '../actions/stationSortingConfigs';
import { parseJSON } from '../../lib/format';

const INITIAL_STATE = {
  loaded: false,
  updating: false,
  data: [],
  error: null,
};

const stationSortingConfigsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SORTING_CONFIGS_REQUEST:
    case CREATE_SORTING_CONFIG_REQUEST:
    case EDIT_SORTING_CONFIG_REQUEST:
    case DELETE_SORTING_CONFIG_REQUEST:
      return {
        ...state,
        updating: true,
        error: null,
      };

    case GET_SORTING_CONFIGS_SUCCESS:
      return {
        ...state,
        loaded: true,
        updating: false,
        data: action.data,
      };

    case CREATE_SORTING_CONFIG_SUCCESS:
      return {
        ...state,
        updating: false,
        data: [...state.data, action.data],
      };

    case EDIT_SORTING_CONFIG_SUCCESS:
      return {
        ...state,
        updating: false,
        data: state.data.map((config) =>
          config.sortingConfigId === action.data.sortingConfigId ? action.data : config
        ),
      };

    case DELETE_SORTING_CONFIG_SUCCESS:
      return {
        ...state,
        updating: false,
        data: state.data.filter((config) => config.sortingConfigId !== action.data),
      };

    case GET_SORTING_CONFIGS_FAILURE:
    case DELETE_SORTING_CONFIG_FAILURE:
    case CREATE_SORTING_CONFIG_FAILURE:
    case EDIT_SORTING_CONFIG_FAILURE:
      return {
        ...state,
        loaded: true,
        updating: false,
        error: parseJSON(action.data),
      };

    case RESET_ERRORS:
      return {
        ...state,
        error: null,
      };

    case RESET_STATION_DATA:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default stationSortingConfigsReducer;
