import React, { useEffect } from 'react';

const ModalBody = ({ id, className = '', children, ...props }) => {
  useEffect(() => {
    // componentDidMount
    document.getElementsByTagName('body')[0].classList.add('modal-open');
    // componentWillUnmount
    return () => document.getElementsByTagName('body')[0].classList.remove('modal-open');
  }, []);
  return (
    <>
      <div className='modal-overlay' />
      <div className='modal'>
        <div id={id} className={`modal-content ${className}`} {...props}>
          {children}
        </div>
      </div>
    </>
  );
};

const Modal = ({ open, ...props }) => open && <ModalBody {...props} />;

export default Modal;
