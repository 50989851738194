import { merge } from 'lodash';

import store from '../redux/store';
import { unauthorized } from '../redux/actions/authentication';

const API_URL = '/api/ui/admin';

export const ROLE_ADMIN = 'role.scanning.admin';
export const ROLE_REPORTS = 'role.scanning.reports';
export const ROLE_OPERATOR = 'role.scanning.operator';
export const ROLE_TRANSPORT_ADMIN = 'role.scanning.transport.admin';
export const ROLE_DI = 'di';

const handleError = async (res) => {
  if (res.status === 401) {
    store.dispatch(unauthorized);
    localStorage.setItem('token', '');
    throw new Error(JSON.stringify({ errorKey: 'unauthorized' }));
  }

  const contentType = res.headers.get('Content-Type');

  if (contentType && !contentType.toLowerCase().includes('application/json')) {
    throw new Error(
      JSON.stringify({
        statusCode: res.status,
        errorKey: 'service.unavailable',
      })
    );
  }

  const body = await res.json();

  if (body.statusCode) {
    // Indicates error
    throw new Error(JSON.stringify(body));
  }

  if (body.status) {
    // Spring fallback error
    throw new Error(JSON.stringify({ statusCode: body.status, errorKey: body.message }));
  }
};

const anyRequest = (url, additionalOptions) => {
  const options = {
    headers: {},
  };

  const jwtToken = localStorage.getItem('jwt-token');

  if (jwtToken) {
    options.headers.Authorization = `Bearer ${jwtToken}`;
  }

  const excludeFromApiUrlPrefix = [
    '/login',
    '/logout',
    '/api/ui/userinfo',
    '/api/ui/userinfo/ownerparties',
  ];

  // Login and logout at root
  if (!excludeFromApiUrlPrefix.includes(url)) {
    url = `${API_URL}${url}`;
  }

  // Set enviroment base URL
  url = `${process.env.REACT_APP_BASE_URL}${url}`;

  return fetch(url, merge(options, additionalOptions))
    .catch(() => {
      throw new Error(JSON.stringify({ errorKey: 'service.unavailable' }));
    })
    .then((res) => {
      if (!res.ok) {
        return handleError(res);
      }

      const jwtToken = res.headers.get('x-jwt-token');
      if (jwtToken) {
        localStorage.setItem('jwt-token', jwtToken);
      }

      const contentType = res.headers.get('Content-Type');

      if (contentType && contentType.toLowerCase().includes('application/json')) {
        return res.json();
      }
    });
};

const getRequest = (url, additionalOptions) =>
  anyRequest(url, {
    method: 'GET',
    credentials: 'include',
    ...additionalOptions,
  });

const postRequest = (url, body, additionalOptions) =>
  anyRequest(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'Application/JSON',
    },
    body: JSON.stringify(body),
    ...additionalOptions,
  });

const postFormRequest = (url, jsonBody, additionalOptions) =>
  anyRequest(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: Object.keys(jsonBody)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(jsonBody[k])}`)
      .join('&'),
    ...additionalOptions,
  });

const putRequest = (url, body, additionalOptions) =>
  anyRequest(url, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'Application/JSON',
    },
    body: JSON.stringify(body),
    ...additionalOptions,
  });

const deleteRequest = (url, additionalOptions) =>
  anyRequest(url, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'Application/JSON',
    },
    ...additionalOptions,
  });

const patchRequest = (url, additionalOptions) =>
  anyRequest(url, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      'Content-Type': 'Application/JSON',
    },
    ...additionalOptions,
  });

export { getRequest, postRequest, postFormRequest, putRequest, deleteRequest, patchRequest };
