import React from 'react';

import LabelInfo from './LabelInfo';
import t from '../lib/translate';

const InputRadioButton = ({ name, value, options, handleChange, label = name, info = '' }) => (
  <div className='input-group'>
    <LabelInfo label={label} info={info} />
    <div className='radio-group'>
      {options.map((option) => (
        <div
          key={option.value}
          onClick={() => !option.disabled && handleChange(name, +option.value)}
          className={`radio-button 
            ${option.value === value ? 'selected' : ''} 
            ${option.disabled ? 'disabled' : ''}`}>
          <input
            type='radio'
            name={name}
            value={option.value}
            id={`${name}${option.value}`}
            disabled={option.disabled}
          />
          <label className='radio' htmlFor={`${name}${option.value}`}>
            {t(option.label)}
          </label>
        </div>
      ))}
    </div>
  </div>
);

export default InputRadioButton;
