import { getRequest, putRequest } from './util';

const get = () => getRequest('/api/ui/userinfo');

const getOwnerParties = () => getRequest('/api/ui/userinfo/ownerparties');

const updateUserInfo = (UserInfoUpdateRequest) =>
  putRequest('/api/ui/userinfo', UserInfoUpdateRequest);

export default {
  get,
  getOwnerParties,
  updateUserInfo,
};
