import { postRequest, deleteRequest, putRequest } from './util';

const create = (stationId, aliasGroup) =>
  postRequest(`/stations/${stationId}/sharedAliasGroup`, aliasGroup);

const update = (stationId, sharedAliasGroupId, aliasGroup) =>
  putRequest(`/stations/${stationId}/sharedAliasGroup/${sharedAliasGroupId}`, aliasGroup);

const remove = (stationId, aliasGroupId) =>
  deleteRequest(`/stations/${stationId}/sharedAliasGroup/${aliasGroupId}`);

export default {
  create,
  update,
  remove,
};
