import React, { type FC } from 'react';
import { useSelector } from 'react-redux';

import t from '../lib/translate';
import { type RootState } from 'src/redux/store';
import { type IntegrationParameterValue } from 'src/types';
import DeprecationWarning from './DeprecationWarning';

interface Props {
  integrationParameterValues: IntegrationParameterValue[];
}

export const GEOADJUST_PARAM = 1;

const IntegrationParamList: FC<Props> = ({ integrationParameterValues }) => {
  const integrations = useSelector((state: RootState) => state.staticData.integrations);

  const integrationParamIdNameMap = new Map<number, string>();
  integrations.forEach((integration) => {
    integration.parameters.forEach((parameter) => {
      integrationParamIdNameMap.set(parameter.integrationParamTypeId, parameter.i18nkey);
    });
  });

  return (
    <>
      {integrationParameterValues.map((parameterValue) => (
        <div key={parameterValue.integrationParameterTypeId}>
          {t(
            `parameter.${integrationParamIdNameMap.get(parameterValue.integrationParameterTypeId)}`,
            parameterValue.value
          )}
          {parameterValue.integrationParameterTypeId === GEOADJUST_PARAM && (
            <DeprecationWarning message={'integration.list.geoadjust.deprecation.warning'} />
          )}
        </div>
      ))}
    </>
  );
};

export default IntegrationParamList;
