import { getRequest, putRequest, deleteRequest } from './util';

const get = () => getRequest('/systeminfo/list');

const createOrUpdate = (message) => putRequest('/systeminfo', message);

const deleteMessage = (systemInfoId) => deleteRequest(`/systeminfo/${systemInfoId}`);

export default {
  get,
  createOrUpdate,
  deleteMessage,
};
