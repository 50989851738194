import React from 'react';

import t from '../lib/translate';

const NotFound = ({ message }) => (
  <div className='page-not-found'>
    <div className='illustration'>
      <div>404</div>
      {message ? t(message) : t('page.not.found')}
    </div>
  </div>
);

export default NotFound;
