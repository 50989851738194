import { stationReturnAliasGroupOptions } from '../../api';

export const GET_RETURN_ALIAS_GROUP_OPTIONS_SUCCESS = 'GET_RETURN_ALIAS_GROUP_OPTIONS_SUCCESS';
export const GET_RETURN_ALIAS_GROUP_OPTIONS_FAILURE = 'GET_RETURN_ALIAS_GROUP_OPTIONS_FAILURE';

export const getReturnAliasGroupOptions = (stationId) => (dispatch) =>
  stationReturnAliasGroupOptions
    .get(stationId)
    .then((returnAliasGroupOptions) =>
      dispatch({
        type: GET_RETURN_ALIAS_GROUP_OPTIONS_SUCCESS,
        data: returnAliasGroupOptions,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_RETURN_ALIAS_GROUP_OPTIONS_FAILURE,
        data: err,
      })
    );
