import { RESET_STATION_DATA } from '../actions/station';

import {
  GET_STATION_RETURN_CONFIGS_SUCCESS,
  GET_STATION_RETURN_CONFIGS_FAILURE,
  UPDATE_STATION_RETURN_CONFIG_SUCCESS,
  CREATE_STATION_RETURN_CONFIG_SUCCESS,
  DELETE_STATION_RETURN_CONFIG_SUCCESS,
} from '../actions/stationReturnConfigs';

const stationConfigReducer = (state = null, action) => {
  switch (action.type) {
    case GET_STATION_RETURN_CONFIGS_SUCCESS:
    case CREATE_STATION_RETURN_CONFIG_SUCCESS:
    case UPDATE_STATION_RETURN_CONFIG_SUCCESS:
    case DELETE_STATION_RETURN_CONFIG_SUCCESS:
      return action.data;

    case GET_STATION_RETURN_CONFIGS_FAILURE:
      return [];

    case RESET_STATION_DATA:
      return null;

    default:
      return state;
  }
};

export default stationConfigReducer;
