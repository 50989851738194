import { stationReturnConfigs } from '../../api';
import { setPrimaryLoader } from './loader';
import { resetErrors } from './resetErrors';
import { getAliasBranches } from './stationAliasBranches';
import { getReturnAliasGroupOptions } from './stationReturnAliasGroupOptions';
import { getReturnAliasGroups } from './stationReturnAliasGroups';

export const GET_STATION_RETURN_CONFIGS_SUCCESS = 'GET_STATION_RETURN_CONFIGS_SUCCESS';
export const GET_STATION_RETURN_CONFIGS_FAILURE = 'GET_STATION_RETURN_CONFIGS_FAILURE';

export const CREATE_STATION_RETURN_CONFIG_SUCCESS = 'CREATE_STATION_RETURN_CONFIG_SUCCESS';
export const CREATE_STATION_RETURN_CONFIG_FAILURE = 'CREATE_STATION_RETURN_CONFIG_FAILURE';

export const UPDATE_STATION_RETURN_CONFIG_SUCCESS = 'UPDATE_STATION_RETURN_CONFIG_SUCCESS';
export const UPDATE_STATION_RETURN_CONFIG_FAILURE = 'UPDATE_STATION_RETURN_CONFIG_FAILURE';

export const DELETE_STATION_RETURN_CONFIG_SUCCESS = 'DELETE_STATION_RETURN_CONFIG_SUCCESS';
export const DELETE_STATION_RETURN_CONFIG_FAILURE = 'DELETE_STATION_RETURN_CONFIG_FAILURE';

export const getStationReturnConfigs = (stationId) => (dispatch) =>
  stationReturnConfigs
    .get(stationId)
    .then((returnConfigs) =>
      dispatch({
        type: GET_STATION_RETURN_CONFIGS_SUCCESS,
        data: returnConfigs,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_STATION_RETURN_CONFIGS_FAILURE,
        data: err,
      })
    );

export const createStationReturnConfig = (stationId, returnConfig, onSuccess) => (dispatch) => {
  dispatch(setPrimaryLoader(true));
  dispatch(resetErrors());

  return stationReturnConfigs
    .create(stationId, returnConfig)
    .then((createdConfig) =>
      dispatch({
        type: CREATE_STATION_RETURN_CONFIG_SUCCESS,
        data: createdConfig,
      })
    )
    .then(() => onSuccess())
    .then(() => {
      dispatch(getAliasBranches(stationId));
      dispatch(getReturnAliasGroupOptions(stationId));
    })
    .catch((err) =>
      dispatch({
        type: CREATE_STATION_RETURN_CONFIG_FAILURE,
        data: err,
      })
    )
    .finally(() => {
      dispatch(setPrimaryLoader(false));
    });
};

export const updateStationReturnConfig =
  (stationId, returnConfig, returnConfigId, onSuccess) => (dispatch) => {
    dispatch(setPrimaryLoader(true));
    dispatch(resetErrors());
    return stationReturnConfigs
      .update(stationId, returnConfig, returnConfigId)
      .then((updatedConfig) => {
        onSuccess();
        dispatch({
          type: UPDATE_STATION_RETURN_CONFIG_SUCCESS,
          data: updatedConfig,
        });
      })
      .then(() => {
        dispatch(getAliasBranches(stationId));
        dispatch(getReturnAliasGroups(stationId));
        dispatch(getReturnAliasGroupOptions(stationId));
      })
      .catch((err) =>
        dispatch({
          type: UPDATE_STATION_RETURN_CONFIG_FAILURE,
          data: err,
        })
      )
      .finally(() => {
        dispatch(setPrimaryLoader(false));
      });
  };

export const deleteStationReturnConfig = (stationId, stationReturnConfigId) => (dispatch) =>
  stationReturnConfigs
    .remove(stationId, stationReturnConfigId)
    .then((deletedId) =>
      dispatch({
        type: DELETE_STATION_RETURN_CONFIG_SUCCESS,
        data: deletedId,
      })
    )
    .then(() => {
      dispatch(getAliasBranches(stationId));
      dispatch(getReturnAliasGroups(stationId));
      dispatch(getReturnAliasGroupOptions(stationId));
    })
    .catch((err) =>
      dispatch({
        type: DELETE_STATION_RETURN_CONFIG_FAILURE,
        data: err,
      })
    );
