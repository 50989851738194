import React from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import Form from '../../components/Form';
import InputCheckbox from '../../components/InputCheckbox';
import InputSelectKeyValue from '../../components/InputSelectKeyValue';

import { editStation } from '../../redux/actions/stations';
import { toggleReuseAlias, resetAliases } from '../../redux/actions/reuseAlias';
import { getAliasBranches } from '../../redux/actions/stationAliasBranches';
import { updateStationAliasLetterLimits } from '../../redux/actions/stationAliasLetterLimits';
import { resetErrors } from '../../redux/actions/resetErrors';
import { dispatchLoader } from '../../redux/actions/loader';
import t from '../../lib/translate';

const REUSE_ALIAS = 'reuseAlias';
const RESET_ALIAS = 'resetAlias';
const LETTER_LIMITS = 'letterLimits';

const LETTER_LIMITS_MAX = 99;

class EditAliasForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      [REUSE_ALIAS]: props.station.reuseAlias,
      [RESET_ALIAS]: false,
      [LETTER_LIMITS]: props.mappedAliasLetterLimits,
      confirmedAction: false,
    };
  }

  updateFieldValue = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChange = (e) => {
    const { target } = e;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.updateFieldValue(name, value);
  };

  validAliasLetterLimits = () => {
    const { letterLimits } = this.state;

    const emptyLetterLimits = letterLimits && letterLimits.filter((ll) => !(ll.key && ll.value));

    if (emptyLetterLimits && emptyLetterLimits.length === 0) return true;

    return false;
  };

  enableSubmit = () => {
    const { letterLimits } = this.state;
    const stationLetterLimits = this.props.mappedAliasLetterLimits;

    if (!this.validAliasLetterLimits()) return false;

    if (
      this.props.station.reuseAlias !== this.state[REUSE_ALIAS] ||
      this.state[RESET_ALIAS] ||
      !isEqual(letterLimits, stationLetterLimits)
    ) {
      return true;
    }
  };

  closeModalAndResetFormErrors = () => {
    this.props.finished(this.props.name);
    this.props.resetErrors();
  };

  handleSubmit = () => {
    if (this.enableSubmit()) {
      const { letterLimits } = this.state;
      const { station, updateStationAliasLetterLimits, editStation, resetAliases } = this.props;
      const { dispatchLoader, resetErrors, finished, name, getAliasBranches } = this.props;
      const { stationId } = station;

      const stationRequest = this.props.station.reuseAlias !== this.state[REUSE_ALIAS] && {
        ...station,
        reuseAlias: this.state[REUSE_ALIAS],
        nodeIds: station.nodes.map((node) => +node.nodeId),
      };

      const apiLetterLimits = letterLimits
        .filter((ll) => ll.value < LETTER_LIMITS_MAX)
        .map((ll) => ({ aliasLetter: ll.key, aliasLimit: ll.value }));

      dispatchLoader(true);
      resetErrors();

      Promise.all([
        !isEqual(letterLimits, this.props.mappedAliasLetterLimits) &&
          updateStationAliasLetterLimits(stationId, apiLetterLimits),
        stationRequest && editStation(stationId, stationRequest),
        this.state[RESET_ALIAS] && resetAliases(stationId),
      ])
        .then(() => finished(name))
        .then(() => getAliasBranches(stationId))
        .catch()
        .finally(() => dispatchLoader(false));
    }
  };

  render() {
    const { isLoading, error } = this.props;
    const { chars, numbers } = this.props.aliasLetters;
    const { confirmedAction, letterLimits, resetAlias } = this.state;
    const enableSubmit = this.enableSubmit();
    const requireConfirmation = this.state[RESET_ALIAS];

    return (
      <Form
        isLoading={isLoading}
        error={error}
        label='station.alias.header'
        hideButtons={requireConfirmation && !confirmedAction}
        buttonLabel={requireConfirmation && 'station.alias.confirm'}
        enableSubmit={enableSubmit}
        onClose={this.closeModalAndResetFormErrors}
        onSubmit={this.handleSubmit}>
        {confirmedAction ? (
          <div>{t('station.alias.reset.confirm')}</div>
        ) : (
          <>
            <InputCheckbox
              label='station.alias.reuse'
              info='station.alias.reuse.info'
              options={[
                {
                  name: 'reuseAlias',
                  value: this.state[REUSE_ALIAS],
                  label: 'station.alias.reuse',
                },
              ]}
              handleChange={this.handleChange}
              toggle>
              {this.props.station.reuseAlias && (
                <button
                  type='button'
                  className={resetAlias ? 'selected' : ''}
                  onClick={this.updateFieldValue.bind(null, RESET_ALIAS, !resetAlias)}>
                  {t('button.reset')}
                </button>
              )}
            </InputCheckbox>

            <InputSelectKeyValue
              name={LETTER_LIMITS}
              label='station.alias.letter.limits'
              info={t('station.alias.letter.limits.info', LETTER_LIMITS_MAX)}
              placeholderKey='station.alias.letter.limits.key'
              placeholderValue='station.alias.letter.limits.value'
              selectedKeyValues={letterLimits}
              keyOptions={chars}
              keyValueOptions={numbers}
              onChange={this.updateFieldValue}
              uniformValues
            />
          </>
        )}

        {requireConfirmation && !confirmedAction && (
          <div className='button-group'>
            <button className='cancel' onClick={this.closeModalAndResetFormErrors}>
              {t('button.cancel')}
            </button>
            <button
              onClick={this.updateFieldValue.bind(null, 'confirmedAction', true)}
              type='submit'
              disabled={!enableSubmit}>
              {t('button.submit')}
            </button>
          </div>
        )}
      </Form>
    );
  }
}

const mapDispatchToProps = {
  dispatchLoader,
  editStation,
  getAliasBranches,
  toggleReuseAlias,
  resetAliases,
  resetErrors,
  updateStationAliasLetterLimits,
};

const mapStateToProps = (state) => {
  const { aliasLetterLimits } = state.station;

  const numbers = Array.from(Array(LETTER_LIMITS_MAX).keys()).map((num) => ({
    value: num + 1,
    label: num + 1,
  }));

  const chars = aliasLetterLimits.map((all) => ({
    value: all.aliasLetter,
    label: all.aliasLetter,
  }));

  const mappedAliasLetterLimits = aliasLetterLimits
    .filter((all) => all.aliasLimit)
    .map((all) => ({ key: all.aliasLetter, value: all.aliasLimit }));

  return {
    isLoading: state.isLoading.primary,
    error: state.error.station.modal,
    mappedAliasLetterLimits,
    aliasLetters: {
      chars,
      numbers,
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAliasForm);
