import React from 'react';
import t from '../../lib/translate';
import { connect } from 'react-redux';

import Form from '../../components/Form';

import { editStationUser, createStationUser } from '../../redux/actions/stationUsers';
import { resetErrors } from '../../redux/actions/resetErrors';
import InputText from '../../components/InputText';
import InputSelect from '../../components/InputSelect';
import InputCheckbox from '../../components/InputCheckbox';

import { ROLE_ADMIN, ROLE_REPORTS } from '../../api/util';

const USERID = 'userId';
const USERNAME = 'username';
const FIRSTNAME = 'firstName';
const LASTNAME = 'lastName';
const EMAIL = 'email';
const LANGUAGE = 'languageCode';
const ROLES = 'grantedRoles';

const languagePrefix = 'table.stationUsers.language';

class CreateUserForm extends React.Component {
  constructor(props) {
    super(props);

    const { isEdit, user } = this.props;

    const languagesOptions = this.getLanguageOptions();
    this.state = {
      [USERID]: isEdit ? user.id : 0,
      [USERNAME]: isEdit ? user.username : '',
      [FIRSTNAME]: isEdit ? user.firstName : '',
      [LASTNAME]: isEdit ? user.lastName : '',
      [EMAIL]: isEdit ? user.email : '',
      [LANGUAGE]: isEdit ? languagesOptions.find((lo) => lo.value === user.languageCode).value : '',
      [ROLES]: isEdit ? user.grantedRoles : [],
    };
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillUnmount() {
    this.props.resetErrors();
  }

  closeModal = () => {
    this.props.finished(this.props.name);
  };

  handleChange = (e) => {
    const { target } = e;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.updateFieldValue(name, value);
  };

  handleRoleChange = (e, role) => {
    const { target } = e;
    let value;
    if (target.checked) {
      value = this.state[ROLES].concat(role);
    } else {
      value = this.state[ROLES].filter((r) => r !== role);
    }
    this.updateFieldValue(ROLES, value);
  };

  getLanguageOptions = () => [
    { value: 'NO', label: t(`${languagePrefix}.nor`) },
    { value: 'SV', label: t(`${languagePrefix}.sve`) },
    { value: 'EN', label: t(`${languagePrefix}.eng`) },
  ];

  handleLanguageChange = (languageSelected) => {
    const { value } = languageSelected;
    this.updateFieldValue('languageCode', value);
  };

  updateFieldValue = (name, value) => {
    this.setState({ [name]: value });
  };

  closeModalAndResetForm = () => {
    this.props.resetErrors();
    this.props.finished(this.props.name);
  };

  enableSubmit = () => {
    const completlyFilledUserInfo =
      this.state[USERNAME] &&
      this.state[FIRSTNAME] &&
      this.state[LASTNAME] &&
      this.state[EMAIL] &&
      this.state[LANGUAGE];
    return completlyFilledUserInfo;
  };

  handleSubmit = () => {
    const { stationId, createStationUser, editStationUser, isEdit } = this.props;
    if (isEdit && this.enableSubmit()) {
      const req = this.toApiRequest();
      editStationUser(stationId, req, this.closeModalAndResetForm);
    } else if (this.enableSubmit()) {
      const req = this.toApiRequest();
      createStationUser(stationId, req, this.closeModalAndResetForm);
    }
  };

  toApiRequest = () => {
    const { isEdit } = this.props;
    if (isEdit) {
      return {
        [USERID]: this.state[USERID],
        [USERNAME]: this.state[USERNAME],
        [FIRSTNAME]: this.state[FIRSTNAME],
        [LASTNAME]: this.state[LASTNAME],
        [LANGUAGE]: this.state[LANGUAGE],
        [ROLES]: this.state[ROLES],
      };
    }
    return {
      [USERNAME]: this.state[USERNAME],
      [FIRSTNAME]: this.state[FIRSTNAME],
      [LASTNAME]: this.state[LASTNAME],
      [EMAIL]: this.state[EMAIL],
      [LANGUAGE]: this.state[LANGUAGE],
      [ROLES]: this.state[ROLES],
    };
  };

  render() {
    const { isLoading, error, isEdit } = this.props;
    const enableSubmit = this.enableSubmit();

    const labelPrefix = 'table.stationUsers';

    const languagesOptions = this.getLanguageOptions();

    return (
      <Form
        isLoading={isLoading}
        error={error}
        onClose={this.closeModal}
        onSubmit={this.handleSubmit}
        enableSubmit={enableSubmit}>
        {!isEdit && (
          <InputText
            name={USERNAME}
            value={this.state[USERNAME]}
            label={`${labelPrefix}.${USERNAME}`}
            info={`${labelPrefix}.${USERNAME}.info`}
            handleChange={this.handleChange}
          />
        )}
        <InputText
          name={FIRSTNAME}
          value={this.state[FIRSTNAME]}
          label={`${labelPrefix}.${FIRSTNAME}`}
          info={`${labelPrefix}.${FIRSTNAME}.info`}
          handleChange={this.handleChange}
        />
        <InputText
          name={LASTNAME}
          value={this.state[LASTNAME]}
          label={`${labelPrefix}.${LASTNAME}`}
          info={`${labelPrefix}.${LASTNAME}.info`}
          handleChange={this.handleChange}
        />

        {!isEdit && (
          <InputText
            name={EMAIL}
            value={this.state[EMAIL]}
            label={`${labelPrefix}.${EMAIL}`}
            info={`${labelPrefix}.${EMAIL}.info`}
            handleChange={this.handleChange}
          />
        )}
        <InputSelect
          name={LANGUAGE}
          value={languagesOptions.filter((lo) => lo.value === this.state[LANGUAGE])}
          label={`${labelPrefix}.${LANGUAGE}`}
          options={languagesOptions}
          onChange={this.handleLanguageChange}
        />

        <div className='d-flex justify-content-start'>
          <InputCheckbox
            name='admin'
            options={[
              {
                name: ROLE_ADMIN,
                value: this.state[ROLES].includes(ROLE_ADMIN),
                label: `${labelPrefix}.admin`,
              },
            ]}
            handleChange={(e) => this.handleRoleChange(e, ROLE_ADMIN)}
          />
          <InputCheckbox
            name='reports'
            options={[
              {
                name: ROLE_REPORTS,
                value: this.state[ROLES].includes(ROLE_REPORTS),
                label: `${labelPrefix}.reports`,
              },
            ]}
            handleChange={(e) => this.handleRoleChange(e, ROLE_REPORTS)}
          />
        </div>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.isLoading.primary,
  error: state.error.station.modal,
});

const mapDispatchToProps = {
  editStationUser,
  createStationUser,
  resetErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserForm);
