import React from 'react';

import t from '../lib/translate';

const isFilterOutDated = (propertyFilter) => {
  // Wrongly sorted parcel
  if (propertyFilter.propertyId === 'ST' && propertyFilter.externalId === '2') {
    return true;
  }

  // Customer system owner, Customer system, External transporter
  const outdatedFilters = ['EB', 'KS', 'ET'];
  return outdatedFilters.includes(propertyFilter.propertyId);
};

const FilterWarning = ({ propertyFilter }) =>
  isFilterOutDated(propertyFilter) && (
    <span className='filter-warning'>
      <i className='fas fa-exclamation-circle' /> {t('outdated.filter')}
    </span>
  );

export default FilterWarning;
