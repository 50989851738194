export const LANGUAGE_CHANGED = 'LANGUAGE_CHANGED';
export const LANGUAGE_LOADED = 'LANGUAGE_LOADED';

export const changeLanguage = (language) => {
  localStorage.setItem('language', language);
  return {
    type: LANGUAGE_CHANGED,
    data: language,
  };
};

export const loadLanguage = () => {
  const language = localStorage.getItem('language');
  return {
    type: LANGUAGE_LOADED,
    data: language || 'en',
  };
};
