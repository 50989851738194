import React from 'react';
import { connect } from 'react-redux';
import Form from '../../components/Form';
import InputSelect from '../../components/InputSelect';
import { createAliasGroup, updateAliasGroup } from '../../redux/actions/stationAliasGroups';
import { resetErrors } from '../../redux/actions/resetErrors';
import t from '../../lib/translate';
import isEmpty from 'lodash/isEmpty';
import LabelInfo from '../../components/LabelInfo';
import Select from 'react-select';
import { getDaysOfWeekOptions } from '../../redux/actions/daysOfWeekOptions';

class AliasGroupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAliasBranches: [],
      selectedCustomAliasBranches: [],
      mainAliasBranch: '',
      availableDaysOfWeek: getDaysOfWeekOptions(),
      customMainBranchAvailableDays: [],
    };
  }

  componentDidMount() {
    if (this.props.groupId) {
      this.setState({
        selectedAliasBranches: this.findSpecificBranches('selected'),
        selectedCustomAliasBranches: this.findSpecificCustomBranches(
          'selected',
          'mainBranch',
          'originalMainBranch',
          'availableDaysOfWeek'
        ),
        mainAliasBranch: this.findMainBranch(
          'mainBranch',
          'customMainBranch',
          'originalMainBranch'
        )[0],
        customMainBranchAvailableDays: this.findCustomMainBranchAvailableDaysOfWeek('selected'),
      });
    }
  }

  componentWillUnmount() {
    this.props.resetErrors();
  }

  handleAddAliasBranchToGroup = (selectedAliasBranches) => {
    if (
      !selectedAliasBranches.find(
        (selectedAliasBranch) => selectedAliasBranch.value === this.state.mainAliasBranch.value
      )
    ) {
      this.setState({ mainAliasBranch: '' });
    }
    this.setState({ selectedAliasBranches });
  };

  handleSetMainAliasBranch = (mainAliasBranch) => {
    let { selectedAliasBranches, selectedCustomAliasBranches } = this.state;
    const availableSelectedCustomAliasBranches = [];
    const chosenSelectedCustomAliasBranches = [];

    selectedAliasBranches.forEach((selectedAliasBranch) => {
      if (
        selectedAliasBranch.value !== mainAliasBranch.value &&
        selectedAliasBranch.value !== this.state.mainAliasBranch.value
      ) {
        if (!selectedCustomAliasBranches.some((v) => v.value === selectedAliasBranch.value)) {
          chosenSelectedCustomAliasBranches.push(selectedAliasBranch);
        }
      }
    });

    selectedAliasBranches
      .filter(
        (value) =>
          value !== mainAliasBranch && !chosenSelectedCustomAliasBranches.some((v) => value === v)
      )
      .map((branch) => availableSelectedCustomAliasBranches.push(branch));

    selectedCustomAliasBranches = availableSelectedCustomAliasBranches;

    this.setState({ mainAliasBranch, selectedCustomAliasBranches });
  };

  enableSubmit = () =>
    this.state.selectedAliasBranches.length > 1 && !isEmpty(this.state.mainAliasBranch);

  closeModal = () => this.props.finished(this.props.name);

  mapAliasBranches = () => {
    const { aliasBranches } = this.props;

    return aliasBranches.map((branch) => ({
      label: `(${branch.startNode.nodeTypeCode}) ${branch.startNode.label}
                     ➜
                   (${branch.endNode.nodeTypeCode}) ${branch.endNode.label}
                   ${
                     branch.endNode.nodeTypeCode === 'R' || branch.endNode.nodeTypeCode === 'S'
                       ? `[M${branch.endNode.dayMatrix.days}]`
                       : ''
                   }`,
      value: branch.originalIndex,
    }));
  };

  findSpecificBranches = (propertyName) => {
    const { aliasBranches } = this.props;
    return aliasBranches
      .filter((branch) => branch[propertyName])
      .map((branch) => ({
        label: (
          <div key={branch.endNode.nodeId}>
            {`(${branch.startNode.nodeTypeCode}) ${branch.startNode.label}`}
            <i className='fas fa-arrow-right' />
            {`(${branch.endNode.nodeTypeCode}) ${branch.endNode.label}`}
          </div>
        ),
        value: branch.originalIndex,
      }));
  };

  findMainBranch = (
    propertyNameMainBranch,
    propertyNameCustomMainBranch,
    propertyNameOriginalMainBranch
  ) => {
    const { aliasBranches } = this.props;
    return aliasBranches
      .filter(
        (branch) =>
          (branch[propertyNameOriginalMainBranch] && !branch[propertyNameMainBranch]) ||
          (branch[propertyNameMainBranch] && !branch[propertyNameCustomMainBranch])
      )
      .map((branch) => ({
        label: (
          <div key={branch.endNode.nodeId}>
            {`(${branch.startNode.nodeTypeCode}) ${branch.startNode.label}`}
            <i className='fas fa-arrow-right' />
            {`(${branch.endNode.nodeTypeCode}) ${branch.endNode.label}`}
          </div>
        ),
        value: branch.originalIndex,
      }));
  };

  findSpecificCustomBranches = (
    propertyNameSelected,
    propertyNameMainBranch,
    propertyNameOriginalMainBranch,
    propertyNameAvailableDaysOfWeek
  ) => {
    const { aliasBranches } = this.props;
    const selectedAliasBranches = aliasBranches.filter((branch) => branch[propertyNameSelected]);
    const selectedCustomAliasBranches = selectedAliasBranches.filter(
      (branch) =>
        !branch[propertyNameMainBranch] &&
        !branch[propertyNameOriginalMainBranch] &&
        !branch[propertyNameAvailableDaysOfWeek].length > 0
    );
    return selectedCustomAliasBranches.map((branch) => ({
      label: (
        <div key={branch.endNode.nodeId}>
          {`(${branch.startNode.nodeTypeCode}) ${branch.startNode.label}`}
          <i className='fas fa-arrow-right' />
          {`(${branch.endNode.nodeTypeCode}) ${branch.endNode.label}`}
        </div>
      ),
      value: branch.originalIndex,
    }));
  };

  addException = () => {
    this.setState((prevState) => ({
      customMainBranchAvailableDays: [
        ...prevState.customMainBranchAvailableDays,
        { customMainBranch: '', availableDays: [] },
      ],
    }));
  };

  removeException = (i) => {
    let { customMainBranchAvailableDays, availableDaysOfWeek, selectedCustomAliasBranches } =
      this.state;
    const customAliasBranch = customMainBranchAvailableDays[i].customMainBranch;
    const availableDays = customMainBranchAvailableDays[i].availableDays;

    if (customAliasBranch) {
      selectedCustomAliasBranches.push(customAliasBranch);
    }

    availableDaysOfWeek = availableDaysOfWeek
      .concat(availableDays)
      .sort((a, b) => a.order.localeCompare(b.order));
    customMainBranchAvailableDays.splice(i, 1);
    this.setState({
      customMainBranchAvailableDays,
      availableDaysOfWeek,
      selectedCustomAliasBranches,
    });
  };

  handleSetCustomMainAliasBranch = (e, i) => {
    let { customMainBranchAvailableDays, selectedCustomAliasBranches } = this.state;
    const previousSelectedCustomAliasBranch = customMainBranchAvailableDays[i].customMainBranch;
    customMainBranchAvailableDays[i] = {
      ...customMainBranchAvailableDays[i],
      customMainBranch: e,
    };
    selectedCustomAliasBranches = selectedCustomAliasBranches.filter((value) => e !== value);

    if (previousSelectedCustomAliasBranch) {
      selectedCustomAliasBranches.push(previousSelectedCustomAliasBranch);
    }

    this.setState({
      customMainBranchAvailableDays,
      selectedCustomAliasBranches,
    });
  };

  handleSetAvailableDaysForCustomMainAliasBranch = (e, i) => {
    let { customMainBranchAvailableDays, availableDaysOfWeek } = this.state;
    const previousAvailableDays = customMainBranchAvailableDays[i].availableDays;
    customMainBranchAvailableDays[i] = {
      ...customMainBranchAvailableDays[i],
      availableDays: e,
    };

    if (availableDaysOfWeek.some((v) => e.includes(v))) {
      availableDaysOfWeek = availableDaysOfWeek.filter((day) => !e.includes(day));
    } else {
      availableDaysOfWeek = availableDaysOfWeek
        .concat(previousAvailableDays.filter((value) => !e.includes(value)))
        .sort((a, b) => a.order.localeCompare(b.order));
    }

    this.setState({ customMainBranchAvailableDays, availableDaysOfWeek });
  };

  findCustomMainBranchAvailableDaysOfWeek = (propertyName) => {
    const { aliasBranches } = this.props;
    let { availableDaysOfWeek } = this.state;
    const customMainBranchAvailableDays = [];

    aliasBranches
      .filter((branch) => branch[propertyName] && branch.availableDaysOfWeek.length !== 0)
      .map((branch) =>
        customMainBranchAvailableDays.push({
          customMainBranch: {
            label: (
              <div key={branch.endNode.nodeId}>
                {`(${branch.startNode.nodeTypeCode}) ${branch.startNode.label}`}
                <i className='fas fa-arrow-right' />
                {`(${branch.endNode.nodeTypeCode}) ${branch.endNode.label}`}
              </div>
            ),
            value: branch.originalIndex,
          },
          availableDays: branch.availableDaysOfWeek.map((day) => ({
            value: day,
            label: getDaysOfWeekOptions().find((dow) => dow.value === day).label,
            order: getDaysOfWeekOptions().find((dow) => dow.value === day).order,
          })),
        })
      );

    const filteredAvailableDays = [];
    customMainBranchAvailableDays.forEach((customMainBranchAvailableDay) => {
      filteredAvailableDays.push(...customMainBranchAvailableDay.availableDays);
    });

    availableDaysOfWeek = availableDaysOfWeek.filter(
      (day) => !filteredAvailableDays.map((day) => day.value).includes(day.value)
    );

    this.setState({ availableDaysOfWeek });

    return customMainBranchAvailableDays;
  };

  handleSubmit = () => {
    if (this.enableSubmit()) {
      const { selectedAliasBranches, mainAliasBranch, customMainBranchAvailableDays } = this.state;
      const { aliasBranches, stationId, createAliasGroup, groupId, updateAliasGroup } = this.props;
      const aliasGroup = selectedAliasBranches.map((selectedAliasBranch) => ({
        startNode: aliasBranches[selectedAliasBranch.value].startNode,
        endNode: aliasBranches[selectedAliasBranch.value].endNode,
        mainBranch: selectedAliasBranch.value === mainAliasBranch.value,
        customMainBranch: customMainBranchAvailableDays.find(
          (customBranch) => customBranch.customMainBranch.value === selectedAliasBranch.value
        ) !== null,
        availableDaysOfWeek:
          customMainBranchAvailableDays.find(
            (customBranch) => customBranch.customMainBranch.value === selectedAliasBranch.value
          ) !== null
            ? customMainBranchAvailableDays
                .filter(
                  (customBranch) =>
                    customBranch.customMainBranch.value === selectedAliasBranch.value
                )
                .map((customBranch) =>
                  customBranch.availableDays.map((availableDay) => availableDay.value)
                )[0]
            : [],
      }));
      groupId
        ? updateAliasGroup(stationId, groupId, aliasGroup, this.closeModal)
        : createAliasGroup(stationId, aliasGroup, this.closeModal);
    }
  };

  render() {
    const { isLoading, error } = this.props;
    const {
      selectedAliasBranches,
      selectedCustomAliasBranches,
      mainAliasBranch,
      customMainBranchAvailableDays,
      availableDaysOfWeek,
    } = this.state;
    const enableSubmit = this.enableSubmit();
    return (
      <Form
        isLoading={isLoading}
        error={error}
        label={t('station.alias.groups.header')}
        enableSubmit={enableSubmit}
        onClose={this.closeModal}
        onSubmit={this.handleSubmit}>
        <InputSelect
          value={selectedAliasBranches}
          onChange={this.handleAddAliasBranchToGroup}
          options={this.mapAliasBranches()}
          info={t('station.alias.groups.info')}
          label={t('station.alias.group')}
          isMulti
        />
        <InputSelect
          value={mainAliasBranch}
          onChange={this.handleSetMainAliasBranch}
          options={selectedAliasBranches}
          info={t('station.alias.groups.main.info')}
          label={t('station.alias.group.main')}
        />
        {customMainBranchAvailableDays.map((el, i) => (
          <div key={el}>
            <div className='input-group'>
              <LabelInfo
                label={t('station.alias.group.custom.main')}
                info={t('station.alias.groups.custom.main.info')}
              />
              <div className='margin-right-minus-5'>
                <div className='custom-main-branch-select'>
                  <Select
                    classNamePrefix='react-select'
                    value={el.customMainBranch}
                    onChange={(e) => this.handleSetCustomMainAliasBranch(e, i)}
                    options={selectedCustomAliasBranches}
                  />
                </div>
                <div className='custom-main-branch-remove-icon'>
                  <i className='far fa-trash-alt' onClick={() => this.removeException(i)} />
                </div>
              </div>
            </div>

            <InputSelect
              value={el.availableDays}
              onChange={(e) => this.handleSetAvailableDaysForCustomMainAliasBranch(e, i)}
              options={availableDaysOfWeek}
              info={t('station.alias.groups.available.days.info')}
              label={t('station.alias.groups.available.days')}
              isMulti
            />
          </div>
        ))}
        {availableDaysOfWeek.length > 0 && selectedCustomAliasBranches.length > 0 && (
          <button type='button' className='button-container' onClick={() => this.addException()}>
            Add exception
          </button>
        )}
      </Form>
    );
  }
}

const mapDispatchToProps = {
  resetErrors,
  createAliasGroup,
  updateAliasGroup,
};

const mapStateToProps = (state, props) => {
  const aliasGroupCompatibleBranches = state.station.aliasBranches.data
    .filter((aliasBranch) =>
      props.groupId
        ? aliasBranch.aliasGroupId === props.groupId || !aliasBranch.aliasGroupId
        : !aliasBranch.aliasGroupId
    )
    .map((aliasBranch) =>
      aliasBranch.aliasGroupId === props.groupId
        ? aliasBranch.branches.map((selectedBranch) => ({
            ...selectedBranch,
            selected: true,
          }))
        : { ...aliasBranch.branches[0] }
    )
    .flat()
    .map((aliasBranch, index) => ({ originalIndex: index, ...aliasBranch }));
  return {
    isLoading: state.station.aliasGroups.updating,
    error: state.station.aliasGroups.error,
    aliasBranches: aliasGroupCompatibleBranches,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AliasGroupForm);
